import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicHunterClinicAPI} from "./provider/clinicHunterClinic";

export function getSubscriptionPlansAPI(): Observable<any> {
    return clinicHunterClinicAPI.get(
        `subscription_definitions?itemsPerPage=100`,
        new RestQueryParams(),
    );
}
