import React from 'react';
import {authTokenSelector, BasicModal, CustomCard, CustomCardType, Translation} from 'common-web';
import {connect} from 'react-redux';
import {
    calendarDetailsSelector,
    calendarIdSelector,
    calendarSelector,
    calendarSettingsSelector,
    calendarStateSelector
} from '../../../../../store/selectors/calendarSelectors';
import {
    changeCalendarId,
    ICalendar,
    ICalendarCreationPayload,
    ICalendarRule,
    ICalendarSettings,
    ICalendarState,
    ITimeSlotItem,
    WeekDays,
    weekDays
} from '../../../../../store/reducers/calendarSlice';
import {RootState} from '../../../../../store/reducers';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {getTimezoneOffset, numberToDateConverter} from '../../../../../utils/dateTransformUtils';
import {updateCalendarAPI} from '../../../../../api/updateCalendarAPI';
import {retrievedCalendarItemSelector} from '../../../../../store/selectors/calendarItemSelectors';
import {isSameRule} from '../../../../../utils/calendarRuleUtils';
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IAlertManagerService} from "../../../../../service/alertManagerService";

interface IConnectedUpdateCalendarModalProps {
    readonly calendarSettings: ICalendarSettings | null;
    readonly retrievedCalendar?: any;
    readonly calendarId: string,
    readonly calendar: ICalendar;
    readonly selectCalendar: ICalendarState;
    readonly authToken: string;
    readonly changeCalendarId: typeof changeCalendarId;
    calendarDetails: any;
}

export interface IUpdateCalendarModalProps extends IConnectedUpdateCalendarModalProps {
    updateModalShown: boolean,
    redirectToMonth: any
}

interface IUpdateCalendarModalState {
    updateModalShown: boolean,
    isLoading: boolean;
}

class UpdateCalendarModal extends React.Component<IUpdateCalendarModalProps, IUpdateCalendarModalState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);
        this.state = {
            updateModalShown: false,
            isLoading: false
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.updateModalShown) {
            this.setState({updateModalShown: this.props.updateModalShown});
        }
    }

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {
        if (this.props.updateModalShown !== prevProps.updateModalShown && this.props.updateModalShown) {
            this.setState({updateModalShown: this.props.updateModalShown});
        }
    }

    componentWillUnmount() {
        if (null !== this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        return (
            <BasicModal isModalShown={this.state.updateModalShown} closeModal={this.closeUpdateModal}>
                <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <h4><Translation text={'calendar.modals.confirmUpdateModal.title'}/></h4>
                            <button className="btn-modal-close" onClick={() => this.toggleUpdateModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><Translation text={'calendar.modals.confirmUpdateModal.question'}/></p>
                        </div>
                    </CustomCard.Body>
                    <CustomCard.Footer>
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-danger-outline mr-4"
                                    type="button"
                                    onClick={() => this.closeUpdateModal()}>
                                <Translation text={'calendar.modals.buttons.no'}/>
                            </button>
                            <button className="btn btn-secondary-theme"
                                    type="button"
                                    onClick={() => this.updateCalendar()}>
                                <Translation text={'calendar.modals.buttons.yes'}/>
                            </button>
                        </div>
                    </CustomCard.Footer>
                </CustomCard>
            </BasicModal>
        );
    }

    private updateCalendar = (): void => {
        const fullCalendar = this.props.selectCalendar;

        if (null !== fullCalendar &&
            null !== fullCalendar.calendarId &&
            null !== fullCalendar.calendarDetails &&
            null !== fullCalendar.calendarDetails.timezone &&
            null !== fullCalendar.calendarDetails.calendarPrice &&
            null !== fullCalendar.calendarDetails.calendarName &&
            null !== fullCalendar.calendarDetails.clinicEmail &&
            null !== fullCalendar.calendarSettings &&
            null !== fullCalendar.calendarSettings.slotLength &&
            null !== fullCalendar.calendarSettings.availableUntil &&
            null !== fullCalendar.calendarSettings.availableFrom) {

            let calendarRulesPayload: ICalendarRule[] = [];
            const abstractWeekBlockedTimeSlots: ITimeSlotItem[] = [],
                calendar = this.props.calendar;

            weekDays.forEach((weekday: WeekDays) => {
                calendar[weekday].forEach((timeSlot: ITimeSlotItem) => {
                        if (!timeSlot.isFree) {
                            abstractWeekBlockedTimeSlots.push(timeSlot);
                        }
                        return timeSlot;
                    }
                );
            });
            calendarRulesPayload = abstractWeekBlockedTimeSlots.map((blockedTimeSlot: ITimeSlotItem): ICalendarRule => ({
                startsAt: blockedTimeSlot.dayDateTimeStart,
                endsAt: blockedTimeSlot.dayDateTimeEnd,
                calendarId: this.props.calendarId,
                interval: 'P0Y0M7DT0H0M0S',
                intervalStart: blockedTimeSlot.dayDateTimeStart
            }));
            (this.props.retrievedCalendar.calendarRules || []).forEach((rule: any) => {
                const found = calendarRulesPayload.find(candidate => isSameRule(candidate, rule));

                if (found) {
                    (found as any).id = rule.id;
                }
            });
            const timezoneOffset = getTimezoneOffset(this.props.calendarDetails.timezone);

            const availableFrom = numberToDateConverter(fullCalendar.calendarSettings.availableFrom, timezoneOffset),
                availableUntil = numberToDateConverter(fullCalendar.calendarSettings.availableUntil, timezoneOffset),
                slotLength = (fullCalendar.calendarSettings.slotLength * 60),
                calendarPrice = fullCalendar.calendarDetails?.calendarPrice,
                clinicEmail = fullCalendar.calendarDetails.clinicEmail,
                specialistEmail = fullCalendar.calendarDetails.specialistEmail;

            const calendarPayload: ICalendarCreationPayload = {
                name: fullCalendar.calendarDetails.calendarName,
                public: true,
                price: calendarPrice,
                slotLength: slotLength,
                availableFrom: availableFrom,
                availableUntil: availableUntil,
                timezone: fullCalendar.calendarDetails.timezone,
                calendarRules: calendarRulesPayload,
                clinicEmail: clinicEmail,
                specialistEmail: specialistEmail
            };
            this.setState({isLoading: true});

            this.subscriptions.push(
                updateCalendarAPI(this.props.calendarId, this.props.authToken, calendarPayload).pipe(
                    tap((data: any) => {
                        this.setState({isLoading: false, updateModalShown: false});
                        this.props.redirectToMonth();
                        this.alertManagerService.addAlert('calendar.modals.confirmUpdateModal.success');
                    }),
                    catchError((error: any) => {
                        this.setState({isLoading: false, updateModalShown: false});
                        this.alertManagerService.handleApiError(error.response);
                        return of(error);
                    })
                ).subscribe());
        }
    };

    private toggleUpdateModal = () => {
        this.setState({updateModalShown: !this.state.updateModalShown});
    };

    private closeUpdateModal = () => {
        return this.setState({updateModalShown: false});
    };

}

export default  connect(
    (state: RootState) => ({
        selectCalendar: calendarStateSelector(state),
        calendarSettings: calendarSettingsSelector(state),
        retrievedCalendar: retrievedCalendarItemSelector(state),
        calendar: calendarSelector(state),
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
        calendarDetails: calendarDetailsSelector(state),
    }),
    {
        changeCalendarId
    }
)(UpdateCalendarModal);
