import {
    FormButtonType,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    MultiselectMenuPlacement,
    MultiSelectType,
    ValidationRules
} from 'common-web';
import moment from "moment";
import {reminderFrequencyUnits} from "../CreateEvent/formConfig";

export const updateEventFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'treatmentPlannerType',
            class: 'form-row',
            controls: {
                treatmentPlannerType: {
                    hostClass: 'col-100 d-flex',
                    controlType: 'control',
                    // defaultValue: 'travel_plan',
                    formControlType: FormControlType.RADIO,
                    options: [
                        {value: 'travel_plan', displayValue: 'Travel Plan'},
                        {value: 'after_care', displayValue: 'Aftercare'}
                    ],
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'treatmentPlanner.newTreatmentPlan.form.plannerType',
                    label: 'treatmentPlanner.newTreatmentPlan.form.plannerType',
                    type: InputType.RADIO,
                }
            }
        },
        {
            controlType: 'group',
            key: 'eventDate',
            class: 'form-row',
            controls: {
                eventHour: {
                    hostClass: 'col-25',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'treatmentPlanner.newTreatmentPlan.form.eventHour',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'treatmentPlanner.newTreatmentPlan.form.eventDate',
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: getDefaultIntervalOptions(12),
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return updateEventFormConfig.controls[1].controls.eventHour.multiselectOptions
                            .filter((event: any) => event.value === data)
                            .map((eventType: any) => ({
                                value: eventType.value,
                                label: eventType.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                },
                eventDayPeriod: {
                    hostClass: 'col-25',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'treatmentPlanner.newTreatmentPlan.form.eventDayPeriod',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'',
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: [
                        {
                            label: 'AM',
                            value: 'am'
                        },
                        {
                            label: 'PM',
                            value: 'pm'
                        }
                    ],
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return updateEventFormConfig.controls[1].controls.eventDayPeriod.multiselectOptions
                            .filter((event: any) => event.value === data)
                            .map((eventType: any) => ({
                                value: eventType.value,
                                label: eventType.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                },
                eventDate: {
                    hostClass: 'col-50',
                    controlType: 'control',
                    defaultValue: '',
                    additionalStyles: 'horizontal',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                        {
                            name: ValidationRules.IS_DATE_GTE_THAN,
                            params: {
                                valueAccessor: (data: any) => data,
                                compareAccessor: () => moment().subtract(1,'days'),
                            }
                        },
                    ],
                    placeholder: 'Event Date',
                    label: '',
                    maxDate: '2100-01-01',
                    openToDate: moment().format('YYYY-MM-DD'),
                    type: InputType.DATE,
                },
            }
        },
        // {
        //     controlType: 'group',
        //     key: 'eventType',
        //     class: 'form-row',
        //     controls: {
        //         eventType: {
        //             hostClass: 'col-100',
        //             controlType: 'control',
        //             defaultValue: null,
        //             placeholder: 'treatmentPlanner.newTreatmentPlan.form.eventType',
        //             formControlType: FormControlType.AUTOCOMPLETE,
        //             multiselectType: MultiSelectType.SINGLE,
        //             label:'treatmentPlanner.newTreatmentPlan.form.eventType',
        //             validationRules: [
        //                 { name: ValidationRules.IS_REQUIRED },
        //             ],
        //             multiselectOptions: [],
        //             inputDataMapper: (data: any) => {
        //                 if (!data) {
        //                     return [];
        //                 }
        //
        //                 return updateEventFormConfig.controls[2].controls.eventType.multiselectOptions
        //                     .filter((event: any) => event.value === data)
        //                     .map((eventType: any) => ({
        //                         value: eventType.value,
        //                         label: eventType.label,
        //                     }));
        //             },
        //             outputDataMapper: (data: any) => {
        //                 if (Array.isArray(data)) {
        //                     return (data.length > 0) ? data[0].value : null;
        //                 }
        //                 if (data && typeof data === 'object') {
        //                     return data.value;
        //                 }
        //                 return data;
        //             },
        //         }
        //     }
        // },
        {
            controlType: 'group',
            key: 'eventName',
            class: 'form-row',
            controls: {
                eventName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'treatmentPlanner.newTreatmentPlan.form.eventName',
                    label: 'treatmentPlanner.newTreatmentPlan.form.eventName',
                    type: InputType.TEXT,
                    hostClass: 'col-100',
                }
            }
        },
        {
            controlType: 'group',
            key: 'eventDescription',
            class: 'form-row',
            controls: {
                eventDescription: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.TEXTAREA,
                    placeholder: 'treatmentPlanner.newTreatmentPlan.form.eventDescription',
                    label: 'treatmentPlanner.newTreatmentPlan.form.eventDescription',
                    type: InputType.TEXT,
                    hostClass: 'col-100',
                }
            }
        },
        {
            controlType: 'group',
            key: 'eventReminder',
            class: 'form-row',
            controls: {
                reminderAmount: {
                    hostClass: 'col-25',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'treatmentPlanner.newTreatmentPlan.form.selectAmountPlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'treatmentPlanner.newTreatmentPlan.form.reminderDate',
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: () => getDefaultIntervalOptions(23, 0),
                    menuPlacement: MultiselectMenuPlacement.TOP,
                    inputDataMapper: (data: any) => {
                        if (!data && data !== 0) {
                            return [];
                        }

                        return updateEventFormConfig.controls[4].controls.reminderAmount.multiselectOptions
                            .filter((event: any) => event.value === data)
                            .map((eventReminder: any) => ({
                                value: eventReminder.value,
                                label: eventReminder.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                },
                reminderUnit: {
                    hostClass: 'col-75 custom-input-group',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'treatmentPlanner.newTreatmentPlan.form.selectTypePlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'treatmentPlanner.newTreatmentPlan.form.before',
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: reminderFrequencyUnits,
                    menuPlacement: MultiselectMenuPlacement.TOP,
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return updateEventFormConfig.controls[4].controls.reminderUnit.multiselectOptions
                            .filter((event: any) => event.value === data)
                            .map((eventFrequency: any) => ({
                                value: eventFrequency.value,
                                label: eventFrequency.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                }
            }
        },
        // {
        //     controlType: 'group',
        //     key: 'repeatEvent',
        //     class: 'form-row',
        //     controls: {
        //         repeatEvent: {
        //             hostClass: 'col-100 d-flex',
        //             controlType: 'control',
        //             defaultValue: null,
        //             formControlType: FormControlType.RADIO,
        //             options: [
        //                 {value: 'single', displayValue: 'Single'},
        //                 {value: 'consecutive', displayValue: 'Consecutive'}
        //             ],
        //             validationRules: [
        //                 { name: ValidationRules.IS_REQUIRED },
        //             ],
        //             placeholder: 'treatmentPlanner.newTreatmentPlan.form.eventOccurrence',
        //             label: 'treatmentPlanner.newTreatmentPlan.form.eventOccurrence',
        //             type: InputType.RADIO,
        //         }
        //     }
        // },
        // {
        //     controlType: 'group',
        //     key: 'consecutiveType',
        //     class: 'form-row',
        //     controls: {
        //         consecutiveType: {
        //             hostClass: 'col-50',
        //             controlType: 'control',
        //             defaultValue: null,
        //             placeholder: 'treatmentPlanner.newTreatmentPlan.form.selectTypePlaceholder',
        //             formControlType: FormControlType.AUTOCOMPLETE,
        //             multiselectType: MultiSelectType.SINGLE,
        //             label:'treatmentPlanner.newTreatmentPlan.form.consecutiveType',
        //             validationRules: [
        //                 { name: ValidationRules.IS_REQUIRED },
        //             ],
        //             menuPlacement: MultiselectMenuPlacement.TOP,
        //             multiselectOptions: [
        //                 {
        //                     value: 'number',
        //                     label: 'Events number'
        //                 },
        //                 {
        //                     value: 'period',
        //                     label: 'Till date'
        //                 }
        //             ],
        //             inputDataMapper: (data: any) => {
        //                 if (!data) {
        //                     return [];
        //                 }
        //
        //                 return updateEventFormConfig.controls[6].controls.consecutiveType.multiselectOptions
        //                     .filter((event: any) => event.value === data)
        //                     .map((eventReminder: any) => ({
        //                         value: eventReminder.value,
        //                         label: eventReminder.label,
        //                     }));
        //             },
        //             outputDataMapper: (data: any) => {
        //                 if (Array.isArray(data)) {
        //                     return (data.length > 0) ? data[0].value : null;
        //                 }
        //                 if (data && typeof data === 'object') {
        //                     return data.value;
        //                 }
        //                 return data;
        //             },
        //         },
        //         consecutiveValue: {
        //             hostClass: 'col-33',
        //             controlType: 'control',
        //             defaultValue: null,
        //             placeholder: 'treatmentPlanner.newTreatmentPlan.form.selectAmountPlaceholder',
        //             formControlType: FormControlType.AUTOCOMPLETE,
        //             multiselectType: MultiSelectType.SINGLE,
        //             // label:'treatmentPlanner.newTreatmentPlan.form.before',
        //             validationRules: [
        //                 { name: ValidationRules.IS_REQUIRED },
        //             ],
        //             menuPlacement: MultiselectMenuPlacement.TOP,
        //             multiselectOptions: () => getDefaultIntervalOptions(30),
        //             inputDataMapper: (data: any) => {
        //                 if (!data) {
        //                     return [];
        //                 }
        //
        //                 return updateEventFormConfig.controls[6].controls.consecutiveValue.multiselectOptions
        //                     .filter((event: any) => event.value === data)
        //                     .map((eventReminder: any) => ({
        //                         value: eventReminder.value,
        //                         label: eventReminder.label,
        //                     }));
        //             },
        //             outputDataMapper: (data: any) => {
        //                 if (Array.isArray(data)) {
        //                     return (data.length > 0) ? data[0].value : null;
        //                 }
        //                 if (data && typeof data === 'object') {
        //                     return data.value;
        //                 }
        //                 return data;
        //             },
        //         }
        //     }
        // },
        // {
        //     controlType: 'group',
        //     key: 'interval',
        //     class: 'form-row',
        //     controls: {
        //         intervalValue: {
        //             hostClass: 'col-33',
        //             controlType: 'control',
        //             defaultValue: null,
        //             placeholder: 'treatmentPlanner.newTreatmentPlan.form.selectAmountPlaceholder',
        //             formControlType: FormControlType.AUTOCOMPLETE,
        //             multiselectType: MultiSelectType.SINGLE,
        //             label:'treatmentPlanner.newTreatmentPlan.form.interval',
        //             validationRules: [
        //                 { name: ValidationRules.IS_REQUIRED },
        //             ],
        //             menuPlacement: MultiselectMenuPlacement.TOP,
        //             multiselectOptions: () => getDefaultIntervalOptions(30),
        //             inputDataMapper: (data: any) => {
        //                 if (!data) {
        //                     return [];
        //                 }
        //
        //                 return updateEventFormConfig.controls[7].controls.intervalValue.multiselectOptions
        //                     .filter((event: any) => event.value === data)
        //                     .map((intervalAmount: any) => ({
        //                         value: intervalAmount.value,
        //                         label: intervalAmount.label,
        //                     }));
        //             },
        //             outputDataMapper: (data: any) => {
        //                 if (Array.isArray(data)) {
        //                     return (data.length > 0) ? data[0].value : null;
        //                 }
        //                 if (data && typeof data === 'object') {
        //                     return data.value;
        //                 }
        //                 return data;
        //             },
        //         },
        //         intervalUnit: {
        //             hostClass: 'col-33',
        //             controlType: 'control',
        //             defaultValue: null,
        //             placeholder: 'treatmentPlanner.newTreatmentPlan.form.selectTypePlaceholder',
        //             formControlType: FormControlType.AUTOCOMPLETE,
        //             multiselectType: MultiSelectType.SINGLE,
        //             label: '',
        //             validationRules: [
        //                 { name: ValidationRules.IS_REQUIRED },
        //             ],
        //             menuPlacement: MultiselectMenuPlacement.TOP,
        //             multiselectOptions: eventFrequencyUnits,
        //             inputDataMapper: (data: any) => {
        //                 if (!data) {
        //                     return [];
        //                 }
        //
        //                 return updateEventFormConfig.controls[7].controls.intervalUnit.multiselectOptions
        //                     .filter((event: any) => event.value === data)
        //                     .map((intervalUnit: any) => ({
        //                         value: intervalUnit.value,
        //                         label: intervalUnit.label,
        //                     }));
        //             },
        //             outputDataMapper: (data: any) => {
        //                 if (Array.isArray(data)) {
        //                     return (data.length > 0) ? data[0].value : null;
        //                 }
        //                 if (data && typeof data === 'object') {
        //                     return data.value;
        //                 }
        //                 return data;
        //             },
        //         }
        //     }
        // },
        {
            controlType: 'group',
            key: 'recommendation_submit',
            class: 'form-row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-100 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.update'
                },
            },
        },
    ]
};

function getDefaultIntervalOptions(cycleValue: number, cycleStartValue?: number) {
    let options: typeof IMultiselectOption[] = [];
    let startValue = cycleStartValue ? cycleStartValue : 1;
    for (let step = startValue; step <= cycleValue; step++) {
        options.push({
            value: step,
            label: step
        })
    }
    return options;
}
