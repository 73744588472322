import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Form, FormControlChangeType, IFormConfig, IMultiselectOption, ValidationRules} from "common-web";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {BehaviorSubject, Subscription} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {addAftercareFormConfig} from "./formConfig";
import {IAftercareMapperService} from "../../../../../service/aftercareMapperService";
import {RecommendationDefinitionType} from "../../index";

interface IConnectedAddAftercareProps {}

interface IAddAftercareProps extends IConnectedAddAftercareProps,
    RouteComponentProps,
    WithTranslation {
    closeRecommendationModal: any;
    selectedRecommendation: {[key: string]: any} | null;
    addRecommendation: (value: any) => void;
}

interface IAddAftercareState {
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    isLoading: boolean;
    value: any;
}

class AddAftercare extends React.Component<IAddAftercareProps, IAddAftercareState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('AftercareMapperService') private aftercareMapper: IAftercareMapperService;

    constructor(props: IAddAftercareProps) {
        super(props);

        this.state = {
            formConfig: addAftercareFormConfig,
            isFormValid: true,
            isLoading: false,
            value: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );

        if (this.props.selectedRecommendation) {
            this.changeFormControlValues(this.props.selectedRecommendation);
        } else {
            this.updateButtonText('treatmentPlanner.predefinedEvents.createEvent.button');
        }

        this.setIntervalRate();
        this.setHourReminderIntervalRate(0, 23);
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <Form config={this.state.formConfig}
                  onValueStateChange={this.onValueStateChange}
                  onValidationStateChange={this.onValidationStateChange}
                  value={this.state.value}
                  controlName={'addRecommendationForm'}
                  submitForm={() => this.props.addRecommendation(this.state.value)}/>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        Object.keys(value).map((key: string) => {
            if (key === 'repeatEvent' &&
                value[key] !== null &&
                value[key] !== this.state.value?.repeatEvent) {
                this.updateEventTypeFormControls(value[key])
            }

            if (key === 'reminderUnit' && value[key] !== null) {
                return value[key] === 'hour' ? this.setHourReminderIntervalRate(0, 23) :
                    this.setHourReminderIntervalRate(1, 30);
            }

            if (key === 'consecutiveType' &&
                value[key] !== null) {
                this.updateConsecutiveTypeLabel(value[key]);
            }
        });

        this.setState({value: value});
    };

    private updateEventTypeFormControls(value: string): void {
        const cloneFormConfig = Object.assign({}, this.state.formConfig);
        cloneFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (value === RecommendationDefinitionType.SINGLE) {
                        if (key === 'consecutiveType') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                        if (key === 'consecutiveValue') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                        if (key === 'intervalValue') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                        if (key === 'intervalUnit') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                    } else {
                        let requiredField = [
                            { name: ValidationRules.IS_REQUIRED },
                        ];
                        if (key === 'consecutiveType') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                        if (key === 'consecutiveValue') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                        if (key === 'intervalValue') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                        if (key === 'intervalUnit') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                    }
                });
            }

            return control;
        });

        this.setState({
            formConfig: cloneFormConfig,
        });
    }

    private setIntervalRate() {
        let repeatValue = 30;
        let options: typeof IMultiselectOption[] = [];
        for (let step = 1; step <= repeatValue; step++) {
            options.push({
                value: step,
                label: step
            })
        }

        addAftercareFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'intervalValue') {
                        control.controls[key].multiselectOptions = options;
                    }

                    if (key === 'consecutiveValue') {
                        control.controls[key].multiselectOptions = options;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: addAftercareFormConfig});
    }

    private setHourReminderIntervalRate(startValue: number, repeatValue: number) {
        let options: typeof IMultiselectOption[] = [];
        for (let step = startValue; step <= repeatValue; step++) {
            options.push({
                value: step,
                label: step
            })
        }

        addAftercareFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'reminderAmount') {
                        control.controls[key].multiselectOptions = options;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: addAftercareFormConfig});
    }

    private updateConsecutiveTypeLabel(value: string) {
        addAftercareFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'consecutiveValue') {
                        value === 'period' ? control.controls[key].label = 'days' :
                            control.controls[key].label = '';
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: addAftercareFormConfig});
    }

    private changeFormControlValues(recommendation: {[key: string]: any}) {
        let recommendationProperties = recommendation?.structure?.properties,
            reminderDetails = this.aftercareMapper.convertDateIntervalToValue(
                recommendationProperties?.notificationPropagationStart
            ),
            intervalDetails = this.aftercareMapper.convertDateIntervalToValue(recommendationProperties?.cycleInterval),
            consecutiveType = recommendation?.structure?.notificationType === RecommendationDefinitionType.SINGLE ?
                null : recommendation?.structure?.notificationType,
            notificationType = recommendation.structure?.notificationType === RecommendationDefinitionType.SINGLE ?
                RecommendationDefinitionType.SINGLE : RecommendationDefinitionType.CONSECUTIVE,
            consecutiveValue = null;

        if (recommendation?.structure?.notificationType === RecommendationDefinitionType.CONSECUTIVE_NUMBER) {
            consecutiveValue = recommendationProperties?.cycleValue;
        }
        if (recommendation?.structure?.notificationType === RecommendationDefinitionType.CONSECUTIVE_PERIOD) {
            consecutiveValue = this.aftercareMapper.convertDateIntervalToDaysAmount(
                recommendationProperties?.cycleValue
            );
        }

        this.setState({
            value: {
                recommendationName: recommendation.name,
                recommendationDescription: recommendation.description,
                repeatEvent: notificationType,
                reminderAmount: reminderDetails?.amount,
                reminderUnit: reminderDetails?.unit,
                consecutiveType: consecutiveType,
                consecutiveValue: consecutiveValue,
                intervalUnit: intervalDetails?.unit,
                intervalValue: intervalDetails?.amount
            }
        });

        this.updateEventTypeFormControls(
            recommendation.structure.notificationType !== RecommendationDefinitionType.SINGLE ?
                RecommendationDefinitionType.CONSECUTIVE :
                RecommendationDefinitionType.SINGLE
        );
        this.updateConsecutiveTypeLabel(consecutiveType);
        this.updateButtonText('buttons.update');
    }

    private updateButtonText = (btnText: string) => {
        const updatedFormConfig = Object.assign({}, addAftercareFormConfig);
        updatedFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'submitButton') {
                        control.controls[key].btnText = btnText;
                    }
                });
            }

            return control;
        });

        return this.setState({formConfig: updatedFormConfig});
    }
}

export default withTranslation()(withRouter(AddAftercare));
