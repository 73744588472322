import React from "react";
import { RootState } from "../../../store/reducers";
import { connect } from "react-redux";
import {
  CustomCard,
  Translation,
  accountLoadingSelector,
  // Form,
  Checkbox
} from 'common-web';
import { clinicStaffFormConfig } from "./clinicStaffFormConfig";

const mockSpecialist = [
  {
    name: 'Test Name',
    title: 'Specialist',
    isAvailable: true,
    disabled: true
  },
  {
    name: 'Test Test',
    title: 'Specialist',
    isAvailable: false,
    disabled: true
  },
  {
    name: 'Test Test',
    title: 'Specialist',
    isAvailable: true,
    disabled: true
  },
  {
    name: 'Test T.',
    title: 'Specialist',
    isAvailable: true,
    disabled: true
  },
  {
    name: 'Test Name',
    title: 'Specialist',
    isAvailable: false,
    disabled: true
  }
];

interface IClinicStaffConnectedProps {
  readonly isLoading: boolean;
}

interface IClinicStaffExternalProps {}

interface IClinicStaffProps extends
  IClinicStaffConnectedProps,
  IClinicStaffExternalProps {}

interface IClinicStaffState {
  isLoading: boolean;
  formIsValid: boolean;
  formControls: any;
}

class ClinicStaff extends React.Component<IClinicStaffProps, IClinicStaffState> {
  constructor(props: IClinicStaffProps) {
    super(props);

    this.state = {
      isLoading: false,
      formIsValid: true,
      formControls: clinicStaffFormConfig.formGroups
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IClinicStaffProps>,
    prevState: Readonly<IClinicStaffState>,
    snapshot?: any
  ): void {
    if(this.props.isLoading !== prevProps.isLoading
        && !this.props.isLoading) {
      this.setState({isLoading: false})
    }
  }


  render() {
    return (
      <CustomCard showLocalLoader={this.state.isLoading}>
        <CustomCard.Header>
            <Translation text={'profile.specialists.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
          <div className="specialists-container">
          {/*<Form options={this.state.formControls}*/}
          {/*      handleChange={this.handleChange}*/}
          {/*      isFormDisabled={!this.state.formIsValid}*/}
          {/*      submitForm={this.changeStaffData}*/}
          {/*      // btnText="Save"*/}
          {/*      isFormValid={this.state.formIsValid}/>*/}
          {
            mockSpecialist.map((specialist: any, index: number) => {
              const label = `${specialist.title} ${specialist.name}`;
              return <Checkbox key={index}
                               checked={specialist.isAvailable}
                               name={specialist.name}
                               label={label}
                               disabled={specialist.disabled} />
            })
          }
          </div>
        </CustomCard.Body>
      </CustomCard>
    );
  }
}

export default connect(
  (state: RootState) => ({
    isLoading: accountLoadingSelector(state)
  }),
  {}
)(ClinicStaff);
