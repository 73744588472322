import {
    FormButtonType,
    FormControlType,
    IFormConfig,
    InputType,
    ValidationRules
} from 'common-web';


const isStripeCardValid = (data: any) => {
    if ((data !== null && data !== undefined) && (data.complete !== null && data.complete !== undefined)) {
        return data.complete;
    }
};

export const paymentMethodFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'cardholder_name',
            class: 'form-row',
            controls: {
                cardholderName: {
                    controlType: 'control',
                    hostClass: 'col-100',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 3}},
                    ],
                    placeholder: 'billings.paymentMethods.addPaymentMethod.form.cardholderName',
                    label: 'billings.paymentMethods.addPaymentMethod.form.cardholderName',
                    type: InputType.TEXT,
                }
            }
        },
        {
            controlType: 'group',
            key: 'stripe_card',
            class: 'form-row',
            controls: {
                stripecard: {
                    controlType: 'control',
                    hostClass: 'col-100',
                    defaultValue: '',
                    formControlType: FormControlType.STRIPECARD,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_VALID, params: {callback: isStripeCardValid}},
                    ],
                    placeholder: '',
                    label: 'billings.paymentMethods.addPaymentMethod.form.cardDetails',
                    stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
                }
            }
        },
        {
            controlType: 'group',
            key: 'aftercare_submit',
            class: 'form-row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-100 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'billings.paymentMethods.addPaymentMethod.form.addMethod',
                },
            },
        },
    ]
};

