import React from 'react';
import './App.scss';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import {connect} from "react-redux";
import {RootState} from "./store/reducers";
import {isAuthenticatedSelector} from "common-web";
import {PaymentAccount} from "./store/reducers/accountSlice";
import {paymentAccountsSelector, subscriptionSelector} from "./store/selectors/accountSelectors";
import {PaymentServiceName} from "./api/buySubscriptionDefiniton";

interface IConnectedAppProps {
  isAuthenticated: boolean;
  readonly paymentAccounts: PaymentAccount[];
  readonly subscription: any;
}

interface IAppProps extends
  IConnectedAppProps,
  RouteComponentProps {}

interface IAppState {
  isAuthenticated: boolean;
}

class App extends React.Component<IAppProps, IAppState>{
  constructor(props: IAppProps) {
    super(props);
     this.state ={
       isAuthenticated: this.props.isAuthenticated
     }
  }

  componentDidUpdate(
      prevProps: Readonly<IAppProps>,
      prevState: Readonly<IAppState>,
      snapshot?: any
  ): void {
      if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
          this.setState({isAuthenticated: this.props.isAuthenticated});
      }
  }


    render() {
      const stripePaymentAccount = this.props.paymentAccounts && this.props.paymentAccounts
        ?.find(account => (account.paymentAccountVendorType === PaymentServiceName.STRIPE)),
       isCardRequired: boolean =
        (stripePaymentAccount && stripePaymentAccount.paymentAccountVendorData.customerPaymentMethodId === null) &&
        (this.props.subscription && this.props.subscription.proposedPlans && this.props.subscription.proposedPlans.length),
       paymentRequiredConfig = {
        pathname: '/dashboard/billings',
        state: {
          selectedTab: 2,
          isCardModalOpen: true
        }
      },
      path = this.state.isAuthenticated ? '/dashboard' : '/auth/login';
        return (
          <div className="App">
            <Redirect push to={isCardRequired ? paymentRequiredConfig : path}/>
          </div>
        );
  }
}

export default connect(
    (state: RootState) => ({
        isAuthenticated: isAuthenticatedSelector(state),
        paymentAccounts: paymentAccountsSelector(state),
        subscription: subscriptionSelector(state)
    })
)(withRouter(App));
