import {FormButtonType, FormControlType, IFormConfig, MultiSelectType, ValidationRules} from 'common-web';

export const invitePatientFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'calendar',
            class: 'form-row',
            controls: {
                calendar: {
                    hostClass: 'col-100',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'Select a calendar',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'Calendar',
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: [],
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return invitePatientFormConfig.controls[0].controls.calendar.multiselectOptions
                            .filter((calendar: any) => calendar.value === data)
                            .map((data: any) => console.log('in inputDataMapper in calendar', data))
                            .map((calendar: any) => ({
                                value: calendar.value,
                                label: calendar.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        console.log('data in outputDataMapper in calendar', data);
                        return data;
                    },
                }
            }
        },
        {
            controlType: 'group',
            key: 'patient',
            class: 'form-row',
            controls: {
                patient: {
                    hostClass: 'col-100',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'Select a patient',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'Patient',
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: [],
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return invitePatientFormConfig.controls[1].controls.patient.multiselectOptions
                            .filter((calendar: any) => calendar.value === data)
                            .map((data: any) => console.log('in inputDataMapper in patient', data))
                            .map((calendar: any) => ({
                                value: calendar.value,
                                label: calendar.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        console.log('data in outputDataMapper in patient', data);
                        return data;
                    },
                }
            }
        },
        // {
        //     controlType: 'group',
        //     key: 'consultation_invitation_submit',
        //     class: 'form-row',
        //     controls: {
        //         submitButton: {
        //             controlType: 'control',
        //             formControlType: FormControlType.BUTTON,
        //             buttonType: FormButtonType.SUBMIT,
        //             hostClass: 'col-100 mb-0',
        //             defaultContainerStyles: '',
        //             containerStyles: 'button-container mt-2',
        //             defaultValue: null,
        //             btnText: 'Send email to patient',
        //         },
        //     },
        // },
    ]
};

export const submitButton = {
    controlType: 'group',
    key: 'consultation_invitation_submit',
    class: 'form-row',
    controls: {
        submitButton: {
            controlType: 'control',
                formControlType: FormControlType.BUTTON,
                buttonType: FormButtonType.SUBMIT,
                hostClass: 'col-100 mb-0',
                defaultContainerStyles: '',
                containerStyles: 'button-container mt-2',
                defaultValue: null,
                btnText: 'Send email to patient',
        },
    },
};
