import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicHunterClinicAPI} from "./provider/clinicHunterClinic";


export function cancelConsultationAPI(
    consultationId: string,
    accessToken: string
): Observable<any> {
    return clinicHunterClinicAPI.put(
        `online_consultation/${consultationId}/cancel`,
        {},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
