import {injectable} from 'inversify';
import store from '../store';
import {usernameSelector} from '../store/selectors/authSelectors';
import {IFormConfig} from "common-web";

export enum UpdateCardType {
    PERSONAL = 'personal_data',
    ADDRESS = 'address_data',
    INVOICE = 'invoice_data',
    ERROR = 'error',
}

export interface IAccountFormUpdaterService {
    updateFormControls: (accountData: any, formConfig: { [key: string]: any }[]) => { updatedControls: { [key: string]: any }[] };
}

@injectable()
class AccountFormUpdaterService implements IAccountFormUpdaterService {
    updateFormControls(accountData: any, formConfig: typeof IFormConfig) {
        const updatedControls: typeof IFormConfig = formConfig,
            state = store.getState(),
            clinicEmail = usernameSelector(state);

        console.log('@@@@@@@@@@@@@@@@@ UPDATE 1');
        updatedControls.groups.forEach((group: any) => {
            Object.keys(group.controls).forEach((controlName: string) => {
                const control = group.controls[controlName];

                console.log('@@@@@@@@@@@@@@@@@ UPDATE 2', group, control);
                for (const [key, value] of Object.entries(accountData || {})) {
                    if (controlName === 'clinicEmail') {
                        control.value = clinicEmail;
                    }
                    if (controlName === 'companyName') {
                        control.value = accountData.companyName;
                    }
                    if (controlName === 'country') {
                        return;
                    }
                    if (controlName === key && value !== null) {
                        return control.value = value;
                    }
                }
            });
        });

        return {updatedControls};
    }
}

export default AccountFormUpdaterService;
