import React from 'react';
import {authTokenSelector, BasicModal, CustomCard, CustomCardType, Translation} from "common-web";
import {connect} from "react-redux";
import {
    calendarIdSelector,
    calendarSettingsSelector,
    calendarSelector
} from "../../../../../store/selectors/calendarSelectors";
import {
    changeCalendarId,
    changeCalendarSettings,
    ICalendar,
    ICalendarSettings,
    ICalendarRule,
    ITimeSlotItem,
    WeekDays,
    weekDays
} from "../../../../../store/reducers/calendarSlice";
import {RootState} from "../../../../../store/reducers";
import {of, Subscription} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {addCalendarRulesAPI} from "../../../../../api/addCalendarRules";
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IAlertManagerService} from "../../../../../service/alertManagerService";

interface IConnectedPopulateCalendarModalProps {
    readonly calendarSettings: ICalendarSettings | null;
    readonly calendarId: string,
    readonly calendar: ICalendar;
    readonly authToken: string;
    readonly changeCalendarSettings: typeof changeCalendarSettings;
    readonly changeCalendarId: typeof changeCalendarId;
}

export interface IPopulateCalendarModalProps extends IConnectedPopulateCalendarModalProps {
    populateModalShown: boolean,
    redirectToMonth: any
}

interface IPopulateCalendarModalState {
    populateModalShown: boolean,
    isLoading: boolean;
}

class PopulateCalendarModal extends React.Component<IPopulateCalendarModalProps, IPopulateCalendarModalState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);

        this.state = {
            populateModalShown: false,
            isLoading: false,
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.populateModalShown) {
            this.setState({populateModalShown: this.props.populateModalShown});
        }
    }

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {
        if (this.props.populateModalShown !== prevProps.populateModalShown && this.props.populateModalShown) {
            this.setState({populateModalShown: this.props.populateModalShown});
        }
    }

    componentWillUnmount() {
        if (null !== this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        return (
            <BasicModal isModalShown={this.state.populateModalShown} closeModal={this.closePopulateModal}>
                <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <h4><Translation text={'calendar.modals.confirmPopulateModal.title'}/></h4>
                            <button className="btn-modal-close" onClick={() => this.togglePopulateModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><Translation text={'calendar.modals.confirmPopulateModal.question'}/></p>
                        </div>
                    </CustomCard.Body>
                    <CustomCard.Footer>
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-danger-outline mr-4"
                                    type="button"
                                    onClick={() => this.closePopulateModal()}>
                                <Translation text={'calendar.modals.buttons.no'}/>
                            </button>
                            <button className="btn btn-secondary-theme"
                                    type="button"
                                    onClick={() => this.populateCalendar()}>
                                <Translation text={'calendar.modals.buttons.yes'}/>
                            </button>
                        </div>
                    </CustomCard.Footer>
                </CustomCard>
            </BasicModal>
        );
    }

    private populateCalendar = (): void => {
        if (!this.props.calendarId) {
            return;
        }
        this.setState({isLoading: true});
        let payload: ICalendarRule[] = [];
        const abstractWeekBlockedTimeSlots: ITimeSlotItem[] = [],
            calendar = this.props.calendar;
        weekDays.forEach((weekday: WeekDays) => {
            calendar[weekday].forEach((timeSlot: ITimeSlotItem) => {
                    if (!timeSlot.isFree) {
                        abstractWeekBlockedTimeSlots.push(timeSlot);
                    }
                    return timeSlot;
                }
            )
        });
        payload = abstractWeekBlockedTimeSlots.map((blockedTimeSlot: ITimeSlotItem): ICalendarRule => ({
            startsAt: blockedTimeSlot.dayDateTimeStart,
            endsAt: blockedTimeSlot.dayDateTimeEnd,
            calendarId: this.props.calendarId,
            interval: "P0Y0M7DT0H0M0S",
            intervalStart: blockedTimeSlot.dayDateTimeStart
        }));

        this.subscriptions.push(
            addCalendarRulesAPI(this.props.authToken, payload).pipe(
                tap((data: any) => {
                    this.setState({isLoading: false});
                    this.props.redirectToMonth();
                    this.closePopulateModal();
                    this.alertManagerService.addAlert('calendar.modals.confirmPopulateModal.success');
                }),
                catchError((error: any) => {
                    this.closePopulateModal();
                    this.setState({isLoading: false});
                    this.alertManagerService.handleApiError(error.response);
                    return of(error);
                })
            ).subscribe());
    };

    private togglePopulateModal = () => {
        this.setState({populateModalShown: !this.state.populateModalShown});
    };
    private closePopulateModal = () => {
        return this.setState({populateModalShown: false});
    };
}

export default connect(
    (state: RootState) => ({
        calendarSettings: calendarSettingsSelector(state),
        calendar: calendarSelector(state),
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeCalendarSettings,
        changeCalendarId
    }
)(PopulateCalendarModal);
