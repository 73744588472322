import React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {list, reset} from '../../../actions/treatmentPlanner/list';
import {RootState} from "../../../store/reducers";
import {
    authTokenSelector,
    CustomCard,
    RestQueryParams,
    CustomPagination,
    Translation,
    MultiSelect,
    clinicTreatmentCategoriesSelector,
    getTreatmentTypesAPI,
    MultiSelectType
} from "common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import AddAfterCareForm from './AddTreatmentPlanForm';
import {IMultiselectOption} from "common-web/src/types";
import {debounceTime, filter, tap} from "rxjs/operators";
import {BehaviorSubject, Subscription} from "rxjs";
import {
    afterCareListErrorSelector,
    afterCareListEventSourceSelector,
    afterCareListLoadingSelector,
    retrievedAfterCareListSelector
} from "../../../store/selectors/afterCareListSelectors";
import {list as patientsList} from "../../../actions/patient/list";
import {retrievedPatientsListSelector} from "../../../store/selectors/patientListSelectors";
import SortingButtons from "./SortingButtons";

export enum AfterCareStatus {
    DRAFT = 'draft',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    INVITED = 'invited',
    EXPIRED = 'expired'
}

interface IConnectedTreatmentPlannerListProps {
    readonly retrieved: any;
    readonly loading: boolean;
    readonly error: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly authToken: string;
    readonly clinicTreatmentCategories: {[key: string]: any}[];
    readonly patientsList: any;
    readonly patients: any;
}

interface ITreatmentPlannerListProps extends IConnectedTreatmentPlannerListProps,
    RouteComponentProps,
    WithTranslation {
}

interface ITreatmentPlannerListState {
    addAfterCareModalShown: boolean;
    treatmentTypes: IMultiselectOption[];
    patientList: IMultiselectOption[];
    isPatientSortedAsc: boolean | null;
    isTreatmentTypeSortedAsc: boolean | null;
    isStatusSortedAsc: boolean | null;
}

class TreatmentPlannerList extends React.Component<ITreatmentPlannerListProps, ITreatmentPlannerListState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private subscriptions: Subscription[] = [];
    readonly onSearchValueChange$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(props: ITreatmentPlannerListProps) {
        super(props);

        this.state = {
            addAfterCareModalShown: false,
            treatmentTypes: [],
            isPatientSortedAsc: null,
            isTreatmentTypeSortedAsc: null,
            isStatusSortedAsc: null,
            patientList: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getPatientsList();
        this.subscriptions.push(this.getTreatmentTypes());
        this.subscriptions.push(
            this.onSearchValueChange$.pipe(
                filter((data: any) => data !== null),
                debounceTime(500),
                tap((data: any) => {
                    if (!data.length) {
                        this.props.list(
                            `treatment_plans?page=1`,
                            this.props.authToken,
                        );
                    }

                    this.props.list(
                        `treatment_plans?page=1&patient.account.lastName=${data}`,
                        this.props.authToken,
                    );
                }),
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<ITreatmentPlannerListProps>,
        prevState: Readonly<ITreatmentPlannerListState>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }

        if(this.props.patients !== prevProps.patients &&
            this.props.patients && this.props.patients['hydra:member'] &&
            this.props.patients['hydra:member'].length) {
            let patientList: IMultiselectOption[] = [];

            this.props.patients['hydra:member'].filter((patient: {[key: string]: any}) => patient.account.user !== null)
                .forEach((patient: any) => {
                return patientList.push({
                    label: `${patient.account.firstName} ${patient.account.lastName}`,
                    value: patient.id
                })
            });

            this.setState({patientList: patientList});
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'treatmentPlanner.title'}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 d-flex justify-content-between">
                        <div className="filter-container">
                            <div className="form-control input-icon icon-search mr-4">
                                <input type="text"
                                       placeholder="Enter patient's last name"
                                       className="input"
                                       onChange={(e: any) => this.handleChange(e)}/>
                            </div>

                            <div className="form-control select aftercare-select">
                                <MultiSelect
                                    multiselectType={MultiSelectType.SINGLE}
                                    handleChange={(values: IMultiselectOption, action: any) => {
                                        this.handleTreatmentMultiselect(values, action)
                                    }}
                                    options={this.state.treatmentTypes}
                                    placeholder={'Select treatment type'}
                                />
                            </div>
                        </div>

                        <div className="action-container">
                            <button className="btn btn-theme"
                                    type="button"
                                    onClick={() => this.toggleAddAfterCareModal()}>
                                <Translation text={'treatmentPlanner.addNewPlan'}/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>
                                {this.renderAfterCareList()}
                            </CustomCard.Body>
                        </CustomCard>
                        <CustomPagination retrieved={this.props.retrieved}
                                    basePath="dashboard"
                                    path="aftercare"
                                    provider={this.getAfterCareList}/>
                    </div>
                </div>

                <AddAfterCareForm addAfterCareModalShown={this.state.addAfterCareModalShown}
                                  closeAfterCareModal={this.closeAddAfterCareModal}
                                  toggleAfterCareModal={this.toggleAddAfterCareModal}
                                  treatmentTypes={this.state.treatmentTypes}
                                  patientList={this.state.patientList}
                                  authToken={this.props.authToken}
                />
            </React.Fragment>
        );
    }

    private renderAfterCareList = () => {
        if (!this.props.retrieved ||
            !this.props.retrieved['hydra:member'] ||
            !Array.isArray(this.props.retrieved['hydra:member']) ||
            !this.props.retrieved['hydra:member'].length) {
            return <p>There is no data available</p>;
        }

        return (
            <React.Fragment>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th>
                            <Translation text={'treatmentPlanner.list.patientName'}/>
                            <SortingButtons sortDataDesc={this.sortDataTableDesc}
                                            sortDataAsc={this.sortDataTableAsc}
                                            isSortingAsc={this.state.isPatientSortedAsc}
                                            sortingParam={'patient.account.lastName'}/>
                        </th>
                        <th>
                            <Translation text={'treatmentPlanner.list.treatmentType'}/>
                            <SortingButtons sortDataDesc={this.sortDataTableDesc}
                                            sortDataAsc={this.sortDataTableAsc}
                                            isSortingAsc={this.state.isTreatmentTypeSortedAsc}
                                            sortingParam={'treatmentType.name'}/>
                        </th>
                        <th>
                            <Translation text={'treatmentPlanner.list.status'}/>
                            <SortingButtons sortDataDesc={this.sortDataTableDesc}
                                            sortDataAsc={this.sortDataTableAsc}
                                            isSortingAsc={this.state.isStatusSortedAsc}
                                            sortingParam={'treatmentPlanStatus'}/>
                        </th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    };

    private renderTableRows() {
        const rows: any[] = [],
            list = this.props.retrieved['hydra:member'];

        list.map((item: any) => {
            const patient = item?.patient?.account;

            return rows.push((
                <tr key={item['id']}>
                    <td>
                        {patient ? `${patient.firstName} ${patient.lastName}` : '---'}
                    </td>
                    <td>
                        {item.treatmentType.name}
                    </td>
                    <td>
                        <div className={`status-wrapper ${item.treatmentPlanStatus}`}>
                            <p className="status">
                                <Translation text={`treatmentPlanner.list.statuses.${item.treatmentPlanStatus}`}/>
                            </p>
                        </div>
                    </td>
                    <td className="align-middle text-right">
                        <Link className="btn btn-action" to={`/dashboard/aftercare/${item['id']}`}>
                            <span className="feather icon-edit"/>
                        </Link>
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private getAfterCareList = (searchParams: typeof RestQueryParams) => {
        searchParams = searchParams.add('order[createdAt]', 'DESC');
        searchParams = searchParams.add('itemsPerPage', '10');
        this.props.list(
            `treatment_plans${searchParams.prepareQuery()}`,
            this.props.authToken
        );
    };

    private handleChange = (e: any) => {
        this.onSearchValueChange$.next(e.target.value);
    };

    private sortDataTableAsc = (param: string) => {
        this.props.list(
            `treatment_plans?page=1&itemsPerPage=10&order[${param}]=asc`,
            this.props.authToken
        );

        this.setState({
            isTreatmentTypeSortedAsc: null,
            isPatientSortedAsc: null,
            isStatusSortedAsc: null
        });

        if (param.includes('patient')) {
            this.setState({isPatientSortedAsc: true});
        }

        if (param.includes('treatment')) {
            this.setState({isTreatmentTypeSortedAsc: true});
        }

        if (param.toLocaleLowerCase().includes('status')) {
            this.setState({isStatusSortedAsc: true});
        }
    };

    private sortDataTableDesc = (param: string) => {
        this.props.list(
            `treatment_plans?page=1&itemsPerPage=10&order[${param}]=desc`,
            this.props.authToken
        );

        this.setState({
            isTreatmentTypeSortedAsc: null,
            isPatientSortedAsc: null,
            isStatusSortedAsc: null
        });

        if (param.includes('patient')) {
            this.setState({isPatientSortedAsc: false});
        }

        if (param.includes('treatment')) {
            this.setState({isTreatmentTypeSortedAsc: false});
        }

        if (param.toLocaleLowerCase().includes('status')) {
            this.setState({isStatusSortedAsc: false});
        }
    };

    private handleTreatmentMultiselect = (values: IMultiselectOption, action: any) => {
        return this.props.list(
            `treatment_plans?page=1&itemsPerPage=10&treatmentType.name=${values.label}`,
            this.props.authToken
        );
    };

    private toggleAddAfterCareModal = () => {
        this.setState({addAfterCareModalShown: !this.state.addAfterCareModalShown});
    };

    private closeAddAfterCareModal = () => {
        return this.setState({addAfterCareModalShown: false});
    };

    private getTreatmentTypes() {
        return getTreatmentTypesAPI().pipe(
            tap((response: {[key: string]: any}) => {
                let treatmentTypes: {[key: string]: any} = [];
                this.props.clinicTreatmentCategories.map((category) => {
                    return response['hydra:member'].forEach((type: {[key: string]: any}) => {
                        if (type.treatmentCategory.id === category.id) {
                            return treatmentTypes.push(type);
                        }
                    })
                });

                let options: IMultiselectOption[] = [];
                treatmentTypes.map((type: any) => {
                    let option: IMultiselectOption = {
                        value: type.id,
                        label: type.name
                    };

                    options.push(option);
                });

                this.setState({treatmentTypes: options});
            })
        ).subscribe();
    }

    private getPatientsList = () => {
        return this.props.patientsList('patients?itemsPerPage=100', this.props.authToken);
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedAfterCareListSelector(state),
        loading: afterCareListLoadingSelector(state),
        error: afterCareListErrorSelector(state),
        eventSource: afterCareListEventSourceSelector(state),
        authToken: authTokenSelector(state),
        clinicTreatmentCategories: clinicTreatmentCategoriesSelector(state),
        patients: retrievedPatientsListSelector(state)
    }),
    {
        list,
        reset,
        patientsList
    }
)(withRouter(TreatmentPlannerList)));
