import { combineReducers } from "redux";
import saga from "./sagaSlice";
import {connectRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {
  alertSlice,
  authSlice,
  loginSlice,
  registrationSlice,
  resetPasswordSlice,
  changePasswordSlice,
  clinicSlice,
  patientSlice,
  inquirySummarySlice,
  clinicProfileSlice,
  consultationDataSlice,
} from "common-web";
import inquiry from "../../reducers/inquiry";
import accountSlice from "./accountSlice";
import specialistSlice from "./specialistSlice";
import calendarSlice from "./calendarSlice";
import specialistList from "../../reducers/specialist";
import patientList from "../../reducers/patient";
import consultation from "../../reducers/consultation";
import calendar from "../../reducers/calendar";
import aftercare from "../../reducers/treatmentPlanner";
import recommendationDefinition from "../../reducers/recommendationDefinition";
import invoices from "../../reducers/invoices";

const history = createBrowserHistory();

const rootReducer = combineReducers({
    saga: saga,
    account: accountSlice,
    auth: authSlice,
    templateCalendar: calendarSlice,
    login: loginSlice,
    registration: registrationSlice,
    resetPassword: resetPasswordSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    inquiry: inquiry,
    consultation: consultation,
    calendar: calendar,
    clinic: clinicSlice,
    specialist: specialistSlice,
    specialistList: specialistList,
    patient: patientSlice,
    patientList: patientList,
    inquirySummary: inquirySummarySlice,
    clinicProfile: clinicProfileSlice,
    consultationData: consultationDataSlice,
    afterCare: aftercare,
    recommendationDefinition: recommendationDefinition,
    invoices: invoices,
    router: connectRouter(history)
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
