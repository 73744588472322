import React from "react";
import { connect } from "react-redux";
import {DateConverter, transformToPriceString, Translation} from "common-web";
import styles from "./styles.module.scss";


interface ISubscriptionPlansHistoryConnectedProps {}

interface ISubscriptionPlansHistoryExternalProps {
    clinicSubscriptions: {[key: string]: any}[];
    paidSubscription: {[key: string]: any} | null;
}

interface ISubscriptionPlansHistoryProps extends
    ISubscriptionPlansHistoryConnectedProps,
    ISubscriptionPlansHistoryExternalProps {}

interface ISubscriptionPlansHistoryState {
    isLoading: boolean;
}

class SubscriptionPlansHistory extends React.Component<ISubscriptionPlansHistoryProps, ISubscriptionPlansHistoryState> {
    constructor(props: ISubscriptionPlansHistoryProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidMount(): void {}

    componentDidUpdate(
        prevProps: Readonly<ISubscriptionPlansHistoryProps>,
        prevState: Readonly<ISubscriptionPlansHistoryState>,
        snapshot?: any
    ): void {}

    render() {
        return (
            <div>
                <div className="view-subtitle">
                    <Translation text={'billings.subscriptions.history.title'}/>
                </div>

                {this.renderSubscriptions()}
            </div>

        );
    }

    private renderSubscriptions = () => {
        if (!this.props.clinicSubscriptions ||
            !Array.isArray(this.props.clinicSubscriptions) ||
            !this.props.clinicSubscriptions.length) {
            return <p>There is no data available</p>;
        }

        return (
            <React.Fragment>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th><Translation text={'billings.subscriptions.history.table.type'}/></th>
                        <th><Translation text={'billings.subscriptions.history.table.paymentDate'}/></th>
                        <th><Translation text={'billings.subscriptions.history.table.startDate'}/></th>
                        <th><Translation text={'billings.subscriptions.history.table.endDate'}/></th>
                        <th><Translation text={'billings.subscriptions.history.table.price'}/></th>
                        <th><Translation text={'billings.subscriptions.history.table.nextPayment'}/></th>
                        <th><Translation text={'billings.subscriptions.history.table.status'}/></th>
                        <th><Translation text={'billings.subscriptions.history.table.status'}/></th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    };

    private renderTableRows() {
        const rows: any[] = [];
        const list = this.props.clinicSubscriptions.sort(this.sortMethod);

        list.map((item: any, index: number) => {
            let subscriptionDetails = item.subscriptionDefinition.subscriptionDetails;
            let payments = item.payments,
                currentPayment;
            if (payments.length >= 2) {
                payments.reduce((a: {[key: string]: any}, b: {[key: string]: any }) => {
                    return currentPayment = Number(a.value.amount) > Number(b.value.amount) ? a : b;
                });
            } else {
                currentPayment = payments[0];
            }

            let paymentData = payments.sort((a: any, b: any): number => {
                const aDate = new Date(a.paidAt),
                    bDate = new Date(b.paidAt);

                return aDate.getTime() > bDate.getTime() ? -1 : aDate.getTime() !== bDate.getTime() ? 1 : 0;
            });

            return rows.push((
                <tr key={index}>
                    <td>{subscriptionDetails.name ? subscriptionDetails.name : '---'}</td>
                    <td>{currentPayment?.paidAt ? <DateConverter date={currentPayment.paidAt}/> : '---'}</td>
                    <td>{item.validFrom ? <DateConverter date={item.validFrom}/> : '---'}</td>
                    <td>{item.validUntil ? <DateConverter date={item.validUntil}/> : '---'}</td>
                    <td>
                        {paymentData.length && paymentData[0].value ?
                            transformToPriceString(paymentData[0].value.currency.code, paymentData[0].value.amount / 100) :
                        '---'}
                    </td>
                    <td>{item.validUntil ? <DateConverter date={item.validUntil}/> : '---'}</td>
                    <td className="">
                        {item.status ? (<div className={`status-wrapper ${item.status}`}>
                            <p className="status">
                                <Translation text={`billings.invoices.statuses.${item.status}`}/>
                            </p>
                        </div>) : null}
                    </td>
                    <td>{this.renderPaymentStatus(item)}</td>
                </tr>
            ))
        });
        return rows;
    }

    private sortMethod(a: any, b: any): number {
        const aDate = new Date(a.purchasedAt),
            bDate = new Date(b.purchasedAt),
            aTime = aDate.getTime(),
            bTime = bDate.getTime();

        return aTime > bTime ? -1 : aTime !== bTime ? 1 : 0;
    }

    private renderPaymentStatus = (item: {[key: string]: any}) => {
        const paymentId = item?.payments[0]?.id;
        if (!this.props.paidSubscription || !paymentId || paymentId !== this.props.paidSubscription?.id) {
            return (<p>---</p>);
        }

        return (
            <button className={styles.actionButton}
                    onClick={() => this.redirectToPaymentStatus()}>
                <Translation text={'billings.subscriptions.checkPaymentStatus'}/>
            </button>
        );
    }

    private redirectToPaymentStatus = () => {
        if (!this.props.paidSubscription) {
            return;
        }
        return window.open(this.props.paidSubscription?.vendorData?.hostedInvoiceUrl, '_blank', 'noopener, noreferrer');
    }
}

export default connect(
    () => ({}),
    {}
)(SubscriptionPlansHistory);
