import {RootState} from "../reducers";
import { createSelector } from "redux-starter-kit";
import {IPatientListBaseState} from "./patientListSelectors";

export interface IRetrievedPatientState extends IPatientListBaseState {
    retrieved: any; // object
    eventSource: EventSource;
}

export interface IDeletePatientState extends IPatientListBaseState {
    deleted: any; // model for inquiry
}

export interface ICreatePatientState extends IPatientListBaseState {
    created: any; // model for inquiry
}

export interface IUpdatePatientState {
    retrieved: any; // model for inquiry
    retrieveError: string;
    retrieveLoading: boolean;
    updateError: string;
    updateLoading: boolean;
    eventSource: EventSource;
    updated: any; // model for inquiry
}

export const selectRetrievedPatient = (state: RootState): IRetrievedPatientState => {
    return state.patientList.show;
};

export const selectDeletePatient = (state: RootState): IDeletePatientState => {
    return state.patientList.del;
};

export const selectCreatePatient = (state: RootState): ICreatePatientState => {
    return state.patientList.create;
};

export const selectUpdatePatient = (state: RootState): IUpdatePatientState => {
    return state.patientList.update;
};

export const retrievedPatientSelector = createSelector(
    [selectRetrievedPatient],
    (state: IRetrievedPatientState) => state.retrieved
);

export const retrievedPatientLoadingSelector = createSelector(
    [selectRetrievedPatient],
    (state: IRetrievedPatientState) => state.loading
);

export const retrievedPatientErrorSelector = createSelector(
    [selectRetrievedPatient],
    (state: IRetrievedPatientState) => state.error
);

export const retrievedPatientEventSourceSelector = createSelector(
    [selectRetrievedPatient],
    (state: IRetrievedPatientState) => state.eventSource
);

export const retrievedPatientDeleteErrorSelector = createSelector(
    [selectDeletePatient],
    (state: IDeletePatientState) => state.error
);

export const retrievedPatientDeleteLoadingSelector = createSelector(
    [selectDeletePatient],
    (state: IDeletePatientState) => state.loading
);

export const retrievedSpecialistDeletedSelector = createSelector(
    [selectDeletePatient],
    (state: IDeletePatientState) => state.deleted
);

export const retrievedPatientCreateErrorSelector = createSelector(
    [selectCreatePatient],
    (state: ICreatePatientState) => state.error
);

export const retrievedPatientCreateLoadingSelector = createSelector(
    [selectCreatePatient],
    (state: ICreatePatientState) => state.loading
);

export const retrievedPatientCreatedSelector = createSelector(
    [selectCreatePatient],
    (state: ICreatePatientState) => state.created
);

export const retrievedPatientUpdateRetrievedSelector = createSelector(
    [selectUpdatePatient],
    (state: IUpdatePatientState) => state.retrieved
);

export const retrievedPatientUpdateRetrieveErrorSelector = createSelector(
    [selectUpdatePatient],
    (state: IUpdatePatientState) => state.retrieveError
);

export const retrievedPatientUpdateRetrieveLoadingSelector = createSelector(
    [selectUpdatePatient],
    (state: IUpdatePatientState) => state.retrieveLoading
);

export const retrievedPatientUpdateErrorSelector = createSelector(
    [selectUpdatePatient],
    (state: IUpdatePatientState) => state.updateError
);

export const retrievedPatientUpdateLoadingSelector = createSelector(
    [selectUpdatePatient],
    (state: IUpdatePatientState) => state.updateLoading
);

export const retrievedPatientUpdatedSelector = createSelector(
    [selectUpdatePatient],
    (state: IUpdatePatientState) => state.updated
);

export const retrievedPatientUpdateEventSourceSelector = createSelector(
    [selectUpdatePatient],
    (state: IUpdatePatientState) => state.eventSource
);
