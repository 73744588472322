import React from "react";
import {CustomCard, Form, Translation} from "common-web";
import {treatmentCategoriesFormConfig} from "./treatmentCategoriesFormConfig";

interface IConnectedClinicTreatmentsProps {}

interface IExternalClinicTreatmentsProps {
  clinic: {[key: string]: any} | null;
  isLoading: boolean;
}

interface IClinicTreatmentsProps extends
  IExternalClinicTreatmentsProps,
  IConnectedClinicTreatmentsProps {}

interface IClinicTreatmentsState {
  formConfig: any;
  value: any;
}

class ClinicTreatments extends React.Component<IClinicTreatmentsProps, IClinicTreatmentsState> {
  constructor(props: IClinicTreatmentsProps) {
    super(props);

    this.state = {
      formConfig: treatmentCategoriesFormConfig,
      value: null
    };
  }

  componentDidMount(): void {
    if (this.props.clinic) {
      this.updateFormFromState();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IClinicTreatmentsProps>,
    prevState: Readonly<IClinicTreatmentsState>,
    snapshot?: any
  ): void {
    if (this.props.clinic !== prevProps.clinic && this.props.clinic) {
      this.updateFormFromState();
    }
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.props.isLoading}>
        <CustomCard.Header>
            <Translation text={'profile.treatmentCategories.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
          <Form config={this.state.formConfig}
                // btnText="Save"//
                value={this.state.value}
                controlName={'clinicTreatmentsForm'}/>
        </CustomCard.Body>
      </CustomCard>
    );
  }

  private mapAccountToFormData(clinicProfile: any): any {
    return {
      treatmentCategories: clinicProfile.treatmentCategories,
    };
  }

  updateFormFromState() {
    this.setState({
      value: this.mapAccountToFormData(this.props.clinic)
    })
  }
}

export default ClinicTreatments;
