import {ICalendarRule} from '../store/reducers/calendarSlice';
import {getUTCTime} from './dateTransformUtils';

export function isSameRule(a: ICalendarRule, b: ICalendarRule): boolean {
    const aDate = new Date(a.startsAt),
        bDate = new Date(b.startsAt);

    if (aDate.getDay() !== bDate.getDay()) {
        return false;
    }

    return getUTCTime(a.startsAt) === getUTCTime(b.startsAt);
}
