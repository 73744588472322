import React from "react";
import PersonalDataForm from "./PersonalData";
import ChangePasswordForm from "./ChangePassword";
import AddressDataForm from "./AddressData";
import ClinicLogo from './ClinicLogo';
import {accountLoadingSelector, addAlert, authTokenSelector, Loader, updateCompanyName, Translation} from "common-web";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {
    MainAccountData,
    PaymentAccount,
    setAccountData,
    SubscriptionDetails,
    Unit
} from "../../store/reducers/accountSlice";
import {getAccountDataAPI} from "../../api/getAccountData";
import {accountSelector} from "../../store/selectors/accountSelectors";


interface IConnectedSettingsProps {
    readonly authToken: string | null;
    readonly account: any;
    readonly updateCompanyName: typeof updateCompanyName;
    readonly setAccountData: typeof setAccountData;
    readonly addAlert: typeof addAlert;
}

interface ISettingsState {
    accountDataIsLoading: boolean;
}

interface IExternalSettingsProps {
}

interface ISettingsProps extends IConnectedSettingsProps,
    IExternalSettingsProps {
}


class Settings extends React.Component<ISettingsProps, ISettingsState> {
    constructor(props: ISettingsProps) {
        super(props);

        this.state = {
            accountDataIsLoading: false,
        };
    }

    componentDidMount() {
        this.setState({accountDataIsLoading: true});
        if (this.props.authToken) {
            getAccountDataAPI(this.props.authToken).subscribe(
                resp => {
                    this.updateStateFromApi(resp);
                    this.setState({accountDataIsLoading: false});
                },
                err => {
                    this.props.addAlert({
                        message: err.response ? err.response.message : "Something went wrong. Please try again later.",
                    });
                    this.setState({accountDataIsLoading: false})
                }
            );
        } else {

            this.setState({accountDataIsLoading: false})
        }
    }

    render() {
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'setting.title'}/>
                            </div>
                        </div>
                    </div>
                </div>

                <Loader showLoader={this.state.accountDataIsLoading}/>

                {this.renderCards()}

            </React.Fragment>
        );
    }

    private renderCards() {
        if (this.state.accountDataIsLoading) {
            return;
        } else {
            return (
                <div className="row">
                    <div className="col-xl-6">
                        <ChangePasswordForm/>
                        <PersonalDataForm/>
                    </div>
                    <div className="col-xl-6">
                        <ClinicLogo/>
                        <AddressDataForm/>
                    </div>
                </div>
            )
        }
    }

    private updateStateFromApi(apiData: any) {
        const accountData = apiData.account,
            addressDataFromApi = accountData.address,
            invoiceDataFromApi = accountData.invoiceAddress,
            personalDataFromApi: Unit = {
                firstName: accountData.firstName,
                lastName: accountData.lastName,
                phone: accountData.phone,
                birthDate: accountData.birthDate,
                residency: accountData.residency,
                timezone: accountData.timezone
            },
        paymentAccounts: PaymentAccount[] = accountData.paymentAccounts.map((account: {[key: string]: any}) => {
            return {
                id: account.id,
                paymentAccountVendorData: {
                    accountBankTransferBankName: account.paymentAccountVendorData.accountBankTransferBankName,
                    accountBankTransferCountry: account.paymentAccountVendorData.accountBankTransferCountry,
                    accountBankTransferCurrency: account.paymentAccountVendorData.accountBankTransferCurrency,
                    accountBankTransferLastFour: account.paymentAccountVendorData.accountBankTransferLastFour,
                    accountEmail: account.paymentAccountVendorData.accountEmail,
                    chargesEnabled: account.paymentAccountVendorData.chargesEnabled,
                    customerId: account.paymentAccountVendorData.customerId,
                    customerPaymentMethodBrand: account.paymentAccountVendorData.customerPaymentMethodBrand,
                    customerPaymentMethodExpMonth: account.paymentAccountVendorData.customerPaymentMethodExpMonth,
                    customerPaymentMethodExpYear: account.paymentAccountVendorData.customerPaymentMethodExpYear,
                    customerPaymentMethodId: account.paymentAccountVendorData.customerPaymentMethodId,
                    customerPaymentMethodLastFour: account.paymentAccountVendorData.customerPaymentMethodLastFour,
                    customerPaymentMethodName: account.paymentAccountVendorData.customerPaymentMethodName,
                    paymentAccountId: account.paymentAccountVendorData.paymentAccountId
                },
                paymentAccountVendorType: account.paymentAccountVendorType
            }
        }),
            subscription: SubscriptionDetails = {
                currentSubscriptionEntry: accountData.subscription.currentSubscriptionEntry,
                id: accountData.subscription.id,
                proposedPlans: accountData.subscription.proposedPlans,
                subscriptionEntries: accountData.subscription.subscriptionEntries,
                upcomingSubscription: accountData.subscription.upcomingSubscription
            };
        const updatedAccountState: MainAccountData = {
            personalData: personalDataFromApi,
            addressData: addressDataFromApi,
            invoiceData: invoiceDataFromApi,
            paymentAccounts: paymentAccounts,
            subscription: subscription,
            id: apiData.account.id
        };
        this.props.updateCompanyName(apiData.clinic.companyName);
        this.props.setAccountData(updatedAccountState);
    }
}


export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isLoading: accountLoadingSelector(state),
        account: accountSelector(state),
    }),
    {
        setAccountData,
        updateCompanyName,
        addAlert
    }
)(Settings);
