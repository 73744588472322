export default {
  items: [
    {
      id: 'Dashboard',
      title: 'Clinic',
      type: 'group',
      icon: '',
      children: [

        // {
        //     id: 'dashboard',
        //     title: 'Dashboard',
        //     type: 'item',
        //     url: '/dashboard',
        //     icon: 'feather icon-home',
        // },
          {
              id: 'specialists',
              title: 'Specialists',
              type: 'item',
              url: '/dashboard/specialists?page=1&itemsPerPage=10',
              icon: 'feather icon-activity',
          },
          {
              id: 'patients',
              title: 'Patients',
              type: 'item',
              url: '/dashboard/patients?page=1&itemsPerPage=10',
              icon: 'feather icon-users',
          },
      ]
    },
      {
          id: 'modules',
          title: 'Modules',
          type: 'group',
          icon: '',
          children: [
              {
                  id: 'chats',
                  title: 'chats with patients',
                  type: 'item',
                  url: '/dashboard/chats',
                  icon: 'feather icon-message-square',
              },
              {
                  id: 'insurance',
                  title: 'Insurances',
                  type: 'collapse',
                  icon: 'feather icon-file-minus',
                  children: [
                      {
                          id: 'insuranceReport',
                          title: 'Report',
                          type: 'item',
                          url: '/dashboard/inquiries?page=1&itemsPerPage=10'
                      },
                      // Menu items commented out due to the client's request
                      // {
                      //     id: 'insurancePatient',
                      //     title: 'Generate Link For Your Patient',
                      //     type: 'item',
                      //     url: '/dashboard/inquiries/create'
                      // },
                      // {
                      //     id: 'buyInsurancePatient',
                      //     title: 'Buy Insurance For Your Patient',
                      //     type: 'item',
                      //     url: '/dashboard/inquiries/purchase-inquiry'
                      // },
                      {
                          id: 'insuranceWidget',
                          title: 'Get Insurance Widget',
                          type: 'item',
                          url: '/dashboard/inquiries/widget'
                      }
                  ]
              },
              {
                  id: 'consultation',
                  title: 'Video Consultations',
                  type: 'collapse',
                  icon: 'feather icon-monitor',
                  children: [
                      {
                          id: 'consultationReport',
                          title: 'Report',
                          type: 'item',
                          url: '/dashboard/consultations?page=1&itemsPerPage=10'
                      },
                      {
                          id: 'consultationWidget',
                          title: 'Calendar Widgets',
                          type: 'item',
                          url: '/dashboard/consultations/widget'
                      }
                  ]
              },
              {
                  id: 'treatmentPlanner',
                  title: 'Treatment Planner',
                  type: 'collapse',
                  icon: 'feather icon-smartphone',
                  children: [
                      {
                          id: 'aftercareReport',
                          title: 'Report',
                          type: 'item',
                          url: '/dashboard/aftercare?page=1&itemsPerPage=10'
                      },
                      {
                          id: 'predefinedRecommendations',
                          title: 'Predefined Events',
                          type: 'item',
                          url: '/dashboard/recommendations?page=1&itemsPerPage=10'
                      }
                  ]
              }
          ]
      },
  ]
}
