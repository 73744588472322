import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterCalendarAPI} from "./provider/clinicHunterCalendar";
import {ICalendarCreationPayload} from "../store/reducers/calendarSlice";
import {deepCloneObject} from "../utils/runtimeUtils";

export function updateCalendarAPI(
    calendarId: string,
    authToken: string,
    calendarData: ICalendarCreationPayload
): Observable<any> {
    const specialistEmail = calendarData.specialistEmail === '' ? null : calendarData.specialistEmail;
    const calendarRulesWithoutTags = calendarData.calendarRules.map((calendarRule: any) => {
        let newCalendarRule = deepCloneObject(calendarRule);
        newCalendarRule.tags = null;
        newCalendarRule.calendarId = calendarId;
        return newCalendarRule;
    });
    const payload: any = {
        name: calendarData.name,
        public: calendarData.public,
        price: calendarData.price,
        slotLength: calendarData.slotLength,
        availableFrom: calendarData.availableFrom,
        availableUntil: calendarData.availableUntil,
        timezone: calendarData.timezone,
        calendarRules: calendarRulesWithoutTags,
        clinicEmail: calendarData.clinicEmail,
        specialistEmail: specialistEmail
    };

    return clinicHunterCalendarAPI.put(
        `calendar/${calendarId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
