import React from 'react';
import {connect} from 'react-redux';
import styles from "./styles.module.scss";
import {addAlert, clinicIdSelector, Translation} from 'common-web';
import {RootState} from '../../../store/reducers';

interface IInsuranceCreateHostConnectedProps {
    addAlert: typeof addAlert;
    clinicId: string;
}

interface IInsuranceCreateHostConnectedState {
    inquiryUrl: string | null;
    iNumber: number;
}

class InsuranceCreateHost extends React.Component<IInsuranceCreateHostConnectedProps, IInsuranceCreateHostConnectedState> {
    constructor(props: any) {
        super(props);
        this.state = {
            inquiryUrl: null,
            iNumber: 0,
        };
    }

    componentDidMount() {
        this.setState({inquiryUrl: null});
        window.addEventListener('message', this.handleIframeTask);
    }

    handleIframeTask = (e: MessageEvent) => {
        if (e.origin !== process.env.REACT_APP_AXA_INSURANCE_URL || e.data === this.state.inquiryUrl) {
            return;
        } else {
            this.setState({inquiryUrl: e.data !== null ? e.origin + '/en' + e.data : null});
        }
    };

    componentWillUnmount() {
        window.removeEventListener('message', this.handleIframeTask);
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title"><Translation text={'inquiries.createInquiry.title'}/></div>
                            <div className="action-container">
                                <button className="btn btn-theme"
                                        type="button"
                                        onClick={e => this.refreshIframe(e)}>
                                    <Translation text={'inquiries.createInquiry.newButton'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="d-flex justify-content-end mb-4">
                            <div className={styles.snippet} onClick={() => this.copyToClipboard()}>
                                <div className={styles.snippetIconContainer}>
                                    <i className="feather icon-copy"/>
                                </div>

                                {this.renderSnippet()}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row flex-grow-1">
                    <div className="col-xl-12 d-flex">
                        <div className={styles.iframeContainer}>
                            <iframe title="Axa insurance form for clinic"
                                    className={styles.iframe}
                                    key={this.state.iNumber}
                                    src={`${process.env.REACT_APP_AXA_INSURANCE_URL}/c/inquiry/treatment/?cid=${this.props.clinicId}`}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderSnippet() {
        if (this.state.inquiryUrl) {
            return (
                <div>
                    <p><Translation text={'inquiries.createInquiry.linkGenerated'}/></p>
                </div>
            )
        }

        return (<div><Translation text={'inquiries.createInquiry.linkNotGenerated'}/></div>);
    }

    private copyToClipboard = () => {
        if (this.state.inquiryUrl) {
            navigator.clipboard.writeText(this.state.inquiryUrl);
            this.props.addAlert({message: 'Client link copied to clipboard. You can now send it to client.'});
        }
        return;
    };

    private refreshIframe(e: any) {
        e.preventDefault();

        this.setState({
            iNumber: this.state.iNumber + 1
        })
    }
}

export default connect(
    (state: RootState) => ({
        clinicId: clinicIdSelector(state)
    }),
    {
        addAlert,
    }
)(InsuranceCreateHost);
