import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterCalendarAPI} from "./provider/clinicHunterCalendar";
import {ICalendarCreationPayload} from "../store/reducers/calendarSlice";

export function createCalendarAPI(
    authToken: string | undefined,
    calendarData: ICalendarCreationPayload
): Observable<any> {
    const payload: any = {
        name: calendarData.name,
        public: calendarData.public,
        price: calendarData.price,
        slotLength: calendarData.slotLength,
        availableFrom: calendarData.availableFrom,
        availableUntil: calendarData.availableUntil,
        timezone: calendarData.timezone,
        calendarRules: calendarData.calendarRules,
        clinicEmail: calendarData.clinicEmail,
        specialistEmail: calendarData.specialistEmail
    };

    return clinicHunterCalendarAPI.post(
        "calendars",
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
