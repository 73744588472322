import {
  fetch,
  extractHubURL,
  normalize,
  mercureSubscribe as subscribe
} from '../../utils/dataAccess';
import {addAlert} from "common-web";

export function error(error: string | null) {
  return { type: 'CALENDAR_SHOW_ERROR', error };
}

export function loading(loading: boolean) {
  return { type: 'CALENDAR_SHOW_LOADING', loading };
}

export function success(retrieved: any) {
  return { type: 'CALENDAR_SHOW_SUCCESS', retrieved };
}

export function retrieve(id: string, authToken: string) {
  return (dispatch: any) => {
    dispatch(loading(true));

    return fetch(id, authToken)
        .then((response: any) =>
            response
                .json()
                .then((retrieved: any)=> ({ retrieved, hubURL: extractHubURL(response) }))
        )
        .then(({ retrieved, hubURL }: {[key: string]: any}) => {
          retrieved = normalize(retrieved);
          // map date parts to correct dates according to frontend logic
          retrieved.calendarRules.forEach((rule: any) => {
            const datePart = rule.intervalStart.substr(0, rule.intervalStart.indexOf('T'));
            rule.startsAt = datePart + rule.startsAt.substr(rule.startsAt.indexOf('T'));
            rule.endsAt = datePart + rule.endsAt.substr(rule.endsAt.indexOf('T'));
          });

          dispatch(loading(false));
          dispatch(success(retrieved));

          if (hubURL) dispatch(mercureSubscribe(hubURL, retrieved['@id']));
        })
        .catch((e: any) => {
          dispatch(loading(false));
          dispatch(addAlert({message: e.message}));
          dispatch(error(e.message));
        });
  };
}

export function reset(eventSource: EventSource) {
  return (dispatch: any) => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'CALENDAR_SHOW_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
  };
}

export function mercureSubscribe(hubURL: any, topic: any) {
  return (dispatch: any) => {
    const eventSource = subscribe(hubURL, [topic]);
    dispatch(mercureOpen(eventSource));
    eventSource.addEventListener('message', event =>
        dispatch(mercureMessage(normalize(JSON.parse(event.data))))
    );
  };
}

export function mercureOpen(eventSource: any) {
  return { type: 'CALENDAR_SHOW_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved: any) {
  return (dispatch: any) => {
    if (1 === Object.keys(retrieved).length) {
      dispatch({ type: 'CALENDAR_SHOW_MERCURE_DELETED', retrieved });
      return;
    }

    dispatch({ type: 'CALENDAR_SHOW_MERCURE_MESSAGE', retrieved });
  };
}
