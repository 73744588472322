import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterClinicAPI} from "./provider/clinicHunterClinic";

export enum PaymentServiceName {
    STRIPE = 'stripe'
}

export function buySubscriptionDefinitionAPI(
    authToken: string,
    subscriptionId: string,
    paymentServiceName: PaymentServiceName,
): Observable<any> {
    const payload = {
        paymentServiceName: paymentServiceName,
    };
    return clinicHunterClinicAPI.put(
        `subscription_definitions/${subscriptionId}/buy`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
