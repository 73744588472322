import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface ISpecialistListBaseState {
    loading: boolean;
    error: string;
}

export interface ISpecialistListState extends ISpecialistListBaseState {
    retrieved: any; // model for inquiry
    eventSource: EventSource;
}

export const selectSpecialistList = (state: RootState): ISpecialistListState => {
    return state.specialistList.list;
};

export const retrievedSpecialistListSelector = createSelector(
    [selectSpecialistList],
    (state: ISpecialistListState) => state.retrieved
);

export const specialistListLoadingSelector = createSelector(
    [selectSpecialistList],
    (state: ISpecialistListState) => state.loading
);

export const specialistListErrorSelector = createSelector(
    [selectSpecialistList],
    (state: ISpecialistListState) => state.error
);

export const specialistListEventSourceSelector = createSelector(
    [selectSpecialistList],
    (state: ISpecialistListState) => state.eventSource
);
