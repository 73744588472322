import { Observable } from "rxjs";
import {IBlockerEvent, RestQueryParams} from "common-web";
import {clinicHunterCalendarAPI} from "./provider/clinicHunterCalendar";

export function editSlotsAvailability(
    slotBlockerEvents: typeof IBlockerEvent[],
    calendarId: string,
    authToken: string
): Observable<any> {
    return clinicHunterCalendarAPI.put(
        `calendar/${calendarId}/edit_slots_availability`,
        {
            slots: slotBlockerEvents,
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        },
    );
}
