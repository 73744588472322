import React from 'react';
import {
    authTokenSelector,
    BasicModal,
    changeConsultationDataLoading,
    consultationDataLoadingSelector,
    consultationSlotsToBlockSelector,
    CustomCard,
    CustomCardType,
    getAvailableConsultationSlots,
    IBlockerEvent,
    setConsultationDataError,
    setSlotsToBlock,
    Translation
} from 'common-web';
import {connect} from 'react-redux';
import {calendarIdSelector} from '../../../../../store/selectors/calendarSelectors';
import {RootState} from '../../../../../store/reducers';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IAlertManagerService} from "../../../../../service/alertManagerService";
import {editSlotsAvailability} from "../../../../../api/editSlotsAvailabilityAPI";
import moment from "moment";

interface IConnectedBlockSlotsCalendarModalProps {
    readonly slotsToBlock: typeof IBlockerEvent[];
    readonly calendarId: string,
    readonly authToken: string;
    readonly getAvailableConsultationSlots: typeof getAvailableConsultationSlots;
    readonly setSlotsToBlock: typeof setSlotsToBlock;
}

export interface IBlockSlotsCalendarModalProps extends IConnectedBlockSlotsCalendarModalProps {
    blockSlotsModalShown: boolean,
    redirectToMonth: any
}

interface IBlockSlotsCalendarModalState {
    blockSlotsModalShown: boolean,
    isLoading: boolean;
}

class BlockSlotsCalendarModal extends React.Component<IBlockSlotsCalendarModalProps, IBlockSlotsCalendarModalState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);
        this.state = {
            blockSlotsModalShown: false,
            isLoading: false,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.blockSlotsModalShown) {
            this.setState({blockSlotsModalShown: this.props.blockSlotsModalShown});
        }
    }

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {
        if (this.props.blockSlotsModalShown !== prevProps.blockSlotsModalShown && this.props.blockSlotsModalShown) {
            this.setState({blockSlotsModalShown: this.props.blockSlotsModalShown});
        }
    }

    componentWillUnmount() {
        if (null !== this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        return (
            <BasicModal isModalShown={this.state.blockSlotsModalShown} closeModal={this.closeBlockSlotsModal}>
                <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <h4><Translation text={'calendar.modals.confirmBlockSlotsModal.title'}/></h4>
                            <button className="btn-modal-close" onClick={() => this.toggleBlockSlotsModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><Translation text={'calendar.modals.confirmBlockSlotsModal.question'}/></p>
                        </div>
                    </CustomCard.Body>
                    <CustomCard.Footer>
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-secondary-theme mr-4"
                                    type="button"
                                    onClick={() => this.blockTimeSlots()}>
                                <Translation text={'calendar.modals.buttons.yes'}/>
                            </button>
                            <button className="btn btn-danger-outline"
                                    type="button"
                                    onClick={() => this.closeBlockSlotsModal()}>
                                <Translation text={'calendar.modals.buttons.no'}/>
                            </button>
                        </div>
                    </CustomCard.Footer>
                </CustomCard>
            </BasicModal>
        );
    }

    private blockTimeSlots = () => {
        this.setState({isLoading: true});

        this.subscriptions.push(editSlotsAvailability(this.props.slotsToBlock, this.props.calendarId, this.props.authToken).pipe(
            tap(() => {
                this.props.setSlotsToBlock([]);
                this.alertManagerService.addAlert('Successfully updated blocked slots');
                this.setState({isLoading: false, blockSlotsModalShown: false});
                this.getCurrentMonthAvailableSlots(this.props.calendarId);
            }),
            catchError((error: any) => {
                const errorMessage: string = error.response ? error.response['hydra:description'] : 'Error occurred. Please try again later';
                this.setState({isLoading: false, blockSlotsModalShown: false});
                return of(
                    changeConsultationDataLoading(false),
                    setConsultationDataError(error),
                    this.alertManagerService.addAlert(errorMessage)
                )
            })).subscribe());
    };

    private getCurrentMonthAvailableSlots(calendarId: string) {
        const date = new Date(),
            month = new Date().getMonth() + 1,
            firstDay = moment(new Date(date.getFullYear(), date.getMonth(), 1)).toISOString(true),
            lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).toISOString(true);
        this.props.getAvailableConsultationSlots(firstDay, lastDay, calendarId, month);
    }

    private toggleBlockSlotsModal = () => {
        this.setState({blockSlotsModalShown: !this.state.blockSlotsModalShown});
    };

    private closeBlockSlotsModal = () => {
        return this.setState({blockSlotsModalShown: false});
    };
}

export default connect(
    (state: RootState) => ({
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
        slotsToBlock: consultationSlotsToBlockSelector(state),
        consultationIsLoading: consultationDataLoadingSelector(state),
    }),
    {
        setSlotsToBlock,
        getAvailableConsultationSlots,
    }
)(BlockSlotsCalendarModal);
