import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { retrieve, reset } from '../../../../actions/inquiry/show';
import { del } from '../../../../actions/inquiry/delete';
import { RootState } from "../../../../store/reducers";
import {
    authTokenSelector,
    CustomCard,
    DateConverter,
    Translation,
    AccountAddressBox
} from 'common-web';
import {
  inquiryItemDeletedSelector,
  inquiryItemDeleteErrorSelector,
  inquiryItemDeleteLoadingSelector,
  inquiryItemErrorSelector,
  inquiryItemEventSourceSelector,
  inquiryItemLoadingSelector,
  retrievedInquiryItemSelector
} from "../../../../store/selectors/inquiryItemSelectors";

interface IConnectedShowProps {
  readonly retrieved?: any;
  readonly loading: boolean;
  readonly error?: string;
  readonly eventSource?: EventSource;
  readonly deleteError?: string;
  readonly deleteLoading: boolean;
  readonly authToken: string;
  readonly deleted?: any;
  readonly retrieve: typeof retrieve;
  // readonly reset: typeof reset;
  readonly reset: any;
  readonly del: typeof del;
}

interface IExternalShowProps {}

interface IShowProps extends
  IConnectedShowProps,
  IExternalShowProps,
  RouteComponentProps {}

class InsuranceView extends React.Component<IShowProps> {

  componentDidMount() {
    const slug = this.props.match.params;
    this.props.retrieve(`inquiries/${(slug as any).id}`, this.props.authToken);
  }

  componentWillUnmount() {
    if (this.props.eventSource) {
      this.props.reset(this.props.eventSource);
    }
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved, this.props.authToken);
  };

  render() {
    if (this.props.deleted) return <Redirect to=".." />;

    const item = this.props.retrieved;

    if (!item) {
        return 'Loading'; // toDO loader PPP
    }

    return (
      <React.Fragment>
          <div className="row">
              <div className="col-xl-6">
                  <div className="view-header">
                      <div className="view-title">
                          <Translation text={'inquiries.view.title'}/>
                      </div>
                      <div className="action-container">
                          <div onClick={() => this.props.history.goBack()} className="btn btn-theme">
                              <span className="feather icon-chevron-left"/>
                              Back
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="row">
              <div className="col-xl-6">

                <CustomCard showLocalLoader={this.props.loading}>
                  <CustomCard.Body>
                    {item && (
                      <table className="data-table">
                        <thead>
                          <tr>
                            <th>Reference Number</th>
                            <th>{item.acceptedOffer.reference}</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Patient</td>
                            <td>{ this.renderPatient(item) }</td>
                        </tr>
                        <tr>
                            <td>Purchase date</td>
                            <td><DateConverter date={item.acceptedOffer.paidAt} /></td>
                        </tr>
                        <tr>
                            <td>Price</td>
                            <td>€ {(item.acceptedOffer.premium.amount / 100).toFixed(2)} </td>
                        </tr>
                          {/*<tr>*/}
                            {/*<td>Life Threat</td>*/}
                            {/*<td>{item.lifeThreat}</td>*/}
                          {/*</tr>*/}
                        <tr>
                            <td>Created At</td>
                            <td><DateConverter date={item.createdAt} /></td>
                        </tr>
                        <tr>
                            <td>Treatment Category</td>
                            <td>{ item.treatmentCategory.name }</td>
                        </tr>
                          <tr>
                            <td>Companion</td>
                            <td> { this.renderCompanion(item) }</td>
                          </tr>
                        <tr>
                            <td>Id</td>
                            <td>{ item.acceptedOffer.id }</td>
                        </tr>

                      </tbody>
                      </table>
                    )}
                  </CustomCard.Body>
                </CustomCard>
              </div>
          </div>
      </React.Fragment>
    );
  }

  private renderPatient(item: any) {
      const mainPatient = item.inquirySubjects?.filter((subject: any) => subject.main === true)[0];

      if (!mainPatient) {
          return '-'
      }

      return (
          <React.Fragment>
              <span className="d-block font-weight-bold mb-2">{ mainPatient.firstName } {mainPatient.lastName }</span>
              {/*commented as main patient does not have phone added during purchase of insurance*/}
              {/*<span className="d-block mb-2">{ item.patient.account.phone }</span>*/}
              <AccountAddressBox address={mainPatient.address} />
          </React.Fragment>
      )
  }

  private renderCompanion(item: any) {
      if (item.inquirySubjects.length === 1) {
          return <span className="feather icon-minus"/>;
      }

      return (<span>{item.inquirySubjects.length - 1}</span>);
  }

  renderLinks = (type: any, items: any) => {
      console.log('------------------');
      console.log(type);
      console.log(items);
  // toDO PPP wtf? brak accepted offer i Inquiry type
  //     return null;
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../../${type}/show/${encodeURIComponent(items)}`}>
        {items}
      </Link>
    );
  };
}

export default connect(
  (state: RootState) => ({
    retrieved: retrievedInquiryItemSelector(state),
    error: inquiryItemErrorSelector(state),
    loading: inquiryItemLoadingSelector(state),
    eventSource: inquiryItemEventSourceSelector(state),
    deleteError: inquiryItemDeleteErrorSelector(state),
    deleteLoading: inquiryItemDeleteLoadingSelector(state),
    deleted: inquiryItemDeletedSelector(state),
    authToken: authTokenSelector(state)
  }),
  {
    retrieve,
    del,
    reset
  }
)(InsuranceView);
