import { createSelector } from "redux-starter-kit";
import { RootState } from "../reducers";
import {ICalendarState} from "../reducers/calendarSlice";
import {isNullOrUndefined} from "../../utils/runtimeUtils";

export const selectCalendar = (state: RootState): ICalendarState => {
    return (state as any).templateCalendar;
};

export const calendarStateSelector = createSelector(
    [selectCalendar],
    (state: ICalendarState) => state
);

export const calendarSelector = createSelector(
    [selectCalendar],
    (state: ICalendarState) => state.calendar
);

export const calendarIdSelector = createSelector(
    [selectCalendar],
    (state: ICalendarState) => state.calendarId
);

export const isCalendarCreatedSelector = createSelector(
    [selectCalendar],
    (state: ICalendarState) => state.isCalendarCreated
);

export const showCalendarLoaderSelector = createSelector(
    [selectCalendar],
    (state: ICalendarState) => state.showCalendarLoader
);

export const calendarSettingsSelector = createSelector(
    [selectCalendar],
    (state: ICalendarState) => state.calendarSettings
);

export const calendarDetailsSelector = createSelector(
    [selectCalendar],
    (state: ICalendarState) => state.calendarDetails
);

export const isCalendarDataPresentSelector = createSelector(
    [selectCalendar],
    (state: ICalendarState) => !(isNullOrUndefined(state.calendarDetails) || isNullOrUndefined(state.calendarSettings))
);
