import React from 'react';
import {connect} from 'react-redux';
import styles from "./styles.module.scss";
import {addAlert, authTokenSelector, clinicIdSelector, refreshTokenSelector, Translation} from 'common-web';
import {RootState} from '../../../store/reducers';

interface IInsurancePurchaseHostConnectedProps {
    readonly authToken: string | null;
    readonly refreshToken: string | null;
    readonly addAlert: typeof addAlert;
    readonly clinicId: string;
}

interface IInsurancePurchaseHostConnectedState {
    inquiryUrl: string | null;
    iNumber: number;
}

class InsurancePurchaseHost extends React.Component<IInsurancePurchaseHostConnectedProps, IInsurancePurchaseHostConnectedState> {
    constructor(props: any) {
        super(props);
        this.state = {
            inquiryUrl: null,
            iNumber: 0,
        };
    }

    componentDidMount() {
        this.setState({inquiryUrl: null});
        window.addEventListener('message', this.handleIframeTask);
    }

    handleIframeTask = (e: MessageEvent) => {
        if (e.origin !== process.env.REACT_APP_AXA_INSURANCE_URL || e.data === this.state.inquiryUrl) {
            return;
        } else {
            this.setState({inquiryUrl: e.data !== null ? e.origin + e.data : null});
        }
    };

    componentWillUnmount() {
        window.removeEventListener('message', this.handleIframeTask);
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title"><Translation text={'inquiries.purchaseInquiry.title'}/></div>
                            <div className="action-container">
                                <button className="btn btn-theme"
                                        type="button"
                                        onClick={e => this.refreshIframe(e)}>
                                    <Translation text={'inquiries.createInquiry.newButton'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row flex-grow-1">
                    <div className="col-xl-12 d-flex">
                        <div className={styles.iframeContainer}>
                            <iframe title="Axa insurance form for clinic"
                                    className={styles.iframe}
                                    key={this.state.iNumber}
                                    src={`${process.env.REACT_APP_AXA_INSURANCE_URL}/c/inquiry/treatment/?cid=${this.props.clinicId}&purchaseInquiry=true&token=${this.props.authToken}&refreshToken=${this.props.refreshToken}`}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private refreshIframe(e: any) {
        e.preventDefault();

        this.setState({
            iNumber: this.state.iNumber + 1
        })
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        refreshToken: refreshTokenSelector(state),
        clinicId: clinicIdSelector(state)
    }),
    {
        addAlert,
    }
)(InsurancePurchaseHost);
