import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterCalendarAPI} from "./provider/clinicHunterCalendar";

export function sendAfterCareToPatientAPI(
    authToken: string | undefined,
    treatmentPlanId: string,
    email: string,
    returnUrl: string
): Observable<any> {
    const payload = {
        email: email,
        returnUrl: returnUrl,
        treatmentPlanId: treatmentPlanId
    };

    return clinicHunterCalendarAPI.post(
        `treatment_plans/send_confirmation`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
