import {
    clinicConsultationDuration,
    clinicOpeningHours,
    currencyList,
    FormButtonType,
    FormControlType,
    IFormConfig,
    InputType,
    MultiSelectType,
    ValidationRules
} from 'common-web';
import {calendarSettingsSelector} from "../../../../store/selectors/calendarSelectors";
import store from "../../../../store";

export const createCalendarModalConfig: typeof IFormConfig = {
    controlType: 'form',
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'name',
            class: 'form-row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'calendar.calendarDetails.form.name.placeholder',
                    label: 'calendar.calendarDetails.form.name.label',
                    type: InputType.TEXT,
                    hostClass: 'col-100',
                }
            }
        },
        {
            controlType: 'group',
            key: 'clinic_timezone',
            class: 'form-row',
            controls: {
                timezone: {
                    controlType: 'control',
                    defaultValue: '',
                    placeholder: 'calendar.calendarDetails.form.timezone.placeholder',
                    formControlType: FormControlType.TIMEZONE,
                    label: 'calendar.calendarDetails.form.timezone.label',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    // tooltipText: 'calendar.calendarDetails.form.timezone.tooltipText',
                    hostClass: 'col-50',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }
                        return data;
                    },
                    outputDataMapper: (data: any) => {
                        if(!data) {
                            return;
                        }
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value
                        }
                        return data;
                    },
                },
            }
        },
        {
            controlType: 'group',
            key: 'amount',
            class: 'form-row',
            controls: {
                // amount: {
                //     controlType: 'control',
                //     defaultValue: '',
                //     formControlType: FormControlType.CURRENCY,
                //     validationRules: [
                //         {name: ValidationRules.IS_REQUIRED},
                //     ],
                //     placeholder: `calendar.calendarDetails.form.price.placeholder.${CurrencyType.EURO}`,
                //     label: 'calendar.calendarDetails.form.price.label',
                //     hostClass: 'col-100',
                //     currency: CurrencyType.EURO,
                //     type: InputType.NUMBER,
                // }
                amount: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'calendar.calendarDetails.form.price.placeholder',
                    label: 'calendar.calendarDetails.form.price.label',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_MIN, params: {min: 5}},
                    ],
                    type: InputType.NUMBER,
                    hostClass: 'col-75 pr-0',
                    isCurrencyInput: true,
                    minDate: 1
                },
                currency: {
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'calendar.calendarDetails.form.currency.placeholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    isCurrencySelect: true,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: () => {
                        return currencyList.map((currency: any) => ({
                            value: currency.value,
                            label: currency.label
                        }))
                    },
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return currencyList
                            .filter((currency: any) => currency.value === data)
                            .map((currency: any) => ({
                                value: currency.value,
                                label: currency.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                    hostClass: 'col-25 pl-0',
                }
            }
        },
        {
            controlType: 'group',
            key: 'clinic_opening_hours',
            class: 'form-row consultation-hours',
            controls: {
                availableFrom: {
                    controlType: 'control',
                    defaultValue: 7,
                    placeholder: 'Select calendar start hour',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'Consultations start hour',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: () => {
                        const calendarSettings = calendarSettingsSelector(store.getState());
                        if (calendarSettings && calendarSettings.availableUntil) {
                            const availableUntil = calendarSettings.availableUntil;

                            return clinicOpeningHours.map((openingHour: any) =>
                                ({
                                    value: openingHour.value,
                                    label: openingHour.label,
                                    isDisabled: openingHour.value >= availableUntil,
                                }))
                        }

                        return clinicOpeningHours;
                    },
                    hostClass: 'col-xl-4 consultation-options-input-group',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return clinicOpeningHours
                            .filter((openingHour: any) => openingHour.value === data)
                            .map((timezone: any) => ({
                                value: timezone.value,
                                label: timezone.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                },
                availableUntil: {
                    controlType: 'control',
                    defaultValue: 19,
                    placeholder: 'Select calendar end hour',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'Consultations last hour',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: () => {
                        const calendarSettings = calendarSettingsSelector(store.getState());
                        if (calendarSettings && calendarSettings.availableFrom) {
                            const availableFrom = calendarSettings.availableFrom;

                            return clinicOpeningHours.map((openingHour: any) => ({
                                value: openingHour.value,
                                label: openingHour.label,
                                isDisabled: openingHour.value <= availableFrom,
                            }))
                        }
                        return clinicOpeningHours;
                    },
                    hostClass: 'col-xl-4 consultation-options-input-group',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return clinicOpeningHours
                            .filter((openingHour: any) => openingHour.value === data)
                            .map((openingHour: any) => ({
                                value: openingHour.value,
                                label: openingHour.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    }
                },
                slotLength: {
                    controlType: 'control',
                    defaultValue: 0.5,
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'consultation duration',
                    label: 'Consultation Duration',
                    multiselectOptions: clinicConsultationDuration,
                    hostClass: 'col-xl-4 consultation-options-input-group',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return clinicConsultationDuration
                            .filter((duration: any) => duration.value === data)
                            .map((duration: any) => ({
                                value: duration.value,
                                label: duration.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    }
                }
            }
        },
        {
            controlType: 'group',
            key: 'mail_group',
            class: 'form-row',
            controls: {
                clinicEmail: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_EMAIL},
                    ],
                    placeholder: 'calendar.calendarDetails.form.clinicEmail.placeholder',
                    label: 'calendar.calendarDetails.form.clinicEmail.label',
                    type: InputType.EMAIL,
                    hostClass: 'col-100',
                },
                specialistEmail: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                    ],
                    placeholder: 'calendar.calendarDetails.form.specialistEmail.placeholder',
                    label: 'calendar.calendarDetails.form.specialistEmail.label',
                    type: InputType.EMAIL,
                    hostClass: 'col-100',
                },
            }
        },
        {
            controlType: 'group',
            key: 'create_calendar',
            class: 'form-row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-100 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'calendar.modals.confirmCreateModal.title',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => {
                        return !isFormValid;
                    }
                },
            },
        },
    ]
};
