import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicHunterCalendarAPI} from "./provider/clinicHunterCalendar";

export function deleteCalendarAPI(
    calendarId: string,
    accessToken: string
): Observable<any> {
    return clinicHunterCalendarAPI.delete(
        `calendars/${calendarId}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
