import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterCalendarAPI} from "./provider/clinicHunterCalendar";

export interface IUpdateRecommendationDefinitionPayload {
    type: string;
    name: string;
    description: string | null;
    startNotificationPropagation: string;
    cyclic: boolean | null;
    cycleInterval: string | null;
    treatmentPointEndsAtInterval: string | null;
    notificationType: string;
}

export function updateRecommendationDefinitionAPI(
    authToken: string | undefined,
    recommendationId: string,
    recommendationDetails: IUpdateRecommendationDefinitionPayload
): Observable<any> {
    const payload: IUpdateRecommendationDefinitionPayload = {
        type: recommendationDetails.type,
        name: recommendationDetails.name,
        description: recommendationDetails.description,
        startNotificationPropagation: recommendationDetails.startNotificationPropagation,
        cyclic: recommendationDetails.cyclic,
        cycleInterval: recommendationDetails.cycleInterval,
        treatmentPointEndsAtInterval: recommendationDetails.treatmentPointEndsAtInterval,
        notificationType: recommendationDetails.notificationType
    };

    return clinicHunterCalendarAPI.put(
        `treatment_point_definitions/${recommendationId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
