import React from "react";
import { RootState } from "../../../store/reducers";
import { connect } from "react-redux";
import {
  passwordLoadingSelector,
  changePasswordStart,
  CustomCard,
  Form,
  Translation
} from 'common-web';
import {changePasswordFormConfig} from "./changePasswordConfig";

interface IChangePasswordConnectedProps {
  readonly isLoading: boolean;
  readonly changePasswordStart: typeof changePasswordStart;
}

interface IChangePasswordExternalProps {}

interface IChangePasswordProps extends
    IChangePasswordConnectedProps,
    IChangePasswordExternalProps {}

interface IChangePasswordState {
  formConfig: any;
}

class ChangePassword extends React.Component<IChangePasswordProps, IChangePasswordState> {
  constructor(props: IChangePasswordProps) {
    super(props);

      this.state = {
        formConfig: changePasswordFormConfig,
      };
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.props.isLoading}>
        <CustomCard.Header>
          <Translation text={'setting.changePassword.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
            <Form config={this.state.formConfig}
                  submitForm={this.changePassword}
                  controlName={'changePasswordForm'}/>

        </CustomCard.Body>
      </CustomCard>
    );
  }

  private changePassword = (event: any, value: any, valid: boolean, touched: boolean): void => {
    if (!valid || !touched) {
      return;
    }

    this.props.changePasswordStart(value.oldPassword, value.password);
  };
}

export default connect(
  (state: RootState) => ({
    isLoading: passwordLoadingSelector(state)
  }),
  {
    changePasswordStart
  }
)(ChangePassword);
