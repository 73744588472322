import React from "react";
import { connect } from "react-redux";
import {BasicModal, CustomCard, CustomCardType, Translation} from "common-web";
import {paymentAccountsSelector} from "../../../store/selectors/accountSelectors";
import {RootState} from "../../../store/reducers";
import {PaymentAccount} from "../../../store/reducers/accountSlice";
import styles from "./styles.module.scss";
import AddPaymentMethodModal from "../AddPaymentMethodModal";
import {PaymentServiceName} from "../../../api/buySubscriptionDefiniton";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {detachPaymentMethodAPI} from "../../../api/detachPaymentMethod";
import {catchError, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";


export enum PaymentCardStatus {
    'CARD_ATTACHED' = 'attached',
    'CARD_NOT_ATTACHED' = 'unattached',
    'CARD_VALID' = 'valid',
    'CARD_INVALID' = 'invalid'
}

interface IPaymentMethodsConnectedProps {
    readonly paymentAccounts: PaymentAccount[];
}

interface IPaymentMethodsExternalProps {
    subscriptions: {[key: string]: any}[] | null;
    authToken: string | null;
}

interface IPaymentMethodsProps extends
    IPaymentMethodsConnectedProps,
    IPaymentMethodsExternalProps,
    RouteComponentProps {}

interface IPaymentMethodsState {
    isLoading: boolean;
    isAddCardModalShown: boolean;
    paymentAccount: PaymentAccount | null;
    isCardAttached: boolean;
    isCancelSubscriptionModalShown: boolean;
}

class PaymentMethods extends React.Component<IPaymentMethodsProps, IPaymentMethodsState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private subscription: Subscription | null = null;

    constructor(props: IPaymentMethodsProps) {
        super(props);

        this.state = {
            isLoading: false,
            isAddCardModalShown: false,
            paymentAccount: null,
            isCardAttached: false,
            isCancelSubscriptionModalShown: false
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        const stripePaymentAccount = this.props.paymentAccounts
            .find(account => (account.paymentAccountVendorType === PaymentServiceName.STRIPE));
        if (stripePaymentAccount !== null &&
            stripePaymentAccount !== undefined
        ) {
            this.setState({
                paymentAccount: stripePaymentAccount,
                isCardAttached: stripePaymentAccount.paymentAccountVendorData.customerPaymentMethodId !== null
            })
        }

        if (this.props.history && this.props.history.location &&
            this.props.history.location.state && (this.props.history.location.state as any).isCardModalOpen) {
            this.setState({isAddCardModalShown: (this.props.history.location.state as any).isCardModalOpen});
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IPaymentMethodsProps>,
        prevState: Readonly<IPaymentMethodsState>,
        snapshot?: any
    ): void {
        const currentStripePaymentAccount = this.props.paymentAccounts
            .find(account => (account.paymentAccountVendorType === PaymentServiceName.STRIPE));

        const prevStripePaymentAccount = prevProps.paymentAccounts
            .find(account => (account.paymentAccountVendorType === PaymentServiceName.STRIPE));

        if (currentStripePaymentAccount !== null &&
            currentStripePaymentAccount !== undefined &&
            prevStripePaymentAccount &&
            currentStripePaymentAccount.paymentAccountVendorData.customerPaymentMethodId !==
            prevStripePaymentAccount.paymentAccountVendorData.customerPaymentMethodId
        ) {
            this.setState({
                paymentAccount: currentStripePaymentAccount,
            })
        }
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <React.Fragment>
                <CustomCard showLocalLoader={this.state.isLoading}>
                    <CustomCard.Header>
                        <Translation text={'billings.paymentMethods.title'}/>
                    </CustomCard.Header>
                    <CustomCard.Body>
                        {this.state.isCardAttached ?
                            this.renderCardDetails() :
                            this.renderAttachCard()
                        }
                    </CustomCard.Body>
                </CustomCard>

                {this.state.isAddCardModalShown ? (
                    <AddPaymentMethodModal
                    isModalShown={this.state.isAddCardModalShown}
                    toggleModal={this.toggleAddCardModal}
                    paymentAccount={this.state.paymentAccount}
                />) : null}

                {this.state.isCancelSubscriptionModalShown ? (this.renderCancelSubscriptionModal()) : null}
            </React.Fragment>
        );
    }

    private renderCardDetails = () => {
        const cardDetails = this.state.paymentAccount?.paymentAccountVendorData,
            cardNumber = cardDetails && cardDetails.customerPaymentMethodLastFour ?
                `**** **** **** ${cardDetails?.customerPaymentMethodLastFour}` : '---',
            cardExpirationDate = `${cardDetails?.customerPaymentMethodExpMonth}/${cardDetails?.customerPaymentMethodExpYear}`;

        return(
            <React.Fragment>
                <div className={styles.cardDetails}>
                    <div className={`status-wrapper ${this.getCardStatus()} mb-4`}>
                        <Translation text={`billings.paymentMethods.cardStatuses.${this.getCardStatus()}`}/>
                    </div>

                    <p className={styles.label}>
                        <Translation text={'billings.paymentMethods.cardDetails.holderName'}/>
                        <span className={styles.value}>{cardDetails?.customerPaymentMethodName}</span>
                    </p>

                    <p className={styles.label}>
                        <Translation text={'billings.paymentMethods.cardDetails.cardNumber'}/>
                        <span className={styles.value}>{cardNumber}</span>
                    </p>

                    <p className={styles.label}>
                        <Translation text={'billings.paymentMethods.cardDetails.cardExpiration'}/>
                        <span className={styles.value}>{cardExpirationDate}</span>
                    </p>
                </div>
                <div className={styles.actionContainer}>
                    <button className="btn btn-primary mr-5"
                            onClick={() => this.toggleAddCardModal()}>
                        <Translation text={'billings.paymentMethods.updateCardDetails'}/>
                    </button>

                    <button className="btn btn-primary"
                            onClick={() => this.toggleCancelSubscriptionModal()}>
                        <Translation text={'billings.paymentMethods.cancelSubscription'}/>
                    </button>
                </div>
            </React.Fragment>
        )
    };

    private renderAttachCard = () => {
        return(
            <div>
                <p><Translation text={'billings.paymentMethods.noCardAttached'}/></p>

                <button className="btn btn-primary mt-5"
                        onClick={() => this.toggleAddCardModal()}>
                    <Translation text={'billings.paymentMethods.addPaymentMethod.add'}/>
                </button>

            </div>
        )
    };

    private renderCancelSubscriptionModal = () => {
        return (
            <BasicModal isModalShown={this.state.isCancelSubscriptionModalShown} closeModal={this.toggleCancelSubscriptionModal}>
                <CustomCard showLocalLoader={false} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <Translation text={'billings.paymentMethods.cancelSubscriptionModal.title'}/>
                            <button className="btn-modal-close" onClick={() => this.toggleCancelSubscriptionModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        <div className={styles.modalBody}>
                            <p className="mb-4">
                                <Translation text={'billings.paymentMethods.cancelSubscriptionModal.description'}
                                />
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-theme"
                                    onClick={() => this.cancelSubscription()}>
                                <Translation text={'billings.paymentMethods.cancelSubscriptionModal.cancelSubscription'}/>
                            </button>
                        </div>
                    </CustomCard.Body>
                </CustomCard>
            </BasicModal>
        )
    };

    private getCardStatus = (): PaymentCardStatus => {
        let cardStatus: PaymentCardStatus = PaymentCardStatus.CARD_ATTACHED;

        if (this.state.paymentAccount &&
            this.state.paymentAccount.paymentAccountVendorData.customerPaymentMethodId === null) {
            cardStatus = PaymentCardStatus.CARD_NOT_ATTACHED;
        } else {
            if (this.props.subscriptions &&
                this.props.subscriptions[0] &&
                this.props.subscriptions[0].payments[0]) {
                if (this.props.subscriptions[0].payments[0].status === 'status_paid') {
                    cardStatus = PaymentCardStatus.CARD_VALID;
                } else if (this.props.subscriptions[0].payments[0].status === 'status_requires_payment_method') {
                    cardStatus = PaymentCardStatus.CARD_INVALID;
                } else {
                    cardStatus = PaymentCardStatus.CARD_INVALID;
                }
            }
        }
        return cardStatus;
    };

    private toggleAddCardModal = () => {
      this.setState({isAddCardModalShown: !this.state.isAddCardModalShown});
    };

    private cancelSubscription = () => {
        if (this.props.authToken && this.state.paymentAccount &&
            this.state.paymentAccount.paymentAccountVendorData &&
            this.state.paymentAccount.paymentAccountVendorData.customerPaymentMethodId) {
            return this.subscription = detachPaymentMethodAPI(
                this.props.authToken,
                this.state.paymentAccount.id,
                PaymentServiceName.STRIPE,
                this.state.paymentAccount.paymentAccountVendorData.customerPaymentMethodId
            ).pipe(
                tap(() => {
                    this.setState({isCancelSubscriptionModalShown: false});
                    return this.alertManager.addAlert("Subscription plan was successfully cancelled")
                }),
                catchError((error: any) => {
                    this.setState({isLoading: false});
                    return of(this.alertManager.handleApiError(error));
                })
            ).subscribe()
        }
    };

    private toggleCancelSubscriptionModal = () => {
        return this.setState({
            isCancelSubscriptionModalShown: !this.state.isCancelSubscriptionModalShown,
        });
    }
}

export default connect(
    (state: RootState) => ({
        paymentAccounts: paymentAccountsSelector(state)
    }),
    {}
)(withRouter(PaymentMethods));
