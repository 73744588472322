import React from 'react';
import CalendarDay from "./CalendarDay";
import styles from "./styles.module.scss";
import {
    calendarWeekDay,
    ICalendarGeneratorHourItem,
    ICalendarRule
} from "../../../../../../store/reducers/calendarSlice";
import {fixInjectedProperties, lazyInject} from "../../../../../../ioc";
import CalendarCreationService from "../../../../../../service/calendarCreationService";
import {getTimezoneOffset} from "../../../../../../utils/dateTransformUtils";

interface ICalendarWeekState {
    weekDays: calendarWeekDay[];
    calendarRulesWeek: any[];
}

interface ICalendarWeekProps {
    timeSlotsTable: ICalendarGeneratorHourItem[];
    calendarRules: ICalendarRule[];
    timezone?: string | null;
}

class CalendarWeek extends React.Component<ICalendarWeekProps, ICalendarWeekState> {
    @lazyInject('CalendarCreationService') private calendarCreationService: CalendarCreationService;

    constructor(props: any) {
        super(props);
        this.state = {
            weekDays: [],
            calendarRulesWeek: []
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.timeSlotsTable && this.props.timezone) {
            const timezoneOffset = getTimezoneOffset(this.props.timezone);
            const weekDaysArray = this.calendarCreationService.generateWeekDaysArray(this.props.timeSlotsTable, timezoneOffset);
            this.setState({weekDays: weekDaysArray});
        }
        if (this.props.calendarRules) {
            const calendarRulesWeek = this.calendarCreationService.generateWeekFromCalendarRules(this.props.calendarRules);
            this.setState({calendarRulesWeek: calendarRulesWeek})
        }
    }

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {
        if (this.props.timeSlotsTable !== prevProps.timeSlotsTable && this.props.timeSlotsTable) {
            if (this.props.timezone) {
                const timezoneOffset = getTimezoneOffset(this.props.timezone);
                const weekDaysArray = this.calendarCreationService.generateWeekDaysArray(this.props.timeSlotsTable, timezoneOffset);
                this.setState({weekDays: weekDaysArray});
            }
        }
        if (this.props.calendarRules !== prevProps.calendarRules) {
            const calendarRulesWeek = this.calendarCreationService.generateWeekFromCalendarRules(this.props.calendarRules);
            this.setState({calendarRulesWeek: calendarRulesWeek})
        }
    }

    render() {
        return (
            <div className={styles.calendarWrapper}>
                {this.renderWeekDays()}
            </div>
        )
    }

    private renderWeekDays = () => {
        if (this.state.weekDays.length < 1) {
            return;
        }

        return this.state.weekDays.map((weekDay: calendarWeekDay) => {
            let currentDayRules = [];
            if (this.state.calendarRulesWeek) {
                const weekDayName: any = weekDay.dayName;
                currentDayRules = this.state.calendarRulesWeek[weekDayName]
            }

            return <CalendarDay key={weekDay.dayIndex} weekDay={weekDay}
                                calendarRulesForDay={currentDayRules}/>
        })
    };
}

export default CalendarWeek;

