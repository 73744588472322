import { Observable } from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterClinicAPI} from "./provider/clinicHunterClinic";

export function getAccountDataAPI(
    accessToken: string
): Observable<any> {
    return clinicHunterClinicAPI.get(
        `me`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        },
    );
}
