import React from "react";
import {changeLogo} from '../../../store/reducers/accountSlice';
import { connect } from "react-redux";
import { RootState } from "../../../store/reducers";
import { logoSelector } from '../../../store/selectors/accountSelectors';
import {accountLoadingSelector, CustomCard, Translation} from "common-web";
import { Uploader } from 'rsuite';
import logo from '../../../assets/images/logo.png';

const fileList = [
  {
    name: 'clinic.png',
    fileKey: 1,
    url: logo
  },
  {
    name: 'clinic.png',
    fileKey: 2,
    url: logo
  }
];

interface IClinicPhotosExternalProps {}

interface IClinicPhotosPropsConnectedProps {
  readonly logo: string | null;
  readonly isLoading: boolean;
  readonly changeLogo: typeof changeLogo;
}

interface IClinicPhotosProps extends
  IClinicPhotosExternalProps,
  IClinicPhotosPropsConnectedProps {}

interface IClinicPhotosState {
  logo: string | null;
  isLoading: boolean;
}

class ClinicPhotos extends React.Component<IClinicPhotosProps, IClinicPhotosState> {
  constructor(props: IClinicPhotosProps) {
    super(props);

    this.state = {
      logo: this.props.logo,
      isLoading: false
    };
  }

  componentDidUpdate(
    prevProps: Readonly<IClinicPhotosProps>,
    prevState: Readonly<IClinicPhotosState>,
    snapshot?: any
  ): void {
    if(this.props.isLoading !== prevProps.isLoading
      && !this.props.isLoading) {
      this.setState({isLoading: false})
    }
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.state.isLoading}>
        <CustomCard.Header>
          <Translation text={'profile.clinicPhotos.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
          <form>
            <Uploader action="//jsonplaceholder.typicode.com/posts/"
                      draggable
                      multiple
                      disabled
                      disabledFileItem
                      listType="picture"
                      defaultFileList={fileList}>
                <div>Click or Drag files to this area to upload</div>
            </Uploader>
          </form>
        </CustomCard.Body>
      </CustomCard>
    );
  }
}

export default connect(
  (state: RootState) => ({
    logo: logoSelector(state),
    isLoading: accountLoadingSelector(state)
  }),
  {
    changeLogo,
  }
)(ClinicPhotos);
