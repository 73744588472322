import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {list as patientsList} from '../../../../actions/patient/list';
import {list as calendarsList} from '../../../../actions/calendar/list';
import {RootState} from "../../../../store/reducers";
import {
    authTokenSelector,
    IMultiselectOption,
    Form,
    IFormConfig,
    FormControlChangeType,
    BasicModal,
    CustomCard,
    CustomCardType,
    clinicIdSelector
} from "common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, map, tap} from "rxjs/operators";
import {retrievedPatientsListSelector} from "../../../../store/selectors/patientListSelectors";
import {retrievedCalendarListSelector} from "../../../../store/selectors/calendarListSelectors";
import {invitePatientFormConfig, submitButton} from "./formConfig";
import {inviteNewPatientControls} from "./inviteNewPatientControls";
import {sendCalendarInvitationAPI} from "../../../../api/sendCalendarInvitation";

interface IConnectedConsultationInvitationFormProps {
    readonly authToken: string;
    readonly patientsList: any;
    readonly calendarsList: any;
    readonly patients: any;
    readonly calendars: any;
    readonly clinicId: string | null;
}

interface IConsultationInvitationFormProps extends IConnectedConsultationInvitationFormProps,
    RouteComponentProps,
    WithTranslation {
        invitationModalShown: boolean;
        closeInvitationModal: any;
        toggleInvitationModal: any;
}

interface IConsultationInvitationFormState {
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    isLoading: boolean;
    inviteNewPatient: boolean;
}

class ConsultationInvitationForm extends React.Component<IConsultationInvitationFormProps, IConsultationInvitationFormState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IConsultationInvitationFormProps) {
        super(props);

        this.state = {
            formConfig: invitePatientFormConfig,
            isFormValid: true,
            isLoading: false,
            inviteNewPatient: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getPatientsList();
        this.getCalendarsList();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );

        const controls = Array.from(this.state.formConfig.controls);
        const cloneFormConfig = Object.assign({}, invitePatientFormConfig);
        let containsButton = cloneFormConfig.controls.some((control: any) => {
            return control.key === 'consultation_invitation_submit';
        });

        if (!containsButton) {
            cloneFormConfig['controls'] = [...controls, submitButton];
        }
        this.setState({formConfig: cloneFormConfig});
        setTimeout(() => {
            invitePatientFormConfig['controls'] = cloneFormConfig['controls'];
        }, 0);
    }

    componentDidUpdate(
        prevProps: Readonly<IConsultationInvitationFormProps>,
        prevState: Readonly<IConsultationInvitationFormState>,
        snapshot?: any
    ): void {
        if (this.props.patients !== prevProps.patients) {
            this.setPatientMultiselectOptions();
        }

        if (this.props.calendars !== prevProps.calendars) {
            this.setCalendarMultiselectOptions();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        const controls = Array.from(this.state.formConfig.controls);
        const cloneFormConfig = Object.assign({}, invitePatientFormConfig);
        let updatedControls = controls.filter((item: any) => item.key !== 'name_details' && item.key !== 'email');
        cloneFormConfig['controls'] = [...updatedControls];

        this.setState({
            formConfig: cloneFormConfig
        });
        setTimeout(() => {
            invitePatientFormConfig['controls'] = cloneFormConfig['controls'];
        }, 0);
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.invitationModalShown}
                            type={CustomCardType.MODAL_CARD}
                            closeModal={() => {
                                this.props.closeInvitationModal();
                            }}
                >
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                Send Consultation Calendar to Patient
                                <button className="btn-modal-close" onClick={() => this.props.toggleInvitationModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                            <Form config={this.state.formConfig}
                                  onValueStateChange={this.onValueStateChange}
                                  onValidationStateChange={this.onValidationStateChange}
                                  submitForm={this.sendPatientInvitation}
                                  // value={this.state.value}
                                  controlName={'invitePatientForm'}/>

                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        Object.keys(value).map((key: string) => {
            if (key === 'patient' &&
                value[key] === 'add_new_patient' &&
                !this.state.inviteNewPatient) {
                    this.inviteNewPatientFields();
                    return;
            }

            if (key === 'patient' && value[key] !== 'add_new_patient' && this.state.inviteNewPatient) {
                this.removeInviteNewPatientFields();
            }
        });
    };

    private sendPatientInvitation = (event: any, value: any, valid: boolean, touched: boolean) => {
        if (!touched || !valid || !this.props.authToken) {
            return;
        }
        this.setState({isLoading: true});
        this.subscriptions.push(this.handleConsultationInvitationAPI(value).subscribe());
    };

    private handleConsultationInvitationAPI(value: any) {
        if (!this.props.clinicId) {
            return of();
        }

        const redirectLink =
            `${process.env.REACT_APP_CONSULTATION_WEB_URL}?cal=${value.calendar}&cid=${this.props.clinicId}`;
        let payload: {[key: string]: any} = {
            calendarId: value.calendar,
        };

        if (!this.state.inviteNewPatient) {
            payload['accountId'] = value.patient
        }

        if (this.state.inviteNewPatient) {
            payload['firstName'] = value.firstName;
            payload['lastName'] = value.lastName;
            payload['email'] = value.email;
        }

        return sendCalendarInvitationAPI(
            this.props.authToken,
            redirectLink,
            value.calendar,
            payload
        ).pipe(
            map(() => {
                this.setState({isLoading: false});
                this.props.closeInvitationModal();
                this.alertManager.addAlert('Consultation invitation was sent to a patient.');
            }),
            catchError((error: any) => {
                    this.setState({isLoading: false});
                    return of(this.alertManager.handleApiError(error));
                }
            )
        )
    }

    private getPatientsList = () => {
        return this.props.patientsList('patients', this.props.authToken);
    };

    private setPatientMultiselectOptions = () => {
        let patients: typeof IMultiselectOption[] = [];
        patients.push({
            label: 'Add new patient',
            value: 'add_new_patient'
        });

        if (this.props.patients && this.props.patients['hydra:member'].length) {
            this.props.patients['hydra:member'].forEach((patient: any) => {
                if (patient.account.user !== null) {
                    return patients.push({
                        label: `${patient.account.firstName} ${patient.account.lastName}`,
                        value: patient.account.id
                    })
                }
            });
        }

        invitePatientFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'patient') {
                        control.controls[key].multiselectOptions = patients;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: invitePatientFormConfig});
    };

    private getCalendarsList = () => {
        this.props.calendarsList('calendars', this.props.authToken,);
    };

    private setCalendarMultiselectOptions() {
        if (!this.props.calendars || !this.props.calendars['hydra:member'].length) {
            return;
        }

        let calendars: typeof IMultiselectOption[] = [];
        this.props.calendars['hydra:member'].forEach((calendar: any) => {
            return calendars.push({
                label: calendar.name,
                value: calendar.id
            })
        });

        invitePatientFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'calendar') {
                        control.controls[key].multiselectOptions = calendars;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: invitePatientFormConfig});
    }

    private inviteNewPatientFields(): void {
        const controls = Array.from(this.state.formConfig.controls);
        controls.pop();
        const cloneFormConfig = Object.assign({}, invitePatientFormConfig);
        cloneFormConfig['controls'] = [...controls, ...inviteNewPatientControls, submitButton];

        this.setState({
            formConfig: cloneFormConfig,
            inviteNewPatient: true,
        });

        setTimeout(() => {
            invitePatientFormConfig['controls'] = cloneFormConfig['controls'];
        }, 0);
    }

    private removeInviteNewPatientFields(): void {
        const controls = Array.from(this.state.formConfig.controls);
        const updatedControls = controls.slice(0, controls.length - 3);
        const cloneFormConfig = Object.assign({}, invitePatientFormConfig);
        cloneFormConfig['controls'] = [...updatedControls, submitButton];

        this.setState({
            formConfig: cloneFormConfig,
            inviteNewPatient: false,
        });
        setTimeout(() => {
            invitePatientFormConfig['controls'] = cloneFormConfig['controls'];
        }, 0);
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        patients: retrievedPatientsListSelector(state),
        calendars: retrievedCalendarListSelector(state),
        clinicId: clinicIdSelector(state)
    }),
    {
        patientsList,
        calendarsList
    }
)(withRouter(ConsultationInvitationForm)));
