import {
    IFormConfig,
    FormControlType,
    fullCountryList,
    InputType,
    MultiSelectType,
    ValidationRules, FormButtonType
} from 'common-web';

export const addressDataFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'clinic_name',
            class: 'row',
            controls: {
                companyName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    placeholder: 'Clinic Name',
                    label: 'Clinic Name',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                }
            }
        },
        {
            controlType: 'group',
            key: 'owner_name',
            class: 'row',
            controls: {
                firstName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    placeholder: 'Owner Name',
                    label: 'Owner Name',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6',
                },
                lastName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    placeholder: 'Owner Surname',
                    label: 'Owner Surname',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6',
                }
            }
        },
        {
            controlType: 'group',
            key: 'contact',
            class: 'row',
            controls: {
                phone: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    placeholder: 'Phone',
                    label: 'Phone',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                },
            },
              // email: {
              //   defaultValue: '',
              //   formControlType: FormControlType.INPUT,
              //     validationRules: [
              //         { name: ValidationRules.IS_REQUIRED },
              //     ],
              //   placeholder: 'Email',
              //   label: "Email",
              //   type: InputType.EMAIL,
              //   hostClass: 'col-xl-6',
              // }
        },
        {
            controlType: 'group',
            key: 'address_1',
            class: 'row',
            controls: {
                addressLine1: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    placeholder: 'Street',
                    label: 'Street',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6',
                },
                addressLine2: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    placeholder: 'House',
                    label: 'House',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-3',
                },
                addressLine3: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Flat',
                    label: 'Flat',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-3',
                },
            },
        },
        {
            controlType: 'group',
            key: 'address_2',
            class: 'row',
            controls: {
                postcode: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    placeholder: 'Postcode',
                    label: 'Postcode',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-4',
                },
                city: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    placeholder: 'City',
                    label: 'City',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-4',
                },
                country: {
                    controlType: 'control',
                    defaultValue: '',
                    placeholder: 'Country',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'Country',
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: fullCountryList,
                    firstOptionValue: 'Select Country',
                    hostClass: 'col-xl-4',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return fullCountryList
                            .filter((country: any) => country.value === data)
                            .map((country: any) => ({
                                value: country.value,
                                label: country.label,
                            }));
                    },
                   outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                       return data;
                    },
                },
            }
        },
        {
            controlType: 'group',
            key: 'address_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2 pr-0',
                    defaultValue: null,
                    btnText: 'Save',
                    defaultStyles: 'btn btn-primary'
                },
            },
        },
    ]
};
