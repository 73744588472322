import {createSlice} from 'redux-starter-kit';
import {IInvoiceAddress} from '../../service/accountPayloadMapperService';

export interface Address {
  addressLine1: string | null;
  addressLine2: string | null;
  addressLine3: string | null;
  city: string | null;
  country: string | null;
  postcode: string | null;
}

export interface Unit {
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  birthDate: string | null;
  residency: string | null;
  timezone: string | null;
}

export interface PaymentAccountVendorData {
  accountBankTransferBankName: string | null;
  accountBankTransferCountry: string | null;
  accountBankTransferCurrency: string | null;
  accountBankTransferLastFour: string | null;
  accountEmail: string;
  chargesEnabled: boolean;
  customerId: string;
  customerPaymentMethodBrand: string | null;
  customerPaymentMethodExpMonth: number | null;
  customerPaymentMethodExpYear: number | null;
  customerPaymentMethodId: string | null;
  customerPaymentMethodLastFour: string | null;
  customerPaymentMethodName: string | null;
  paymentAccountId: string;
}

export interface PaymentAccount {
  id: string;
  paymentAccountVendorData: PaymentAccountVendorData;
  paymentAccountVendorType: string;
}

export interface SubscriptionDetails {
  currentSubscriptionEntry: any;
  id: string;
  proposedPlans: any[];
  subscriptionEntries: any[];
  upcomingSubscription: any
}

export interface MainAccountData {
  personalData: Unit;
  addressData: Address;
  invoiceData: IInvoiceAddress;
  paymentAccounts: PaymentAccount[];
  subscription: SubscriptionDetails;
  id: string;
}

export interface IAccountState {
  personalData: Unit | null;
  logo: string | null;
  clinicCategories: string[] | null;
  addressData: Address | null;
  invoiceData: IInvoiceAddress | null;
  paymentAccounts: PaymentAccount[] | [];
  subscription: SubscriptionDetails | null;
  id: string | null;
}

export interface IChangePersonalData {
  readonly payload: {
    personalData: Unit;
  };
}

export interface IChangeLogo {
  readonly payload: {
    logo: string;
  };
}

export interface IChangeClinicCategories {
  readonly payload: {
    clinicCategories: string [];
  };
}

export interface IChangeAddressData {
  readonly payload: {
    addressData: Address;
  };
}

export interface IChangePaymentAccountData {
  readonly payload: {
    paymentAccount: PaymentAccount;
  };
}

export interface ISetPaymentAccountData {
  readonly payload: {
    paymentAccounts: PaymentAccount[];
  };
}

export interface IChangeInvoiceData {
  readonly payload: {
    invoiceData: IInvoiceAddress;
  };
}

export interface ISetAccountData {
  readonly payload: {
    personalData: Unit,
    addressData: Address,
    invoiceData: IInvoiceAddress,
    paymentAccounts: PaymentAccount[],
    subscription: SubscriptionDetails,
    id: string
  };
}

export interface IUpdateAccountData {
  readonly payload: {
    personalData: Unit,
    addressData: Address,
    invoiceData: IInvoiceAddress,
    id: string
  }
}

export interface ISetAccountStateFailure {
  readonly payload: {
    error: string;
  }
}


const initialState: IAccountState = {
  personalData: null,
  logo: null,
  clinicCategories: null,
  addressData: null,
  invoiceData: null,
  paymentAccounts: [],
  subscription: null,
  id: null
};

const accountSlice = createSlice({
  slice: "account",
  initialState: initialState,
  reducers: {
    changePersonalData: {
      reducer: (state: IAccountState, action: IChangePersonalData) => {
        return {
          personalData: action.payload.personalData,
          logo: state.logo,
          clinicCategories: state.clinicCategories,
          addressData: state.addressData,
          invoiceData: state.invoiceData,
          paymentAccounts: state.paymentAccounts,
          subscription: state.subscription,
          id: state.id
        };
      },
      prepare(personalData: Unit) {
        return {
          payload: { personalData: personalData }
        };
      }
    },
    changeLogo: {
      reducer: (state: IAccountState, action: IChangeLogo) => {
        return {
          personalData: state.personalData,
          logo: action.payload.logo,
          clinicCategories: state.clinicCategories,
          addressData: state.addressData,
          invoiceData: state.invoiceData,
          paymentAccounts: state.paymentAccounts,
          subscription: state.subscription,
          id: state.id
        };
      },
      prepare(logo: string) {
        return {
          payload: { logo: logo }
        };
      }
    },
    changeClinicCategories: {
      reducer: (state: IAccountState, action: IChangeClinicCategories) => {
        return {
          personalData: state.personalData,
          logo: state.logo,
          clinicCategories: action.payload.clinicCategories,
          addressData: state.addressData,
          invoiceData: state.invoiceData,
          paymentAccounts: state.paymentAccounts,
          subscription: state.subscription,
          id: state.id
        };
      },
      prepare(clinicCategories: string[]) {
        return {
          payload: { clinicCategories: clinicCategories }
        };
      }
    },
    changeAddressData: {
      reducer: (state: IAccountState, action: IChangeAddressData) => {
        return {
          personalData: state.personalData,
          logo: state.logo,
          clinicCategories: state.clinicCategories,
          addressData: action.payload.addressData,
          invoiceData: state.invoiceData,
          paymentAccounts: state.paymentAccounts,
          subscription: state.subscription,
          id: state.id
        };
      },
      prepare(addressData: Address) {
        return {
          payload: { addressData: addressData }
        };
      }
    },
    changeInvoiceData: {
      reducer: (state: IAccountState, action: IChangeInvoiceData) => {
        return {
          personalData: state.personalData,
          logo: state.logo,
          clinicCategories: state.clinicCategories,
          addressData: state.addressData,
          invoiceData: action.payload.invoiceData,
          paymentAccounts: state.paymentAccounts,
          subscription: state.subscription,
          id: state.id
        };
      },
      prepare(invoiceData: IInvoiceAddress) {
        return {
          payload: { invoiceData: invoiceData }
        };
      }
    },
    setPaymentAccountData: {
      reducer: (state: IAccountState, action: ISetPaymentAccountData) => {
        return {
          personalData: state.personalData,
          logo: state.logo,
          clinicCategories: state.clinicCategories,
          addressData: state.addressData,
          invoiceData: state.invoiceData,
          paymentAccounts: action.payload.paymentAccounts,
          subscription: state.subscription,
          id: state.id
        };
      },
      prepare(paymentAccounts: PaymentAccount[]) {
        return {
          payload: { paymentAccounts: paymentAccounts }
        };
      }
    },
    changePaymentAccountData: {
      reducer: (state: IAccountState, action: IChangePaymentAccountData) => {
        const paymentAccounts = [...state.paymentAccounts],
            paymentAccount = action.payload.paymentAccount;
        const i = paymentAccounts.findIndex(o => o.id === paymentAccount.id);
        if (paymentAccounts[i]) { paymentAccounts[i] = paymentAccount }

        return {
          personalData: state.personalData,
          logo: state.logo,
          clinicCategories: state.clinicCategories,
          addressData: state.addressData,
          invoiceData: state.invoiceData,
          paymentAccounts: paymentAccounts,
          subscription: state.subscription,
          id: state.id
        };
      },
      prepare(paymentAccount: PaymentAccount) {
        return {
          payload: { paymentAccount: paymentAccount }
        };
      }
    },
    setAccountData: {
      reducer: (state: IAccountState, action: ISetAccountData) => {
        return {
          personalData: action.payload.personalData,
          logo: state.logo,
          clinicCategories: state.clinicCategories,
          addressData: action.payload.addressData,
          invoiceData: action.payload.invoiceData,
          paymentAccounts: action.payload.paymentAccounts,
          subscription: action.payload.subscription,
          id: action.payload.id
        };
      },
      prepare(accountData: MainAccountData) {
        return {
          payload: {
            personalData: accountData.personalData,
            addressData: accountData.addressData,
            invoiceData: accountData.invoiceData,
            paymentAccounts: accountData.paymentAccounts,
            subscription: accountData.subscription,
            id: accountData.id
          }
        };
      }
    },
    updateAccountData: {
      reducer: (state: IAccountState, action: IUpdateAccountData) => {
        return {
          personalData: action.payload.personalData,
          logo: state.logo,
          clinicCategories: state.clinicCategories,
          addressData: action.payload.addressData,
          invoiceData: action.payload.invoiceData,
          paymentAccounts: state.paymentAccounts,
          subscription: state.subscription,
          id: state.id
        };
      },
      prepare(accountData: MainAccountData) {
        return {
          payload: {
            personalData: accountData.personalData,
            addressData: accountData.addressData,
            invoiceData: accountData.invoiceData,
            id: accountData.id
          }
        };
      }
    },
    // setAccountStateFailure: {
    //   reducer: (state: IAccountState, action: ISetAccountStateFailure) => {
    //     return {
    //       account: state.account,
    //       isLoading: false,
    //       error: action.payload.error
    //     };
    //   },
    //   prepare(error: string) {
    //     return {
    //       payload: {
    //         error: error
    //       }
    //     };
    //   }
    // },
  }
});

export const {
  changePersonalData,
  changeLogo,
  changeClinicCategories,
  changeAddressData,
  changeInvoiceData,
  setAccountData,
  updateAccountData,
  setPaymentAccountData,
  changePaymentAccountData
} = accountSlice.actions;

export default accountSlice.reducer;
