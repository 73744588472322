import React from 'react';
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation,
    usernameSelector
} from "common-web";
import {connect} from "react-redux";
import {calendarIdSelector, calendarSettingsSelector} from "../../../../store/selectors/calendarSelectors";
import {
    changeCalendarId,
    changeCalendarSettings,
    ICalendarCreationPayload,
    ICalendarSettings
} from "../../../../store/reducers/calendarSlice";
import {RootState} from "../../../../store/reducers";
import {BehaviorSubject, Subscription, of} from "rxjs";
import {createCalendarModalConfig} from "./formConfig";
import {catchError, filter, tap} from "rxjs/operators";
import {getTimezoneOffset, numberToDateConverter} from "../../../../utils/dateTransformUtils";
import {createCalendarAPI} from "../../../../api/createCalendarAPI";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";

interface IConnectedCreateCalendarModalProps {
    readonly calendarSettings: ICalendarSettings | null;
    readonly username: string;
    readonly calendarId: string | null,
    readonly authToken: string;
    readonly changeCalendarSettings: typeof changeCalendarSettings;
    readonly changeCalendarId: typeof changeCalendarId;
}

export interface ICreateCalendarModalProps extends IConnectedCreateCalendarModalProps,
    RouteComponentProps {
    createModalShown: boolean;
    toggleCreateModal: () => void;
}

interface ICreateCalendarModalState {
    formConfig: typeof IFormConfig;
    createCalendarValues: any;
    isFormValid: boolean;
    isLoading: boolean;
}

class CreateCalendarModal extends React.Component<ICreateCalendarModalProps, ICreateCalendarModalState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);

        this.state = {
            isFormValid: false,
            formConfig: createCalendarModalConfig,
            createCalendarValues: null,
            isLoading: false
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );

        const configOptionsDefaultValues = this.state.formConfig.controls[3].controls;

        let defaultClinicMail = '';
        if(this.props.username) {
            defaultClinicMail = this.props.username
        }

        this.setState({
            createCalendarValues: {
                clinicEmail: defaultClinicMail,
                availableFrom: 7,
                availableUntil: 19,
                slotLength: 0.5
            }
        });

        this.props.changeCalendarSettings({
            slotLength: configOptionsDefaultValues.slotLength.defaultValue,
            availableFrom: configOptionsDefaultValues.availableFrom.defaultValue,
            availableUntil: configOptionsDefaultValues.availableUntil.defaultValue
        })
    }

    componentWillUnmount() {
        if (null !== this.subscriptions) {
            this.setState({formConfig: null});
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.createModalShown} closeModal={this.props.toggleCreateModal}>
                <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <h4><Translation text={'calendar.modals.confirmCreateModal.title'}/></h4>
                            <button className="btn-modal-close" onClick={() => this.props.toggleCreateModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Form config={this.state.formConfig}
                                  onValidationStateChange={this.onFormValidityChange}
                                  onValueStateChange={this.onValueStateChange}
                                  submitForm={this.addCalendar}
                                  value={this.state.createCalendarValues}
                                  controlName={'addCalendarForm'}/>
                        </div>
                    </CustomCard.Body>
                </CustomCard>
            </BasicModal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        const calendarSettings = this.props.calendarSettings;

        if (calendarSettings) {
            this.props.changeCalendarSettings({
                slotLength: value.slotLength ? value.slotLength : calendarSettings.slotLength,
                availableFrom: value.availableFrom ? value.availableFrom : calendarSettings.availableFrom,
                availableUntil: value.availableUntil ? value.availableUntil : calendarSettings.availableUntil
            })
        }

        this.setState({createCalendarValues: value});
    };

    private onFormValidityChange = (controlName: string, isValid: boolean, errorMessages: ReadonlyArray<string>) => {
        this.setState({isFormValid: isValid});
    };

    private addCalendar = (): void => {
        if ((null === this.state.createCalendarValues) || (undefined === this.state.createCalendarValues)) {
            return
        }

        const timezoneOffset = getTimezoneOffset(this.state.createCalendarValues.timezone);
        const availableFrom = numberToDateConverter(
            this.state.createCalendarValues.availableFrom,
            timezoneOffset
            ),
            availableUntil = numberToDateConverter(
                this.state.createCalendarValues.availableUntil,
                timezoneOffset
            ),
            slotLength = (this.state.createCalendarValues.slotLength * 60),
            calendarPrice = {
                amount: (this.state.createCalendarValues.amount * 100).toString(),
                currency: {code: this.state.createCalendarValues.currency}
            };
        this.setState({isFormValid: true});

        const payload: ICalendarCreationPayload = {
            name: this.state.createCalendarValues.name,
            public: true,
            price: calendarPrice,
            slotLength: slotLength,
            availableFrom: availableFrom,
            availableUntil: availableUntil,
            timezone: this.state.createCalendarValues.timezone,
            calendarRules: [],
            clinicEmail: this.state.createCalendarValues.clinicEmail,
            specialistEmail: this.state.createCalendarValues.specialistEmail
        };
        this.setState({isLoading: true});
        this.subscriptions.push(
            createCalendarAPI(this.props.authToken, payload).pipe(
                tap((data: any) => {
                    this.props.changeCalendarId(data.id);
                    this.alertManagerService.addAlert('calendar.modals.confirmCreateModal.success');
                    this.props.toggleCreateModal();
                    if (this.props.calendarId) {
                        const path = [''];
                        path.push(`dashboard/consultations/edit/${this.props.calendarId}`);
                        this.props.history.push(path.join('/'));
                    }
                }),
                catchError((error: any) => {
                    this.props.toggleCreateModal();
                    this.alertManagerService.handleApiError(error.response);
                    return of(error);
                })
            ).subscribe());
    };
}

export default connect(
    (state: RootState) => ({
        calendarSettings: calendarSettingsSelector(state),
        username: usernameSelector(state),
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeCalendarSettings,
        changeCalendarId
    }
)(withRouter(CreateCalendarModal));
