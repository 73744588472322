import React from "react";
import { connect } from "react-redux";
import {
    authTokenSelector,
    CustomPagination,
    DateConverter,
    Loader,
    RestQueryParams,
    transformToPriceString,
    Translation
} from "common-web";
import {RootState} from "../../../store/reducers";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {Subscription} from "rxjs";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {
    invoiceListErrorSelector,
    invoiceListEventSourceSelector,
    invoiceListLoadingSelector,
    retrievedInvoiceListSelector
} from "../../../store/selectors/invoicesSelectors";
import {list, reset} from "../../../actions/invoices/list";
import {saveAs} from "file-saver";

enum PaymentStatus {
    PAID = 'paid',
    UNPAID = 'unpaid'
}

interface IInvoicesConnectedProps {
    readonly authToken: string;
    readonly retrieved: {[key: string]: any};
    readonly isLoading: boolean;
    readonly error: string | null;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
}

interface IInvoicesExternalProps {}

interface IInvoicesProps extends
    IInvoicesConnectedProps,
    IInvoicesExternalProps {}

interface IInvoicesState {
    isLoading: boolean;
    clinicInvoices: any;
}

class Invoices extends React.Component<IInvoicesProps, IInvoicesState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IInvoicesProps) {
        super(props);

        this.state = {
            isLoading: true,
            clinicInvoices: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {}

    componentDidUpdate(
        prevProps: Readonly<IInvoicesProps>,
        prevState: Readonly<IInvoicesState>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div>
                {this.renderInvoiceList()}

                <CustomPagination retrieved={this.props.retrieved}
                                  basePath="dashboard"
                                  path="consultations"
                                  provider={this.getClinicInvoices}
                                  isPaginationLocal={true}/>

                <Loader type="local" showLoader={this.props.isLoading}/>
            </div>
        );
    }

    private renderInvoiceList = () => {
        if (!this.props.retrieved ||
            !this.props.retrieved['hydra:member'] ||
            !Array.isArray(this.props.retrieved['hydra:member'])) {
            return <p>There is no data available</p>;
        }

        return (
            <React.Fragment>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th><Translation text={'billings.invoices.table.id'}/></th>
                        <th><Translation text={'billings.invoices.table.date'}/></th>
                        <th><Translation text={'billings.invoices.table.price'}/></th>
                        <th><Translation text={'billings.invoices.table.status'}/></th>
                        <th className="text-center"/>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderTableRows()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    };

    private renderTableRows() {
        const list = this.props.retrieved['hydra:member'],
            rows: any[] = [];

        list.map((item: any, index: number) => {
            let status = item.paidAt ? PaymentStatus.PAID : PaymentStatus.UNPAID;

            return rows.push((
                <tr key={index}>
                    <td>{item.name}</td>
                    <td><DateConverter date={item.issuedAt}/></td>
                    <td>
                        {item.valueAmount && item.valueCurrency ?
                            transformToPriceString(item.valueCurrency, item.valueAmount) :
                            '---'
                        }
                    </td>
                    <td>
                        <div className={`status-wrapper ${status}`}>
                            <p className="status">
                                <Translation text={`billings.invoices.statuses.${status}`}/>
                            </p>
                        </div>
                    </td>
                    <td className="align-middle text-right">
                        <button className="btn btn-action"
                                onClick={() => this.downloadInvoice(item.id, item.name)}>
                            <span className="feather icon-download"/>
                        </button>
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private downloadInvoice = (invoiceId: string, invoiceName?: string) => {
        let url = `${process.env.REACT_APP_AUTH_API_URL}/invoices/${invoiceId}/stream`,
            fileName = invoiceName ? `${invoiceName}.pdf` : 'invoice.pdf';
        return fetch(url, {
            headers: {
                "Accept": "text/pdf",
                "Content-type": "application/pdf",
                "Authorization": `Bearer ${this.props.authToken}`
            },
        }).then(response => response.blob())
            .then(blob => saveAs(blob, fileName));
    };

    private getClinicInvoices = (searchParams: typeof RestQueryParams) => {
        this.props.list(
            `invoices${searchParams.prepareQuery()}`,
            this.props.authToken
        );
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        retrieved: retrievedInvoiceListSelector(state),
        isLoading: invoiceListLoadingSelector(state),
        error: invoiceListErrorSelector(state),
        eventSource: invoiceListEventSourceSelector(state),
    }),
    {
        list,
        reset,
    }
)(Invoices);
