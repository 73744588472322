import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
    BasicModal,
    CustomCard,
    CustomCardType,
    Translation
} from "common-web";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {of, Subscription} from "rxjs";
import {deleteAftercareRecommendationAPI} from "../../../../../api/deleteAftercareRecommendationAPI";
import {catchError, tap} from "rxjs/operators";
import {connect} from "react-redux";
import {retrieve} from "../../../../../actions/treatmentPlanner/show";
import {isNotNullOrUndefined} from "../../../../../utils/runtimeUtils";

interface IConnectedDeleteEventProps {
    readonly retrieve: typeof retrieve;
}

interface IDeleteEventProps extends IConnectedDeleteEventProps,
    RouteComponentProps,
    WithTranslation {
    authToken: string;
    deleteRecommendationModalShown: boolean;
    toggleDeleteModal: any;
    afterCareId: string | null;
    selectedRecommendation: {[key: string]: any} | null;
}

interface IDeleteEventState {
    isLoading: boolean;
}

class DeleteEvent extends React.Component<IDeleteEventProps, IDeleteEventState> {
    subscription: Subscription;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IDeleteEventProps) {
        super(props);

        this.state = {
            isLoading: false,
        };

        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        if (isNotNullOrUndefined(this.subscription)) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.deleteRecommendationModalShown} closeModal={this.props.toggleDeleteModal}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={'treatmentPlanner.predefinedEvents.deleteEvent.title'}/>
                                <button className="btn-modal-close" onClick={() => this.props.toggleDeleteModal(null)}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Translation text={'treatmentPlanner.predefinedEvents.deleteEvent.description'}/>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-danger-outline mr-4"
                                        onClick={() => this.props.toggleDeleteModal(null)}>
                                    <span className="feather icon-minus-circle"/>
                                    <Translation text={'treatmentPlanner.predefinedEvents.deleteEvent.buttons.no'}/>
                                </button>
                                <button className="btn btn-theme"
                                        onClick={() => this.deleteRecommendation()}>
                                    <span className="feather icon-check-circle"/>
                                    <Translation text={'treatmentPlanner.predefinedEvents.deleteEvent.buttons.yes'}/>
                                </button>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private deleteRecommendation = () => {
        if (!this.props.selectedRecommendation) {
            return;
        }

        this.setState({isLoading: true});
        this.subscription = this.handleDeleteAftercareRecommendationAPI(this.props.selectedRecommendation.id).subscribe();

    };

    private handleDeleteAftercareRecommendationAPI(aftercareRecommendationId: string) {
        return deleteAftercareRecommendationAPI(this.props.authToken, aftercareRecommendationId).pipe(
            tap((response: any) => {
                this.setState({isLoading: false});
                if (this.props.afterCareId) {
                    this.props.retrieve(`treatment_plans/${this.props.afterCareId}`, this.props.authToken);
                }
                this.alertManager.addAlert('The aftercare recommendation was deleted');
                this.props.toggleDeleteModal();
            }),
            catchError((error: any) => {
                    this.setState({isLoading: false});
                    return of(this.alertManager.handleApiError(error));
                }
            )
        );
    }
}

export default withTranslation()(connect(
    () => ({}),
    {
        retrieve
    }
)(withRouter(DeleteEvent)));
