import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Translation,} from "common-web";
import {WithTranslation, withTranslation} from "react-i18next";
import styles from './styles.module.scss';
// import {AlertType} from "../../../service/alertManagerService";


interface IConnectedConsultationAlertProps {}

interface IConsultationAlertProps extends IConnectedConsultationAlertProps,
    RouteComponentProps,
    WithTranslation {
    onlineConsultationModuleConfig: {[key: string]: any} | null;
    isSubscriptionPlanFree: boolean;
}

interface IConsultationAlertState {
    isTelemedicineAlertShown: boolean;
}

class ConsultationAlert extends React.Component<IConsultationAlertProps, IConsultationAlertState> {
    constructor(props: IConsultationAlertProps) {
        super(props);

        this.state = {
            isTelemedicineAlertShown: true
        };
    }

    componentDidMount() {}

    componentDidUpdate(
        prevProps: Readonly<IConsultationAlertProps>,
        prevState: Readonly<IConsultationAlertState>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {}

    render() {
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-xl-12">
                        {this.renderConsultationLimitAlert()}
                    </div>
                </div>

            </React.Fragment>
        );
    }


    private renderConsultationLimitAlert = () => {
        if (!this.props.onlineConsultationModuleConfig) {
            return;
        }

        // const minutesAvailable = this.props.onlineConsultationModuleConfig.minutesAvailable;
        // let alertType: AlertType = AlertType.INFO;
        //
        // if (minutesAvailable <= 0) {
        //     alertType = AlertType.WARNING;
        // }

        // if (minutesAvailable <= 10 && this.state.isTelemedicineAlertShown) {
        if (this.props.isSubscriptionPlanFree) {
            return(
                // <div className={`${styles.alert} ${alertType === AlertType.INFO ? styles.info : ''}`}>
                <div className={styles.alert}>
                    <div className={styles.message}>
                        <div className={styles.infoIcon}>
                            <i>!</i>
                        </div>
                        {/*{alertType === AlertType.INFO ?*/}
                        {/*    (<p>*/}
                        {/*        <Translation text={'consultations.list.alerts.minutesLeft'}*/}
                        {/*                     config={{minutes: minutesAvailable}} />*/}
                        {/*    </p>) :*/}
                        {/*    (<p><Translation text={'consultations.list.alerts.minutesEnded'}/></p>)*/}
                        {/*}*/}
                        <p><Translation text={'consultations.list.alerts.freePlanDetails'}/></p>
                        <a href="/dashboard/billings"><Translation text={'consultations.list.alerts.upgradePlan'}/></a>
                    </div>

                    <button
                        type="button"
                        onClick={() => this.setState({isTelemedicineAlertShown: !this.state.isTelemedicineAlertShown})}
                        className={`${styles.btnClose}`}>
                        X
                    </button>
                </div>
            );
        } else {
            return null;
        }
    };
}

export default withTranslation()(withRouter(ConsultationAlert));
