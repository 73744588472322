import React from "react";
import {accountLoadingSelector, authTokenSelector, Loader, Translation, Tabs, CustomCard} from "common-web";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {PaymentAccount, setPaymentAccountData} from "../../store/reducers/accountSlice";
import {getAccountDataAPI} from "../../api/getAccountData";
import {accountSelector} from "../../store/selectors/accountSelectors";
import SubscriptionPlans from "./SubscriptionPlans";
import Invoices from "./Invoices";
import PaymentMethods from "./PaymentMethods";
import PaymentCollection from "./PaymentCollection";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {of, Subscription} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {ModuleConfigType} from "../Consultations/CalendarWidgets";


interface IConnectedBillingsProps {
    readonly authToken: string | null;
    readonly account: any;
    readonly setPaymentAccountData: typeof setPaymentAccountData;
}

interface IBillingsState {
    accountDataIsLoading: boolean;
    chosenTab: number;
    subscriptions: {[key: string]: any}[];
    activeSubscription: {[key: string]: any} | null;
    proposedSubscriptions: {[key: string]: any}[];
    paidSubscription: {[key: string]: any} | null;
    clinicOrigin: string | null;
    calendarModuleConfig: {[key: string]: any} | null;
}

interface IExternalBillingsProps {}

interface IBillingsProps extends IConnectedBillingsProps,
    IExternalBillingsProps,
    RouteComponentProps {}


class Billings extends React.Component<IBillingsProps, IBillingsState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IBillingsProps) {
        super(props);

        this.state = {
            accountDataIsLoading: false,
            chosenTab: 0,
            subscriptions: [],
            activeSubscription: null,
            proposedSubscriptions: [],
            paidSubscription: null,
            clinicOrigin: null,
            calendarModuleConfig: null
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setState({accountDataIsLoading: true});
        if (this.props.authToken) {
            this.subscriptions.push(
                getAccountDataAPI(this.props.authToken).pipe(
                    tap((resp: any) => {
                        this.updateStateFromApi(resp);
                        let calendarModuleConfig = resp.clinic.moduleConfigs.find((item: {[key: string]: any}) => {
                            return item.module.name === ModuleConfigType.CALENDAR;
                        });
                        this.setState({
                            accountDataIsLoading: false,
                            clinicOrigin: resp.account?.address?.country,
                            calendarModuleConfig: calendarModuleConfig
                        });
                    }),
                    catchError((err: any) => {
                        this.setState({accountDataIsLoading: false});
                        this.alertManager.handleApiError(err);
                        return of();
                    })
                ).subscribe()
            );
        } else {
            this.setState({accountDataIsLoading: false})
        }

        if (this.props.history && this.props.history.location &&
            this.props.history.location.state && (this.props.history.location.state as any).selectedTab) {
            this.setState({chosenTab: (this.props.history.location.state as any).selectedTab});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'billings.title'}/>
                            </div>
                        </div>
                    </div>
                </div>
                <CustomCard>
                    <CustomCard.Body>
                        {this.renderBillingTabs()}
                    </CustomCard.Body>
                </CustomCard>
                <Loader showLoader={this.state.accountDataIsLoading}/>
            </React.Fragment>
        );
    }

    private renderBillingTabs() {
        if (this.state.accountDataIsLoading) {
            return;
        } else {
            return (
                <Tabs chosenTab={this.state.chosenTab} selectedTab={this.selectedTab}>
                    <div className="tab-headers">
                        <span><Translation text={'billings.tabs.subscription'}/></span>
                        <span><Translation text={'billings.tabs.invoices'}/></span>
                        <span><Translation text={'billings.tabs.paymentMethods'}/></span>
                        {this.state.clinicOrigin && this.state.clinicOrigin !== "TR" ?
                            (<span><Translation text={'billings.tabs.paymentsCollection'}/></span>) :
                            null
                        }
                    </div>
                    <div className="tabs-content">
                        <div className="tabs-content">
                            <SubscriptionPlans clinicSubscriptions={this.state.subscriptions}
                                               activeSubscription={this.state.activeSubscription}
                                               proposedSubscription={this.state.proposedSubscriptions}
                                               paidSubscription={this.state.paidSubscription}
                                               calendarModuleConfig={this.state.calendarModuleConfig} />
                        </div>
                        <div className="tabs-content">
                            <Invoices />
                        </div>
                        <div className="tabs-content">
                            <PaymentMethods subscriptions={this.state.subscriptions}
                                            authToken={this.props.authToken}/>
                        </div>
                        {this.state.clinicOrigin && this.state.clinicOrigin !== "TR" ?
                            (<div className="tabs-content">
                                <PaymentCollection/>
                            </div>) :
                            null
                        }
                    </div>
                </Tabs>
            )
        }
    }

    private updateStateFromApi(apiData: any) {
        const accountData = apiData.account,
            paymentAccounts: PaymentAccount[] = accountData.paymentAccounts.map((account: {[key: string]: any}) => {
                return {
                    id: account.id,
                    paymentAccountVendorData: {
                        accountBankTransferBankName: account.paymentAccountVendorData.accountBankTransferBankName,
                        accountBankTransferCountry: account.paymentAccountVendorData.accountBankTransferCountry,
                        accountBankTransferCurrency: account.paymentAccountVendorData.accountBankTransferCurrency,
                        accountBankTransferLastFour: account.paymentAccountVendorData.accountBankTransferLastFour,
                        accountEmail: account.paymentAccountVendorData.accountEmail,
                        chargesEnabled: account.paymentAccountVendorData.chargesEnabled,
                        customerId: account.paymentAccountVendorData.customerId,
                        customerPaymentMethodBrand: account.paymentAccountVendorData.customerPaymentMethodBrand,
                        customerPaymentMethodExpMonth: account.paymentAccountVendorData.customerPaymentMethodExpMonth,
                        customerPaymentMethodExpYear: account.paymentAccountVendorData.customerPaymentMethodExpYear,
                        customerPaymentMethodId: account.paymentAccountVendorData.customerPaymentMethodId,
                        customerPaymentMethodLastFour: account.paymentAccountVendorData.customerPaymentMethodLastFour,
                        customerPaymentMethodName: account.paymentAccountVendorData.customerPaymentMethodName,
                        paymentAccountId: account.paymentAccountVendorData.paymentAccountId
                    },
                    paymentAccountVendorType: account.paymentAccountVendorType
                }
            });
        this.props.setPaymentAccountData(paymentAccounts);

        if (accountData.subscription) {
            const currentSubscription = accountData.subscription.currentSubscriptionEntry,
                proposedSubscriptions = accountData.subscription.proposedPlans,
                subscriptions = accountData.subscription.subscriptionEntries,
                paidSubscription = accountData.subscription;
            this.setState({
                activeSubscription: currentSubscription,
                subscriptions: subscriptions,
                proposedSubscriptions: proposedSubscriptions,
                paidSubscription: paidSubscription,
            });
        }
    }

    private selectedTab = (tabNumber: number) => {
        this.setState({chosenTab: tabNumber});
    }
}


export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isLoading: accountLoadingSelector(state),
        account: accountSelector(state),
    }),
    {
        setPaymentAccountData
    }
)(withRouter(Billings));
