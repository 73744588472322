import {FormControlType, InputType, ValidationRules} from 'common-web';

export const clinicStaffFormConfig: {[key: string]: any} = {
  controlType: 'group',
  class: '',
  dataAccessor: (data: any) => data, // data is not grouped
  outputDataMapper: (data: any, previousStateSnapshot: any) => {
    Object.assign(previousStateSnapshot, data);

    return previousStateSnapshot;
  },
  controls: [
    {
      controlType: 'group',
      key: 'staff',
      class: 'row',
      controls: [
        {
          staff: {
            controlType: 'control',
            defaultValue: '',
            formControlType: FormControlType.INPUT,
            validationRules: [
              { name: ValidationRules.IS_REQUIRED },
            ],
            placeholder: 'Staff',
            label: "Staff",
            type: InputType.TEXT,
            hostClass: 'col-xl-12',
          }
        },
      ]
    }
  ]
};
