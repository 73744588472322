import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {authTokenSelector, IMultiselectOption, MultiSelect, MultiSelectType} from 'common-web';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {list as calendarList} from '../../../../actions/calendar/list';
import {WithTranslation, withTranslation} from 'react-i18next';
import {
    calendarListLoadingSelector,
    retrievedCalendarListSelector
} from "../../../../store/selectors/calendarListSelectors";
import {Subject, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

interface IConnectedSelectCalendarInputProps {
    readonly retrievedCalendars: { [key: string]: any };
    readonly calendarLoading: boolean;
    readonly calendarList: any;
    readonly authToken: string;
    readonly showConsultations: (calendarId: string) => void;
}

interface IExternalSelectCalendarInputProps {
}

interface ISelectCalendarInputProps extends IConnectedSelectCalendarInputProps,
    IExternalSelectCalendarInputProps,
    RouteComponentProps,
    WithTranslation {
}

interface ISelectCalendarInputState {
    isLoading: boolean;
    defaultOptions: any[];
    options: any[];
    searchInputValue: typeof IMultiselectOption | null;
    debounced: string;
}


class SelectCalendarInput extends React.Component<ISelectCalendarInputProps, ISelectCalendarInputState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    private subscription: Subscription | null = null;
    private readonly onSearchSubject = new Subject();

    constructor(props: ISelectCalendarInputProps) {
        super(props);

        this.state = {
            isLoading: false,
            defaultOptions: [],
            options: [],
            searchInputValue: null,
            debounced: '',
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscription = this.onSearchSubject
            .pipe(
                debounceTime(750),
                distinctUntilChanged()
            )
            .subscribe(
                (debounced: any) => this.props.showConsultations(debounced.value)
            );

        this.props.calendarList(
            `calendars`,
            this.props.authToken
        );
        const {t} = this.props,
            defaultOptions = [
                {
                    value: 'all',
                    label: t(`consultations.list.selectCalendar.allCalendars`),
                    isDisabled: false
                }
            ];
        this.setState({defaultOptions: defaultOptions, options: defaultOptions});
    }

    componentDidUpdate(
        prevProps: Readonly<ISelectCalendarInputProps>,
        prevState: Readonly<ISelectCalendarInputState>,
        snapshot?: any
    ): void {
        if (this.props.retrievedCalendars !== prevProps.retrievedCalendars) {
            const {t} = this.props;
            let updatedOptions = [...this.state.defaultOptions];
            if (!this.props.retrievedCalendars || !this.props.retrievedCalendars['hydra:member'] ||
                !Array.isArray(this.props.retrievedCalendars['hydra:member']) || !this.props.retrievedCalendars['hydra:member'].length) {
                updatedOptions.push({
                    value: 'no_data',
                    label: t(`consultations.list.selectCalendar.noCalendars`),
                    isDisabled: true
                })
            } else {
                this.props.retrievedCalendars['hydra:member'].map((item: any) => {
                    const option = {
                        value: item.id,
                        label: item.name,
                        isDisabled: false
                    };
                    updatedOptions.push(option);
                });
            }
            this.setState({options: updatedOptions});
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div className="select calendar-select form-control mr-4">
                <MultiSelect
                    multiselectType={MultiSelectType.SINGLE}
                    handleChange={
                        (e: any) => this.onSearch(e)
                    }
                    options={this.state.options}
                    name={'select_calendars'}
                    placeholder={t('consultations.list.selectCalendar.title')}
                    value={[this.state.searchInputValue]}/>
            </div>
        );
    }

    private onSearch = (item: any) => {
        const searchValue = {value: item.value, label: item.label};
        this.setState({searchInputValue: searchValue});
        this.onSearchSubject.next(searchValue);
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        calendarLoading: calendarListLoadingSelector(state),
        retrievedCalendars: retrievedCalendarListSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        calendarList
    }
)(withRouter(SelectCalendarInput)));
