import React, {CSSProperties} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import styles from './styles.module.scss';
import {clinicIdSelector, Translation} from 'common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../../../store/reducers';
import {fixInjectedProperties, lazyInject} from '../../../../../ioc';
import {IAlertManagerService} from '../../../../../service/alertManagerService';
import ScriptTag from 'react-script-tag';

declare global {
    namespace JSX {

        export interface IntrinsicElements {
            'x-calendar-widget': ICalendarWidgetProps;
        }

        interface ICalendarWidgetProps
            extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>,
                HTMLElement> {
            clinicId: string;
            calendarId: string;
            baseUrl: string;
            readonly style?: CSSProperties;
            readonly isInitialPositionDisabled?: boolean;
        }
    }
}

interface IConnectedCalendarWidgetProps {
    readonly clinicId: string;
}

interface IExternalCalendarWidgetProps {
    calendarId: string;
}

interface ICalendarWidgetProps extends IConnectedCalendarWidgetProps,
    IExternalCalendarWidgetProps,
    RouteComponentProps {
}

interface ICalendarWidgetState {
    clinicId: string;
}

class CalendarWidget extends React.Component<ICalendarWidgetProps, ICalendarWidgetState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: ICalendarWidgetProps) {
        super(props);

        this.state = {
            clinicId: this.props.clinicId
        };

        fixInjectedProperties(this);
    }

    get widget() {
        return `<x-calendar-widget clinicId="${this.state.clinicId}"
              calendarId="${this.props.calendarId}"
              baseUrl="${process.env.REACT_APP_CALENDAR_WIDGET_URL}"
              style="height: 280px; min-width: 100%;"></x-calendar-widget>
              <script src="${process.env.REACT_APP_CALENDAR_WIDGET_URL}/calendar-widget.js"></script>`;
    }

    get clinicLink() {
        return `${process.env.REACT_APP_CONSULTATION_WEB_URL}/inquiry/treatment?cid=${this.state.clinicId}`;
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className={styles.widgetItem}>
                            <div className={styles.widgetHeader}>
                                <h4><Translation text={'calendar.calendarTimeSlots.tabs.widget.tutorial'}/></h4>
                            </div>
                            <ol className={styles.tutorialList}>
                                <li><Translation text={'calendar.calendarTimeSlots.tabs.widget.steps.first'}/></li>
                                <li><Translation text={'calendar.calendarTimeSlots.tabs.widget.steps.second'}/></li>
                                <li><Translation text={'calendar.calendarTimeSlots.tabs.widget.steps.third'}/></li>
                            </ol>
                        </div>

                        <div className={styles.widgetItem}>
                            <div className={styles.widgetHeader}>
                                <h4><Translation text={'calendar.calendarTimeSlots.tabs.widget.copyWidget'}/></h4>
                                <div className={styles.snippetIconContainer}>
                                    <i className="feather icon-copy"/>
                                </div>
                            </div>
                            <div className={styles.snippet} onClick={() => this.copyWidgetToClipboard()}>
                                <div>{this.widget}</div>
                            </div>
                        </div>

                        <div className={styles.widgetItem}>
                            <div className={styles.widgetHeader}>
                                <h4><Translation text={'calendar.calendarTimeSlots.tabs.widget.preview'}/></h4>
                            </div>
                            <div style={{minHeight: '260px', minWidth: '100%', backgroundColor: '#f4f4f4', padding: '1rem'}}>
                                <x-calendar-widget clinicId={`${this.state.clinicId}`}
                                                   calendarId={`${this.props.calendarId}`}
                                              baseUrl={`${process.env.REACT_APP_CALENDAR_WIDGET_URL}`}
                                              style={{height: '100%', width: '100%'}} />
                                <ScriptTag type="text/javascript" src={`${process.env.REACT_APP_CALENDAR_WIDGET_URL}/calendar-widget.js`}/>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private copyWidgetToClipboard = () => {
        navigator.clipboard.writeText(this.widget);
        this.alertManagerService.addAlert('inquiries.clinicWidget.copyWidget.widgetCopied');
    };

    private copyLinkToClipboard = () => {
        navigator.clipboard.writeText(this.clinicLink);
        this.alertManagerService.addAlert('inquiries.clinicWidget.copyLink.linkCopied');
    };


}

export default connect(
    (state: RootState) => ({
        clinicId: clinicIdSelector(state),
    }),
    {}
)(withRouter(CalendarWidget));
