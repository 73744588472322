import {FormControlType, InputType, ValidationRules} from 'common-web';

export const treatmentCategoriesFormConfig: {[key: string]: any} = {
  controlType: 'group',
  class: '',
  dataAccessor: (data: any) => data, // data is not grouped
  outputDataMapper: (data: any, previousStateSnapshot: any) => {
    Object.assign(previousStateSnapshot, data);

    return previousStateSnapshot;
  },
  controls: [
    {
      controlType: 'group',
      key: 'treatments_categories',
      class: 'row',
      controls: {
        treatmentCategories: {
          controlType: 'control',
          defaultValue: '',
          formControlType: FormControlType.AUTOCOMPLETE,
          options: [],
          inputDataMapper: (data: any) => {
            if (!data) {
              return [];
            }

            return data.map((category: any) => ({
              value: category.id,
              label: category.name,
            }));
          },
          disabled: true,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
          placeholder: 'No extra services selected',
          label: 'profile.treatmentCategories.title',
          type: InputType.TEXT,
          hostClass: 'col-xl-12',
        }
      },
    }
  ]
};
