import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterCalendarAPI} from "./provider/clinicHunterCalendar";
import {IAftercareRecommendationPayload} from "./createAftercareRecommendationAPI";

export interface IUpdateAftercarePayload {
    patientId?: string,
    doctorId?: string,
    clinicId?: string,
    treatmentTypeId?: string,
    treatmentPlanStatus?: string,
    treatmentEndDate?: string,
    treatmentPoints?: IAftercareRecommendationPayload[]
}

export function updateAftercareAPI(
    authToken: string | undefined,
    treatmentPlanId: string,
    aftercareDetails: IUpdateAftercarePayload
): Observable<any> {
    const payload: {[key: string]: any} = {};

    if (aftercareDetails.patientId) {
        payload['patientId'] = aftercareDetails.patientId;
    }

    if (aftercareDetails.doctorId) {
        payload['doctorId'] = aftercareDetails.doctorId;
    }

    if (aftercareDetails.clinicId) {
        payload['clinicId'] = aftercareDetails.clinicId;
    }

    if (aftercareDetails.treatmentTypeId) {
        payload['treatmentTypeId'] = aftercareDetails.treatmentTypeId;
    }

    if (aftercareDetails.treatmentPlanStatus) {
        payload['treatmentPlanStatus'] = aftercareDetails.treatmentPlanStatus;
    }

    if (aftercareDetails.treatmentEndDate) {
        payload['treatmentEndDate'] = aftercareDetails.treatmentEndDate;
    }

    if (aftercareDetails.treatmentPoints) {
        payload['treatmentPoints'] = aftercareDetails.treatmentPoints;
    }

    return clinicHunterCalendarAPI.put(
        `treatment_plans/${treatmentPlanId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
