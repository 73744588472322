import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface IPatientListBaseState {
    loading: boolean;
    error: string;
}

export interface IPatientListState extends IPatientListBaseState {
    retrieved: any; // model for inquiry
    eventSource: EventSource;
}

export const selectPatientList = (state: RootState): IPatientListState => {
    return state.patientList.list;
};

export const retrievedPatientsListSelector = createSelector(
    [selectPatientList],
    (state: IPatientListState) => state.retrieved
);

export const patientsListLoadingSelector = createSelector(
    [selectPatientList],
    (state: IPatientListState) => state.loading
);

export const patientsListErrorSelector = createSelector(
    [selectPatientList],
    (state: IPatientListState) => state.error
);

export const patientsListEventSourceSelector = createSelector(
    [selectPatientList],
    (state: IPatientListState) => state.eventSource
);
