import React from "react";
import {authTokenSelector, Toast, Translation} from "common-web";
import GeneralInformation from "./GeneralInformation";
import ClinicTreatments from "./ClinicTreatments";
import ClinicStaff from "./ClinicStaff";
import ClinicPhotos from "./ClinicPhotos";
import ClinicDetails from "./ClinicDetails";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {accountSelector} from "../../store/selectors/accountSelectors";
import {Subscription} from "rxjs";
import {clinicSelector} from "common-web";

interface IConnectedProfileProps {
    readonly authToken: string | null;
    readonly account: { [key: string]: any };
    readonly clinic: { [key: string]: any };
}

interface IExternalProfileProps {
}

interface IProfileState {
    clinic: { [key: string]: any } | null;
    clinicProfile: { [key: string]: any } | null;
    dataIsLoading: boolean;
}

interface IProfileProps extends IConnectedProfileProps,
    IExternalProfileProps {
}


class Profile extends React.Component<IProfileProps, IProfileState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    private subscription: Subscription | null = null;

    constructor(props: IProfileProps) {
        super(props);

        this.state = {
            clinic: this.props.clinic,
            clinicProfile: this.props.clinic.profile,
            dataIsLoading: false
        };
        fixInjectedProperties(this);
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'profile.title'}/>
                            </div>
                            <div className="action-container">
                                <Translation text={'profile.additionalInfo'}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6">
                        <GeneralInformation clinicProfile={this.state.clinicProfile}
                                            isLoading={this.state.dataIsLoading}/>

                        <ClinicStaff/>

                        <ClinicTreatments clinic={this.state.clinic}
                                          isLoading={this.state.dataIsLoading}/>
                    </div>

                    <div className="col-xl-6">
                        <ClinicDetails clinicProfile={this.state.clinicProfile}
                                       isLoading={this.state.dataIsLoading}/>

                        <ClinicPhotos/>
                    </div>
                </div>
                <Toast/>
            </React.Fragment>
        );
    } // toDo PPP why Toast here, not global one ?
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        account: accountSelector(state),
        clinic: clinicSelector(state)
    }),
    {}
)(Profile);
