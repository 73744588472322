import { createSelector } from "redux-starter-kit";
import { RootState } from "../reducers";
import { IAccountState } from "../reducers/accountSlice";

export const selectAccount = (state: RootState): IAccountState => {
    return (state as any).account;
};

export const accountSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state
);

export const accountIdSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state.id
);

export const personalDataSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state.personalData
);

export const logoSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state.logo
);

export const clinicCategoriesSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state.clinicCategories
);

export const addressDataSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state.addressData
);

export const invoiceDataSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state.invoiceData
);

export const paymentAccountsSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state.paymentAccounts
);

export const subscriptionSelector = createSelector(
    [selectAccount],
    (state: IAccountState) => state.subscription
);
