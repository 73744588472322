import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface IAfterCareBaseState {
    loading: boolean;
    error: string;
}

export interface IAfterCareListState extends IAfterCareBaseState {
    retrieved: any; // model for aftercare
    eventSource: EventSource;
}

export const selectAfterCareList = (state: RootState): IAfterCareListState => {
    return state.afterCare.list;
};

export const retrievedAfterCareListSelector = createSelector(
    [selectAfterCareList],
    (state: IAfterCareListState) => state.retrieved
);

export const afterCareListLoadingSelector = createSelector(
    [selectAfterCareList],
    (state: IAfterCareListState) => state.loading
);

export const afterCareListErrorSelector = createSelector(
    [selectAfterCareList],
    (state: IAfterCareListState) => state.error
);

export const afterCareListEventSourceSelector = createSelector(
    [selectAfterCareList],
    (state: IAfterCareListState) => state.eventSource
);
