import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface IInquiryBaseState {
    loading: boolean;
    error: string;
}

export interface ICalendarItemState extends IInquiryBaseState {
    retrieved: any;
    eventSource: EventSource;
}

export const selectCalendarItem = (state: RootState): ICalendarItemState => {
    return state.calendar.show;
};

export const retrievedCalendarItemSelector = createSelector(
    [selectCalendarItem],
    (state: ICalendarItemState) => state.retrieved
);

export const calendarItemLoadingSelector = createSelector(
    [selectCalendarItem],
    (state: ICalendarItemState) => state.loading
);

export const calendarItemErrorSelector = createSelector(
    [selectCalendarItem],
    (state: ICalendarItemState) => state.error
);

export const calendarItemEventSourceSelector = createSelector(
    [selectCalendarItem],
    (state: ICalendarItemState) => state.eventSource
);
