import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterClinicAPI} from "./provider/clinicHunterClinic";
import {PaymentServiceName} from "./buySubscriptionDefiniton";

export function attachPaymentMethodAPI(
    authToken: string,
    paymentAccountId: string,
    paymentServiceName: PaymentServiceName,
    paymentMethodId: string
): Observable<any> {
    const payload = {
        paymentServiceName: paymentServiceName,
        paymentMethod: {
            paymentMethodId: paymentMethodId
        }
    };

    return clinicHunterClinicAPI.put(
        `payment_accounts/${paymentAccountId}/attach_payment_method`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
