import React from 'react';
import {BasicModal, CustomCard, CustomCardType, Translation} from "common-web";
import styles from "./styles.module.scss";


export interface ICalendarLimitsModalProps {
    calendarLimitsModalShown: boolean;
    toggleCalendarLimitsModal: () => void;
}

interface ICalendarLimitsModalState {}

class CalendarLimitsModal extends React.Component<ICalendarLimitsModalProps, ICalendarLimitsModalState> {

    componentDidMount() {}

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {}

    render() {
        return (
            <BasicModal isModalShown={this.props.calendarLimitsModalShown} closeModal={this.props.toggleCalendarLimitsModal}>
                <CustomCard type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <h4><Translation text={'calendar.modals.confirmCreateModal.title'}/></h4>
                            <button className="btn-modal-close" onClick={() => this.props.toggleCalendarLimitsModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className={styles.modalBody}>
                                <Translation text={'calendar.modals.calendarLimitsModal.limitReached'}/>
                                <a href="mailto:contact@clinichunter.com" className={styles.contactLink}>
                                    <Translation text={'calendar.modals.calendarLimitsModal.salesTeam'}/>
                                </a>
                                <Translation text={'calendar.modals.calendarLimitsModal.createCustomPlan'}/>
                            </div>
                        </div>
                    </CustomCard.Body>
                </CustomCard>
            </BasicModal>
        );
    }
}

export default CalendarLimitsModal;
