import { createSlice } from "redux-starter-kit";

export interface ITreatmentCategory {
    id: string;
    name: string;
}

export interface ILanguage {
    title: string;
    description: string;
}

export interface ICertificate {
    title: string;
    description: string;
}

export interface IMembershipAndAwards {
    title: string;
    description: string;
}

export interface ISpecialistSpecialization {
    id: string;
    name: string;
}

export interface ISpecialistClinic {
  id: string;
  logo: {
    public: boolean;
    contentUrl: string;
  }
}

export interface ISpecialistPhoto {
    public: boolean,
    contentUrl: string
}

export interface ISpecialistAddress {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    postcode: string;
    city: string;
    country: string;
}

export interface ISpecialistInvoiceAddress {
    taxId: string,
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    postcode: string;
    city: string;
    country: string;
}

export interface ISpecialistAccount {
    firstName: string;
    lastName: string;
    birthdate: string;
    phone: string;
    address: ISpecialistAddress | null;
    invoiceAddress: ISpecialistInvoiceAddress | null;
    residency: string;
}

export interface ISpecialistState {
    id: string | null;
    photo: ISpecialistPhoto | null;
    description: string | null;
    education: string | null;
    yearsExperience: string | null;
    languages: any[] | null;
    certificates: any[] | null;
    membershipAndAwards: any[] | null;
    treatments: ITreatmentCategory[] | null;
    specializations: ISpecialistSpecialization[] | null;
    clinics: ISpecialistClinic[] | null;
    error: string | null;
}

export interface ISetSpecialistState {
    readonly payload: {
        id: string;
        photo: ISpecialistPhoto;
        description: string;
        education: string,
        yearsExperience: string;
        languages: any[];
        certificates: any[];
        membershipAndAwards: any[];
        treatments: ITreatmentCategory[];
        specializations: ISpecialistSpecialization[];
        clinics: ISpecialistClinic[];
    }
}

export interface IAddSpecialist {
    readonly payload: {
        login: string;
        plainPassword: string;
        account: ISpecialistAccount;
        description: string;
        education: string;
        yearsExperience: string;
        languages: ILanguage[] | [];
        certificates: ICertificate[] | [];
        membershipAndAwards: IMembershipAndAwards[] | [];
        treatments: string[] | [];
        specializations: string[] | [];
        clinics: string[] | [];
    }
}

export interface ISetSpecialistStateFailure {
    readonly payload: {
        error: string;
    }
}

const initialState: ISpecialistState = {
    id: null,
    photo: null,
    description: null,
    education: null,
    yearsExperience: null,
    languages: null,
    certificates: null,
    membershipAndAwards: null,
    treatments: null,
    specializations: null,
    clinics: null,
    error: null
};

const specialistSlice = createSlice({
    slice: "specialist",
    initialState: initialState,
    reducers: {
        setSpecialistState: {
            reducer: (state: ISpecialistState, action: ISetSpecialistState) => {
                return {
                    id: action.payload.id,
                    photo: action.payload.photo,
                    description: action.payload.description,
                    education: action.payload.education,
                    yearsExperience: action.payload.yearsExperience,
                    languages: action.payload.languages,
                    certificates: action.payload.certificates,
                    membershipAndAwards: action.payload.membershipAndAwards,
                    treatments: action.payload.treatments,
                    specializations: action.payload.specializations,
                    clinics: action.payload.clinics,
                    error: state.error
                };
            },
            prepare(id: string,
                    photo: ISpecialistPhoto,
                    description: string,
                    education: string,
                    yearsExperience: string,
                    languages: any[],
                    certificates: any[],
                    membershipAndAwards: any[],
                    treatments: ITreatmentCategory[],
                    specializations: ISpecialistSpecialization[],
                    clinics: ISpecialistClinic[],
            ) {
                return {
                    payload: {
                        id: id,
                        photo: photo,
                        description: description,
                        education: education,
                        yearsExperience: yearsExperience,
                        languages: languages,
                        certificates: certificates,
                        membershipAndAwards: membershipAndAwards,
                        treatments: treatments,
                        specializations: specializations,
                        clinics: clinics
                    }
                };
            }
        },
        addSpecialist: {
            reducer: (state: ISpecialistState, action: IAddSpecialist) => {
                return {
                    id: state.id,
                    photo: state.photo,
                    description: action.payload.description,
                    education: action.payload.education,
                    yearsExperience: action.payload.yearsExperience,
                    languages: state.languages,
                    certificates: state.certificates,
                    membershipAndAwards: state.membershipAndAwards,
                    treatments: state.treatments,
                    specializations: state.specializations,
                    clinics: state.clinics,
                    error: state.error
                };
            },
            prepare(login: string,
                    plainPassword: string,
                    account: ISpecialistAccount,
                    description: string,
                    education: string,
                    yearsExperience: string,
                    languages: ILanguage[] | [],
                    certificates: ICertificate[] | [],
                    membershipAndAwards: IMembershipAndAwards[] | [],
                    treatments: string[] | [],
                    specializations: string[] | [],
                    clinics: string[] | []
            ) {
                return {
                    payload: {
                        login: login,
                        plainPassword: plainPassword,
                        account: account,
                        description: description,
                        education: education,
                        yearsExperience: yearsExperience,
                        languages: languages,
                        certificates: certificates,
                        membershipAndAwards: membershipAndAwards,
                        treatments: treatments,
                        specializations: specializations,
                        clinics: clinics
                    }
                };
            }
        },
        setSpecialistStateFailure: {
            reducer: (state: ISpecialistState, action: ISetSpecialistStateFailure) => {
                return {
                    id: state.id,
                    photo: state.photo,
                    description: state.description,
                    education: state.education,
                    yearsExperience: state.yearsExperience,
                    languages: state.languages,
                    certificates: state.certificates,
                    membershipAndAwards: state.membershipAndAwards,
                    treatments: state.treatments,
                    specializations: state.specializations,
                    clinics: state.clinics,
                    error: action.payload.error
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error
                    }
                };
            }
        },
    }
});

export const {
    setSpecialistState,
    setSpecialistStateFailure,
    addSpecialist
} = specialistSlice.actions;

export default specialistSlice.reducer;
