import {FormControlType, InputType, ValidationRules, IFormConfig, FormButtonType} from 'common-web';

export const changePasswordFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'old_password',
            controlType: 'group',
            class: 'row',
            controls: {
                oldPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                        { name: ValidationRules.MIN_LENGTH, params: { length: 4 } },
                    ],
                    placeholder: 'Current password',
                    label: "Current password",
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12',
                }
            }
        },
        {
            key: 'new_password',
            controlType: 'group',
            class: 'row',
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: { accessors: [ (value: any) => value.password, (value: any) => value.repeatPassword ] }
                },
            ],
            controls: {
                password: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                        { name: ValidationRules.MIN_LENGTH, params: { length: 4 } },
                    ],
                    placeholder: 'New password',
                    label: "New password",
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12',
                },
                repeatPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                        { name: ValidationRules.MIN_LENGTH, params: { length: 4 } },
                    ],
                    placeholder: 'Repeat password',
                    label: "Repeat password",
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12',
                }
            }
        },
        {
            controlType: 'group',
            key: 'change_password_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2 pr-0',
                    defaultValue: null,
                    btnText: 'Change password',
                    defaultStyles: 'btn btn-primary'
                },
            },
        },
    ]
};
