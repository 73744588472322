import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface IRecommendationDefinitionBaseState {
    loading: boolean;
    error: string;
}

export interface IRecommendationDefinitionListState extends IRecommendationDefinitionBaseState {
    retrieved: any; // model for aftercare
    eventSource: EventSource;
}

export const selectRecommendationDefinitionList = (state: RootState): IRecommendationDefinitionListState => {
    return state.recommendationDefinition.list;
};

export const retrievedRecommendationDefinitionListSelector = createSelector(
    [selectRecommendationDefinitionList],
    (state: IRecommendationDefinitionListState) => state.retrieved
);

export const recommendationDefinitionListLoadingSelector = createSelector(
    [selectRecommendationDefinitionList],
    (state: IRecommendationDefinitionListState) => state.loading
);

export const recommendationDefinitionListErrorSelector = createSelector(
    [selectRecommendationDefinitionList],
    (state: IRecommendationDefinitionListState) => state.error
);

export const recommendationDefinitionListEventSourceSelector = createSelector(
    [selectRecommendationDefinitionList],
    (state: IRecommendationDefinitionListState) => state.eventSource
);
