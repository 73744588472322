import React, {CSSProperties} from 'react';
import styles from '../styles.module.scss';
import {Translation} from 'common-web';
import ScriptTag from 'react-script-tag';

export enum BannerType {
    VERTICAL_S = 'vertical_small',
    VERTICAL_M = 'vertical_medium',
    SQUARE = 'square',
    HORIZONTAL_S = 'horizontal_small',
    HORIZONTAL_M = 'horizontal_medium',
    HORIZONTAL_L = 'horizontal_large',
}

declare global {
    namespace JSX {

        export interface IntrinsicElements {
            'x-banner-widget': IBannerWidgetProps;
        }

        interface IBannerWidgetProps
            extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>,
                HTMLElement> {
            clinicId: string;
            bannerType: BannerType;
            baseUrl: string;
            readonly style?: CSSProperties;
        }
    }
}

interface IConnectedBannerWidgetComponentProps {}

interface IExternalBannerWidgetComponentProps {
    readonly clinicId: string;
    readonly copyToClipboard: (content: string, message: string) => void;
}

interface IBannerWidgetComponentProps extends IConnectedBannerWidgetComponentProps,
    IExternalBannerWidgetComponentProps{
}

interface IInsuranceWidgetState {}

class BannerWidgetComponent extends React.Component<IBannerWidgetComponentProps, IInsuranceWidgetState> {
    private bannerWidget = (bannerType: BannerType) => {
        return `
                <x-banner-widget clinicId="${this.props.clinicId}" 
                                 bannerType="${bannerType}"
                                 baseUrl="${process.env.REACT_APP_BANNER_WIDGET_URL}">             
                </x-banner-widget>
                <script src="${process.env.REACT_APP_BANNER_WIDGET_URL}/banner-widget.js"></script>
            `;
    }

    render() {
        const bannerSizes = [BannerType.SQUARE, BannerType.VERTICAL_S, BannerType.VERTICAL_M, BannerType.HORIZONTAL_S, BannerType.HORIZONTAL_M, BannerType.HORIZONTAL_L];

        return (
            <>
                {bannerSizes.map((bannerType) => {
                    return (
                        <div className="row">
                            <div className="col-xl-8">
                                <div className={styles.widgetItem}>
                                    <h4><Translation text={'inquiries.clinicWidget.copyBannerWidget.title'}/></h4>
                                    <div className={styles.snippet} onClick={() => this.props.copyToClipboard(this.bannerWidget(bannerType), 'inquiries.clinicWidget.copyWidget.widgetCopied')}>
                                        <div>{this.bannerWidget(bannerType)}</div>
                                        <div className={styles.snippetIconContainer}>
                                            <i className="feather icon-copy"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div className={styles.widgetItem}>
                                    <h4><Translation text={'inquiries.clinicWidget.widgetPreview.title'}/></h4>
                                    <div style={{minHeight: '260px', minWidth: '100%'}}>
                                        <x-banner-widget clinicId={`${this.props.clinicId}`}
                                                         bannerType={bannerType}
                                                         baseUrl={`${process.env.REACT_APP_BANNER_WIDGET_URL}`}
                                                         style={{height: '100%', width: '100%'}} />
                                        <ScriptTag type="text/javascript" src={`${process.env.REACT_APP_BANNER_WIDGET_URL}/banner-widget.js`}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
        );
    }
}

export default BannerWidgetComponent;
