import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicHunterClinicAPI} from "./provider/clinicHunterClinic";

export function getPaymentStatusAPI(subscriptionId: string, accessToken: string): Observable<any> {
    return clinicHunterClinicAPI.get(
        `subscriptions/${subscriptionId}/queue`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        },
    );
}
