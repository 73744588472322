import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PayloadAction} from 'redux-starter-kit';
import jwt_decode from 'jwt-decode';
import {
    addAlert,
    getLoginCredentials,
    IGetLoginCredentials,
    loginAPI,
    setAuthState,
    setLoginFailure,
    userAPI
} from "common-web";
import {AlertType} from "../../service/alertManagerService";
import {MainAccountData, PaymentAccount, Unit, setAccountData, SubscriptionDetails} from "../reducers/accountSlice";

const loginStart: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(getLoginCredentials.type),
        switchMap((action: PayloadAction<typeof IGetLoginCredentials>): any => {
            return loginAPI(action.payload.username, action.payload.password)
                .pipe(
                    switchMap((resp: any) => {
                            const authToken = resp.authToken,
                                refreshToken = resp.refreshToken,
                                decoded = jwt_decode(resp.authToken),
                                userRoles = (decoded as any).roles;
                            if (userRoles.includes(action.payload.role)) {
                                return userAPI(authToken).pipe(
                                    mergeMap((resp: any) => {
                                        const actions: any[] = [
                                            setAuthState(
                                                (state$ as any).value.login.username,
                                                authToken,
                                                refreshToken,
                                                null,
                                                null,
                                                true
                                            )
                                        ];

                                        const accountData = resp.account,
                                            addressDataFromApi = accountData.address,
                                            invoiceDataFromApi = accountData.invoiceAddress,
                                            personalDataFromApi: Unit = {
                                                firstName: accountData.firstName,
                                                lastName: accountData.lastName,
                                                phone: accountData.phone,
                                                birthDate: accountData.birthDate,
                                                residency: accountData.residency,
                                                timezone: accountData.timezone
                                            },
                                            paymentAccounts: PaymentAccount[] = accountData.paymentAccounts.map((account: {[key: string]: any}) => {
                                                return {
                                                    id: account.id,
                                                    paymentAccountVendorData: {
                                                        accountBankTransferBankName: account.paymentAccountVendorData.accountBankTransferBankName,
                                                        accountBankTransferCountry: account.paymentAccountVendorData.accountBankTransferCountry,
                                                        accountBankTransferCurrency: account.paymentAccountVendorData.accountBankTransferCurrency,
                                                        accountBankTransferLastFour: account.paymentAccountVendorData.accountBankTransferLastFour,
                                                        accountEmail: account.paymentAccountVendorData.accountEmail,
                                                        chargesEnabled: account.paymentAccountVendorData.chargesEnabled,
                                                        customerId: account.paymentAccountVendorData.customerId,
                                                        customerPaymentMethodBrand: account.paymentAccountVendorData.customerPaymentMethodBrand,
                                                        customerPaymentMethodExpMonth: account.paymentAccountVendorData.customerPaymentMethodExpMonth,
                                                        customerPaymentMethodExpYear: account.paymentAccountVendorData.customerPaymentMethodExpYear,
                                                        customerPaymentMethodId: account.paymentAccountVendorData.customerPaymentMethodId,
                                                        customerPaymentMethodLastFour: account.paymentAccountVendorData.customerPaymentMethodLastFour,
                                                        customerPaymentMethodName: account.paymentAccountVendorData.customerPaymentMethodName,
                                                        paymentAccountId: account.paymentAccountVendorData.paymentAccountId
                                                    },
                                                    paymentAccountVendorType: account.paymentAccountVendorType
                                                }
                                            }),
                                            subscription: SubscriptionDetails = {
                                                currentSubscriptionEntry: accountData.subscription.currentSubscriptionEntry,
                                                id: accountData.subscription.id,
                                                proposedPlans: accountData.subscription.proposedPlans,
                                                subscriptionEntries: accountData.subscription.subscriptionEntries,
                                                upcomingSubscription: accountData.subscription.upcomingSubscription
                                            };
                                        const updatedAccountState: MainAccountData = {
                                            personalData: personalDataFromApi,
                                            addressData: addressDataFromApi,
                                            invoiceData: invoiceDataFromApi,
                                            paymentAccounts: paymentAccounts,
                                            subscription: subscription,
                                            id: resp.account.id
                                        };

                                        actions.push(setAccountData(updatedAccountState));

                                        return of(...actions);
                                    }),
                                    catchError((error: any) => of(
                                        // setAccountStateFailure(error.toString()),
                                        addAlert({
                                            message: error.response ? error.response.message : "Something went wrong. Please try again later.",
                                            type: AlertType.WARNING
                                        })
                                        )
                                    )
                                );
                            } else {
                                return of(
                                    setLoginFailure("You are not authorized to login to panel."),
                                    addAlert({
                                        message: "You are not authorized to login to panel.",
                                        type: AlertType.WARNING
                                    })
                                );
                            }
                        }
                    ),

                    catchError((error: any) =>
                        of(
                            setLoginFailure(error.toString()),
                            addAlert({
                                message: error.response ? error.response.message : "Something went wrong. Please try again later.",
                                type: AlertType.WARNING
                            })
                        )
                    )
                )
        }),
    );

const loginEpic = combineEpics(
    loginStart
);

export default loginEpic;
