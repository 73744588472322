import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {reset, retrieve} from '../../../../actions/treatmentPlanner/show';
import {RootState} from "../../../../store/reducers";
import {
    authTokenSelector,
    clinicTreatmentCategoriesSelector,
    CustomCard,
    Form,
    FormControlChangeType,
    getTreatmentTypesAPI,
    IFormConfig,
    Translation,
    clinicTimezoneSelector
} from "common-web";
import {calendarFormConfig} from "./calendarFormConfig";
import {
    afterCareItemErrorSelector,
    afterCareItemEventSourceSelector,
    afterCareItemLoadingSelector,
    retrievedAfterCareItemSelector
} from "../../../../store/selectors/afterCareItemSelectors";
import styles from "./styles.module.scss";
import {treatmentPlanDetailsFormConfig} from "./treatmentPlanDetailsFormConfig";
import moment from "moment";
import UpdateEvent from './UpdateEvent';
import {catchError, tap} from "rxjs/operators";
import {IMultiselectOption} from "common-web/src/types";
import {of, Subscription} from "rxjs";
import DeleteEvent from "./DeleteEvent";
import {sendAfterCareToPatientAPI} from "../../../../api/sendAfterCareToPatientAPI";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {AfterCareStatus} from "../index";
import TreatmentPlanPatientInvitationModal from "./TreatmentPlanPatientInvitationModal";
import {EventType} from "../../PredefinedRecommendationsList/AddPredefinedRecommendation";
import CreateEvent from './CreateEvent';

interface IConnectedTreatmentPlanViewProps {
    readonly retrieved?: any;
    readonly loading: boolean;
    readonly error?: string;
    readonly eventSource?: EventSource;
    readonly authToken: string;
    readonly retrieve: typeof retrieve;
    readonly reset: any;
    readonly clinicTreatmentCategories: {[key: string]: any}[];
    readonly clinicTimezone: string | null;
}

interface IExternalTreatmentPlanViewProps {}

interface ITreatmentPlanViewProps extends
    IConnectedTreatmentPlanViewProps,
    IExternalTreatmentPlanViewProps,
    RouteComponentProps {}

interface ITreatmentPlanViewState {
    formConfig: typeof IFormConfig;
    treatmentPlanDetailsFormConfig: typeof IFormConfig;
    value: any;
    selectedRecommendationDate: Date | null;
    addEventModalShown: boolean;
    updateEventModalShown: boolean;
    deleteEventModalShown: boolean;
    patientInvitedModalShown: boolean;
    recommendationsTravelPlanDates: any[] | null;
    recommendationsAftercareDates: any[] | null;
    treatmentPlan: {[key: string]: any} | null;
    selectedRecommendation: {[key: string]: any} | null;
    treatmentMultiselectOptions: IMultiselectOption[];
}

class TreatmentPlanView extends React.Component<ITreatmentPlanViewProps, ITreatmentPlanViewState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: ITreatmentPlanViewProps) {
        super(props);
        this.state = {
            formConfig: calendarFormConfig,
            treatmentPlanDetailsFormConfig: treatmentPlanDetailsFormConfig,
            value: null,
            selectedRecommendationDate: null,
            addEventModalShown: false,
            updateEventModalShown: false,
            deleteEventModalShown: false,
            patientInvitedModalShown: false,
            recommendationsTravelPlanDates: null,
            recommendationsAftercareDates: null,
            treatmentPlan: null,
            selectedRecommendation: null,
            treatmentMultiselectOptions: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        const slug = this.props.match.params;
        this.props.retrieve(`treatment_plans/${(slug as any).id}`, this.props.authToken);
        this.subscriptions.push(this.getTreatmentTypes().subscribe());
    }

    componentDidUpdate(
        prevProps: Readonly<ITreatmentPlanViewProps>,
        prevState: Readonly<ITreatmentPlanViewState>,
        snapshot?: any
    ): void {

        if (this.state.treatmentMultiselectOptions !== prevState.treatmentMultiselectOptions) {
            this.setTreatmentTypesMultiselectOptions();
        }

        if (this.props.retrieved !== prevProps.retrieved &&
            this.props.retrieved) {
            let config = calendarFormConfig;
            config.controls[0].controls.date.recommendationsTravelPlanDates = this.getRecommendationOccurrence(EventType.TRAVEL_PLAN);
            config.controls[0].controls.date.recommendationsAftercareDates = this.getRecommendationOccurrence(EventType.AFTERCARE);
            this.setState({
                treatmentPlan: this.props.retrieved,
                recommendationsTravelPlanDates: this.getRecommendationOccurrence(EventType.TRAVEL_PLAN),
                recommendationsAftercareDates: this.getRecommendationOccurrence(EventType.AFTERCARE),
                formConfig: config,
                value: {
                    planName: this.props.retrieved.name,
                    aftercareDate: this.props.retrieved.treatmentEndDate.split('T')[0],
                    aftercareTreatmentType: {
                        value: this.props.retrieved.treatmentType.id,
                        label: this.props.retrieved.treatmentType.name
                    }
                }
            });
        }

        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }
    }

    componentWillUnmount() {
        if (this.props.eventSource) {
            this.props.reset(this.props.eventSource);
        }

        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        let patient = this.props.retrieved?.patient?.account;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title"><Translation text={'treatmentPlanner.create.title'}/></div>
                            <div>
                                {this.state.treatmentPlan && this.state.treatmentPlan.treatmentPlanStatus !== AfterCareStatus.ACTIVE ?
                                    (<button className="btn btn-theme mr-4"
                                             type="button"
                                             onClick={() => this.updateAftercare()}
                                             disabled={this.state.treatmentPlan &&
                                                       this.state.treatmentPlan.treatmentPoints &&
                                                       !this.state.treatmentPlan.treatmentPoints.length}>
                                        <span className="feather icon-save"/>
                                        <Translation text={'treatmentPlanner.create.invitePatient'}/>
                                    </button>
                                    ) : null
                                }

                                <Link to={`/dashboard/aftercare?page=1&itemsPerPage=10`} className="btn btn-no-outline">
                                    <span className="feather icon-chevron-left"/>
                                    <Translation text={'buttons.back'}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Header>
                                <Translation text={'treatmentPlanner.newTreatmentPlan.patient'}/>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                <div className="row">
                                    <div className="d-flex align-middle col-xl-6">
                                        <div className="d-flex aftercare-details-form">
                                            <Form config={this.state.treatmentPlanDetailsFormConfig}
                                                  controlName={'aftercareDetailsForm'}
                                                  onValueStateChange={this.onValueStateChange}
                                                  value={this.state.value}/>
                                        </div>
                                    </div>

                                    <div className={`${styles.patientInfo} ${"col-xl-6"}`}>
                                        <h4>{patient ? `${patient.firstName} ${patient.lastName}` : '---'}</h4>
                                        <p className={styles.patientDetails}>{patient && patient.phone ? patient.phone : '---'}</p>
                                        <p className={styles.patientDetails}>{patient && patient.user ? patient.user.login : '---'}</p>

                                        <div className={`status-wrapper ${this.state.treatmentPlan?.treatmentPlanStatus} mt-4`}>
                                            <p className="status">
                                                <Translation text={`treatmentPlanner.list.statuses.${this.state.treatmentPlan?.treatmentPlanStatus}`}/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Header>
                                <Translation text={'treatmentPlanner.create.calendar'}/>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                <div className="d-flex align-middle event-form-container">
                                <Form config={this.state.formConfig}
                                      controlName={'calendarForm'}
                                      onValueStateChange={this.onValueStateChange}
                                      value={this.state.value}/>
                                </div>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>

                    <div className="col-xl-6">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Header>
                                <div className="d-flex align-content-center justify-content-between">
                                    <Translation text={'treatmentPlanner.create.dailyEvents.title'}/>
                                    <button className="btn btn-theme"
                                            type="button"
                                            onClick={() => this.toggleNewEventModal()}>
                                        <Translation text={'treatmentPlanner.create.dailyEvents.addEvent'}/>
                                    </button>
                                </div>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                <div className={styles.recommendationDate}>
                                    {this.state.selectedRecommendationDate ?
                                        moment(this.state.selectedRecommendationDate).format('YYYY-MM-DD') : null}

                                </div>
                                {this.renderEventsList()}
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>

                {this.state.addEventModalShown &&
                    <CreateEvent addAfterCareModalShown={this.state.addEventModalShown}
                                          toggleAfterCareModal={this.toggleNewEventModal}
                                          afterCare={this.state.treatmentPlan}
                                          selectedRecommendationDate={this.state.selectedRecommendationDate ?
                                            moment(this.state.selectedRecommendationDate).format('YYYY-MM-DD') :
                                            new Date().toISOString().split('T')[0]} />
                }

                <UpdateEvent updateAfterCareModalShown={this.state.updateEventModalShown}
                                      closeAfterCareModal={this.closeUpdateEventModal}
                                      toggleAfterCareModal={this.toggleUpdateEventModal}
                                      afterCareId={this.state.treatmentPlan?.id}
                                      selectedRecommendation={this.state.selectedRecommendation}
                />

                <DeleteEvent authToken={this.props.authToken}
                                      deleteRecommendationModalShown={this.state.deleteEventModalShown}
                                      toggleDeleteModal={this.toggleDeleteModal}
                                      selectedRecommendation={this.state.selectedRecommendation}
                                      afterCareId={this.state.treatmentPlan?.id}
                />

                <TreatmentPlanPatientInvitationModal togglePatientInvitedModal={this.togglePatientInvitedModal}
                                      patientInvitedModalShown={this.state.patientInvitedModalShown}
                />
            </React.Fragment>
        );
    }

    private renderEventsList = () => {
        if (((!this.props.retrieved ||
            !this.props.retrieved['treatmentPoints'] ||
            !Array.isArray(this.props.retrieved['treatmentPoints']) ||
            !this.props.retrieved['treatmentPoints'].length) &&
            !this.state.selectedRecommendationDate) ||
            !this.state.selectedRecommendationDate) {
            return (<p>Pick a date to see Events</p>);
        }

        let treatmentPoints = this.getSelectedRecommendations(
            this.state.selectedRecommendationDate,
            this.props.retrieved['treatmentPoints']
        );

        if (!treatmentPoints || !treatmentPoints.length) {
            return (<p className={'ml-4'}>There are no recommendations available</p>);
        }

        return (
            <React.Fragment>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th><Translation text={'treatmentPlanner.create.dailyEvents.table.notificationName'}/></th>
                        <th className="text-center"><Translation text={'treatmentPlanner.predefinedEvents.list.type'}/></th>
                        <th className="text-center"><Translation text={'treatmentPlanner.create.dailyEvents.table.actions.title'}/></th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRows(treatmentPoints)}
                    </tbody>
                </table>
            </React.Fragment>
        )
    };

    private renderTableRows(treatmentPoints: {[key: string]: any}) {
        const rows: any[] = [];

        treatmentPoints.map((item: any) => {
            return rows.push((
                <tr key={item['id']}>
                    <td>
                        {item.name}
                    </td>
                    <td className="text-center align-middle">
                        <div
                            className={`status-wrapper ${item.type === EventType.AFTERCARE ? 'aftercare' : 'travel-plan'}`}>
                            <p className="status">
                                <Translation
                                    text={`treatmentPlanner.predefinedEvents.type.${item.type === EventType.AFTERCARE ? 'aftercare' : 'travelPlan'}`}/>
                            </p>
                        </div>
                    </td>
                    <td className="align-middle text-center">
                        <button className="btn btn-action"
                                onClick={() => this.editDailyEvent(item)}>
                            <span className="feather icon-edit-2"/>
                        </button>

                        <button className="btn btn-action ml-2"
                                onClick={() => this.toggleDeleteModal(item)}>
                            <span className="feather icon-trash-2"/>
                        </button>
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private getSelectedRecommendations(selectedDate: Date, treatmentPointsList: {[key: string]: any}[]) {
        let recommendationDate = moment(selectedDate).format('YYYY-MM-DD');

        let treatmentPoints;
        treatmentPoints = treatmentPointsList.filter((treatmentPoint: {[key: string]: any}) => {
            let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (this.props.clinicTimezone) {
                tz = this.props.clinicTimezone;
            }
            let localDate = new Date(treatmentPoint.treatmentPointStartsAt).toLocaleString('en-US', { timeZone: tz });
            let convertedDate = moment(localDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            let date = moment(convertedDate).format('YYYY-MM-DD');
            return date === recommendationDate;
        });

        return treatmentPoints;
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
      if (controlName === 'calendarForm' && changeType === 'user') {
          this.setState({
              selectedRecommendationDate: value.date.value
          })
      }
    };

    private editDailyEvent = (item: {[key: string]: any}) => {
        this.toggleUpdateEventModal();
        this.setState({selectedRecommendation: item});
    };

    private toggleNewEventModal = () => {
        this.setState({addEventModalShown: !this.state.addEventModalShown});
    };

    private toggleUpdateEventModal = () => {
        this.setState({updateEventModalShown: !this.state.updateEventModalShown});
    };

    private closeUpdateEventModal = () => {
        return this.setState({
            updateEventModalShown: false,
            selectedRecommendation: null
        });
    };

    private updateAftercare = () => {
        if (!this.props.retrieved ||
            !this.props.retrieved.treatmentPoints ||
            !this.props.retrieved.treatmentPoints.length) {
            return;
        }

        if(!this.props.retrieved.patient.account.user ||
           !this.props.retrieved.patient.account.user.login) {
           return this.alertManager.addAlert('The patient did not provide an email for communication');
        }

        const patientId = this.props.retrieved.patient.account.id,
            patientEmail = this.props.retrieved.patient.account.user.login,
            treatmentPlanId = this.props.retrieved.id,
            returnUrl = `${process.env.REACT_APP_CLINIC_PANEL_URL}/aftercare_confirmation/${patientId}/${treatmentPlanId}`;

        this.subscriptions.push(
            sendAfterCareToPatientAPI(
                this.props.authToken,
                treatmentPlanId,
                patientEmail,
                returnUrl
            ).pipe(
                tap(() => this.setState({patientInvitedModalShown: true})),
                catchError((error: any) => {
                    return of(this.alertManager.handleApiError(error));
                }
            )
        ).subscribe());
    };

    private getRecommendationOccurrence(treatmentPointType: EventType) {
        if (!this.props.retrieved || !this.props.retrieved.treatmentPoints) {
            return null;
        }
        const map = this.props.retrieved.treatmentPoints
            .filter((item: {[key: string]: any}) => item.type === treatmentPointType)
            .reduce((acc: any, e: any) => {
                let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                if (this.props.clinicTimezone) {
                    tz = this.props.clinicTimezone;
                }
                let localDate = new Date(e.treatmentPointStartsAt).toLocaleString('en-US', { timeZone: tz });
                let convertedDate = moment(localDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                return acc.set(
                    convertedDate.split('T')[0].replace(/-0/g, '-'),
                    (acc.get(convertedDate.split('T')[0].replace(/-0/g, '-')) || 0) + 1
                )
            }, new Map());

       return [...map.entries()];
    }

    private getTreatmentTypes() {
        return getTreatmentTypesAPI().pipe(
            tap((response: {[key: string]: any}) => {
                let treatmentTypes: {[key: string]: any} = [];
                let options: IMultiselectOption[] = [];

                if (!this.props.clinicTreatmentCategories) {
                    return;
                }
                this.props.clinicTreatmentCategories.map((category) => {
                    return treatmentTypes =  response['hydra:member'].filter((type: {[key: string]: any}) => {
                        return type.treatmentCategory.id === category.id
                    })
                });

                treatmentTypes.map((type: any) => {
                    let option: IMultiselectOption = {
                        value: type.id,
                        label: type.name
                    };

                    options.push(option);
                    this.setState({treatmentMultiselectOptions: options})
                });
            })
        );
    }

    private setTreatmentTypesMultiselectOptions() {
        if (!this.state.treatmentMultiselectOptions) {
            return;
        }

        treatmentPlanDetailsFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'aftercareTreatmentType') {
                        control.controls[key].multiselectOptions = this.state.treatmentMultiselectOptions;
                    }
                });
            }

            return control;
        });

        this.setState({treatmentPlanDetailsFormConfig: treatmentPlanDetailsFormConfig});
    }

    private toggleDeleteModal = (recommendation: {[key: string]: any} | null) => {
        if  (this.state.deleteEventModalShown) {
            this.setState({
                deleteEventModalShown: !this.state.deleteEventModalShown,
                selectedRecommendation: null
            })
        } else {
            this.setState({
                deleteEventModalShown: !this.state.deleteEventModalShown,
                selectedRecommendation: recommendation
            })
        }
    };

    private togglePatientInvitedModal = () => {
        this.setState({
            patientInvitedModalShown: !this.state.patientInvitedModalShown,
        });

        this.props.retrieve(`treatment_plans/${this.props.retrieved.id}`, this.props.authToken);
    };
}

export default connect(
    (state: RootState) => ({
        retrieved: retrievedAfterCareItemSelector(state),
        error: afterCareItemErrorSelector(state),
        loading: afterCareItemLoadingSelector(state),
        eventSource: afterCareItemEventSourceSelector(state),
        authToken: authTokenSelector(state),
        clinicTreatmentCategories: clinicTreatmentCategoriesSelector(state),
        clinicTimezone: clinicTimezoneSelector(state)
    }),
    {
        retrieve,
        reset
    }
)(TreatmentPlanView);
