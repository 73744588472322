import {FormControlType, InputType, ValidationRules} from "common-web";

export const inviteNewPatientControls = [
    {
        controlType: 'group',
        key: 'name_details',
        class: 'row',
        controls: {
            firstName: {
                controlType: 'control',
                defaultValue: '',
                formControlType: FormControlType.INPUT,
                validationRules: [
                    { name: ValidationRules.IS_REQUIRED },
                ],
                placeholder: 'First Name',
                label: 'First Name',
                type: InputType.TEXT,
                hostClass: 'col-xl-6',
            },
            lastName: {
                controlType: 'control',
                defaultValue: '',
                formControlType: FormControlType.INPUT,
                validationRules: [
                    { name: ValidationRules.IS_REQUIRED },
                ],
                placeholder: 'Last Name',
                label: 'Last Name',
                type: InputType.TEXT,
                hostClass: 'col-xl-6',
            }
        }
    },
    {
        controlType: 'group',
        key: 'email',
        class: 'row',
        controls: {
            email: {
                controlType: 'control',
                defaultValue: '',
                formControlType: FormControlType.INPUT,
                validationRules: [
                    { name: ValidationRules.IS_REQUIRED },
                    { name: ValidationRules.IS_EMAIL }
                ],
                placeholder: 'Email',
                label: 'Email',
                hostClass: 'col-xl-12',
                type: InputType.EMAIL,
            }
        }
    }
];
