import React from "react";
import { connect } from "react-redux";
import {authTokenSelector, Loader, LoaderType, Translation} from "common-web";
import styles from "./styles.module.scss";
import SubscriptionPlansHistory from "./SubscriptionPlansHistory";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import {catchError, map} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {getSubscriptionPlansAPI} from "../../../api/getSubscriptionPlans";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {getPaymentStatusAPI} from "../../../api/getPaymentStatus";
import {RootState} from "../../../store/reducers";

interface ISubscriptionPlansConnectedProps {
    readonly authToken: string | null;
}

interface ISubscriptionPlansExternalProps {
    clinicSubscriptions: {[key: string]: any}[];
    activeSubscription: {[key: string]: any} | null;
    proposedSubscription: {[key: string]: any}[];
    paidSubscription: {[key: string]: any} | null;
    calendarModuleConfig: {[key: string]: any} | null;
}

interface ISubscriptionPlansProps extends
    ISubscriptionPlansConnectedProps,
    ISubscriptionPlansExternalProps {}

interface ISubscriptionPlansState {
    isLoading: boolean;
    isHistoryShown: boolean;
    shouldUpdatePaymentStatus: boolean;
    hostedInvoiceUrl: string | null;
    subscriptionPlans: {[key: string]: any} | null;
    activeSubscription: {[key: string]: any} | null;
    paymentStatus: {[key: string]: any} | null;
}

class SubscriptionPlans extends React.Component<ISubscriptionPlansProps, ISubscriptionPlansState> {
    private subscriptions: Subscription[] = [];
    private paymentStatusInterval: NodeJS.Timeout | null = null;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: ISubscriptionPlansProps) {
        super(props);

        this.state = {
            isLoading: true,
            isHistoryShown: false,
            shouldUpdatePaymentStatus: true,
            hostedInvoiceUrl: null,
            subscriptionPlans: null,
            activeSubscription: null,
            paymentStatus: null,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getSubscriptionPlans();
        this.startPaymentStatusInterval();
    }

    componentDidUpdate(
        prevProps: Readonly<ISubscriptionPlansProps>,
        prevState: Readonly<ISubscriptionPlansState>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.stopPaymentStatusInterval();
    }

    render() {
        return (
            <React.Fragment>
                {this.state.hostedInvoiceUrl ? (
                    <div className={styles.paymentWarningContainer}>
                        <p className={styles.description}>
                            <Translation text={'billings.subscriptions.paymentStatus'}/>
                        </p>
                        <button className="btn btn-primary mr-5"
                                onClick={() => this.redirectToPaymentStatus()}>
                            <Translation text={'billings.subscriptions.checkPaymentStatus'}/>
                        </button>
                    </div>
                ) : null}

                <div className={styles.plans}>
                    {this.state.subscriptionPlans ? this.state.subscriptionPlans.map((plan: {[key: string]: any}) => {
                        return (
                            <SubscriptionPlanCard key={plan.id}
                                                  subscriptionPlan={plan}
                                                  currentPlan={this.state.activeSubscription}
                                                  updateSubscriptionPlanStatus={() => this.startPaymentStatusInterval(true)}
                                                  calendarModuleConfig={this.props.calendarModuleConfig}
                            />
                        )
                    }) : <p><Translation text={'billings.subscriptions.noData'} /></p>}

                    {this.props.proposedSubscription.length ?
                        (<div className={styles.proposedPlan}>
                            <SubscriptionPlanCard key={this.props.proposedSubscription[0].id}
                                                  subscriptionPlan={this.props.proposedSubscription[0]}
                                                  currentPlan={this.state.activeSubscription}
                                                  updateSubscriptionPlanStatus={() => this.startPaymentStatusInterval(true)}
                                                  calendarModuleConfig={this.props.calendarModuleConfig}/>
                            <span className={styles.proposedPlanLabel}>Proposed Plan</span>
                        </div>) : null
                    }

                    <Loader showLocalLoader={this.state.isLoading} type={LoaderType.Local}/>
                </div>

                <button className={styles.subscriptionBtn}
                        onClick={() => {this.setState({isHistoryShown: !this.state.isHistoryShown})}}>
                    <Translation text={'billings.subscriptions.subscriptionHistory'} />
                    {this.state.isHistoryShown ?
                        <span className="feather icon-chevron-down"/> :
                        <span className="feather icon-chevron-right"/>
                    }
                </button>

                {this.state.isHistoryShown ?
                    (<SubscriptionPlansHistory clinicSubscriptions={this.props.clinicSubscriptions}
                                               paidSubscription={this.state.paymentStatus}/>) :
                    null}
            </React.Fragment>
        );
    }

    private getSubscriptionPlans = () => {
        return this.subscriptions.push(getSubscriptionPlansAPI().pipe(
            map((response: any) => {
                if (response && response['hydra:member'] && response['hydra:member'].length) {
                    let activeSubscription;
                    let plans = response['hydra:member'].filter((plan: {[key: string]: any}) => plan.active);
                    plans.sort((a: {[key: string]: any}, b: {[key: string]: any}) => a.itemOrder - b.itemOrder);
                    if (!this.props.activeSubscription) {
                        activeSubscription = plans.find((plan: {[key: string]: any}) => plan.subscriptionDetails.price.amount === "0");
                    // } else if (!this.props.activeSubscription &&
                    //     (this.props.proposedSubscription as any).length) {
                    //     activeSubscription = this.props.proposedSubscription[0];
                    } else {
                        activeSubscription = this.props.activeSubscription;
                    }

                    if (plans.length > 3) {
                        plans = plans.slice(0, 3);
                    }

                    this.setState({
                        subscriptionPlans: plans,
                        isLoading: false,
                        activeSubscription: activeSubscription
                    });
                }
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager.handleApiError(error);
                return of();
            })
        ).subscribe());
    }

    private getSubscriptionPaymentStatus = (shouldUpdateRequest?: boolean) => {
        const { paidSubscription, authToken } = this.props;

        if (!paidSubscription || !authToken) {
            return;
        }

        return this.subscriptions.push(getPaymentStatusAPI(paidSubscription.id, authToken).pipe(
            map((response: any) => {
                const currentPayment = response?.currentSubscriptionEntry?.payment,
                    upcomingPayment = response?.upcomingSubscriptionEntry?.payment;

                const paymentToCheck = upcomingPayment ? upcomingPayment : currentPayment,
                    vendorData = paymentToCheck?.vendorData,
                    hasPaidAt = paymentToCheck?.paidAt;


                if (hasPaidAt && shouldUpdateRequest) {
                    this.alertManager.addAlert('Your subscription plan has been successfully updated');
                }

                if ((!paymentToCheck && !shouldUpdateRequest) || hasPaidAt) {
                    this.setState({
                        shouldUpdatePaymentStatus: false,
                        hostedInvoiceUrl: null,
                        paymentStatus: null
                    });
                } else if (!hasPaidAt && !vendorData?.hostedInvoiceUrl) {
                    this.setState({
                        shouldUpdatePaymentStatus: true,
                        hostedInvoiceUrl: null
                    });
                } else if (!hasPaidAt && vendorData?.hostedInvoiceUrl) {
                    this.setState({
                        shouldUpdatePaymentStatus: true,
                        hostedInvoiceUrl: vendorData.hostedInvoiceUrl,
                        paymentStatus: paymentToCheck
                    });
                }
            }),
            catchError((error: any) => {
                this.alertManager.handleApiError(error);
                return of();
            })
        ).subscribe());
    }

    private startPaymentStatusInterval = (shouldRequestUpdate?: boolean) => {
        this.getSubscriptionPaymentStatus(shouldRequestUpdate);
        this.paymentStatusInterval = setInterval(() => {
            if (this.state.shouldUpdatePaymentStatus) {
                this.getSubscriptionPaymentStatus(shouldRequestUpdate);
            } else {
                this.stopPaymentStatusInterval();
            }
        }, 1 * 1000);
    };

    private stopPaymentStatusInterval = () => {
        if (this.paymentStatusInterval) {
            clearInterval(this.paymentStatusInterval);
            this.paymentStatusInterval = null;
        }
    };

    private redirectToPaymentStatus = () => {
        if (!this.state.hostedInvoiceUrl) {
            return;
        }
        return window.open(this.state.hostedInvoiceUrl, '_blank', 'noopener, noreferrer');
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {}
)(SubscriptionPlans);
