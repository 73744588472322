import { fetch } from '../../utils/dataAccess';

export function error(error: any) {
  return { type: 'RECOMMENDATIONDEFINITION_DELETE_ERROR', error };
}

export function loading(loading: boolean) {
  return { type: 'RECOMMENDATIONDEFINITION_DELETE_LOADING', loading };
}

export function success(deleted: any) {
  return { type: 'RECOMMENDATIONDEFINITION_DELETE_SUCCESS', deleted };
}

export function del(item: any, authToken: string) {
  return (dispatch: any) => {
    dispatch(loading(true));

    return fetch(item['@id'], authToken, { method: 'DELETE' })
      .then(() => {
        dispatch(loading(false));
        dispatch(success(item));
      })
      .catch((e: any) => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}
