import { Observable } from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterClinicAPI} from "./provider/clinicHunterClinic";

export function getAfterCareRecommendationAPI(
    accessToken: string,
    recommendationId: string
): Observable<any> {
    return clinicHunterClinicAPI.get(
        `treatment_points/${recommendationId}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        },
    );
}
