import React, {CSSProperties} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import styles from './styles.module.scss';
import {clinicIdSelector, CustomCard, Translation} from 'common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import ScriptTag from 'react-script-tag';
import BannerWidgetComponent from "./BannerWidgetComponent";

declare global {
    namespace JSX {

        export interface IntrinsicElements {
            'x-axa-widget': IAxaWidgetProps;
        }

        interface IAxaWidgetProps
            extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>,
                HTMLElement> {
            clinicId: string;
            baseUrl: string;
            title?: string;
            button?: string;
            readonly style?: CSSProperties;
            readonly isInitialPositionDisabled?: boolean;
        }
    }
}

interface IConnectedInsurancesWidgetProps {
    readonly clinicId: string;
}

interface IExternalInsurancesWidgetProps {
}

interface IInsurancesWidgetProps extends IConnectedInsurancesWidgetProps,
    IExternalInsurancesWidgetProps,
    RouteComponentProps {
}

interface IInsuranceWidgetState {
    clinicId: string;
}

class InsurancesWidget extends React.Component<IInsurancesWidgetProps, IInsuranceWidgetState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    constructor(props: IInsurancesWidgetProps) {
        super(props);

        this.state = {
            clinicId: this.props.clinicId
        };

        fixInjectedProperties(this);
    }

    get widget() {
        return `<x-axa-widget clinicId="${this.state.clinicId}"
              baseUrl="${process.env.REACT_APP_WIDGET_URL}"
              style="height: 280px; min-width: 100%;"></x-axa-widget>
              <script src="${process.env.REACT_APP_WIDGET_URL}/axa-widget.js"></script>`;
    }

    get clinicLink() {
        return `${process.env.REACT_APP_INSURANCE_URL}?cid=${this.state.clinicId}`;
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title"><Translation text={'inquiries.clinicWidget.title'}/></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CustomCard>
                            <CustomCard.Body>
                                <div className="row">
                                    <div className="col-xl-8">
                                        <div className={styles.widgetItem}>
                                            <h4><Translation text={'inquiries.clinicWidget.tutorial.title'}/></h4>
                                            <ol className={styles.tutorialList}>
                                                <li><Translation text={'inquiries.clinicWidget.tutorial.1'}/></li>
                                                <li><Translation text={'inquiries.clinicWidget.tutorial.2'}/></li>
                                                <li><Translation text={'inquiries.clinicWidget.tutorial.3'}/></li>
                                            </ol>
                                        </div>

                                        <div className={styles.widgetItem}>
                                            <h4><Translation text={'inquiries.clinicWidget.copyLink.title'}/></h4>
                                            <div className={styles.snippet}
                                                 onClick={() => this.copyToClipboard(this.clinicLink, 'inquiries.clinicWidget.copyLink.linkCopied')}>
                                                <div>{this.clinicLink}</div>

                                                <div className={styles.snippetIconContainer}>
                                                    <i className="feather icon-copy"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.widgetItem}>
                                            <h4><Translation text={'inquiries.clinicWidget.copyWidget.title'}/></h4>
                                            <div className={styles.snippet}
                                                 onClick={() => this.copyToClipboard(this.widget, 'inquiries.clinicWidget.copyWidget.widgetCopied')}>
                                                <div>{this.widget}</div>

                                                <div className={styles.snippetIconContainer}>
                                                    <i className="feather icon-copy"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4">
                                        <div className={styles.widgetItem}>
                                            <h4><Translation text={'inquiries.clinicWidget.widgetPreview.title'}/></h4>
                                            <div style={{minHeight: '260px', minWidth: '100%'}}>
                                                <x-axa-widget clinicId={`${this.state.clinicId}`}
                                                              baseUrl={`${process.env.REACT_APP_WIDGET_URL}`}
                                                              style={{height: '100%', width: '100%'}} />
                                                <ScriptTag type="text/javascript" src={`${process.env.REACT_APP_WIDGET_URL}/axa-widget.js`}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <BannerWidgetComponent clinicId={this.state.clinicId} copyToClipboard={this.copyToClipboard}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private copyToClipboard = (content: string, message: string) => {
        navigator.clipboard.writeText(content);
        this.alertManagerService.addAlert(message);
    }
}

export default connect(
    (state: RootState) => ({
        clinicId: clinicIdSelector(state),
    }),
    {}
)(InsurancesWidget);
