import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterCalendarAPI} from "./provider/clinicHunterCalendar";


export function confirmTreatmentPlanAPI(
    token: string,
    treatmentPlanId: string
): Observable<any> {
    const payload: any = {
        token: token,
        treatmentPlanId: treatmentPlanId
    };

    return clinicHunterCalendarAPI.post(
        "treatment_plans/confirm",
        payload,
        new RestQueryParams(),
    );
}

