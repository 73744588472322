import React from 'react';
import styles from './styles.module.scss';
import {
    authTokenSelector,
    availableConsultationSlotsSelector,
    clinicIdSelector,
    clinicSelector,
    consultationDataLoadingSelector,
    consultationSlotsToBlockSelector,
    Form,
    getAvailableConsultationSlots,
    IBlockerEvent,
    IFormConfig,
    Loader,
    setSlotsToBlock,
    Toast,
    Translation,
} from 'common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../../../store/reducers';
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {calendarFormConfig} from "./calendarFormConfig";
import moment from "moment";
import {Subscription} from "rxjs";
import {getTime, getTimezoneOffset, getUTCTime} from "../../../../../utils/dateTransformUtils";
import {calendarDetailsSelector, calendarIdSelector} from "../../../../../store/selectors/calendarSelectors";
import CalendarCreationService from "../../../../../service/calendarCreationService";
import {IAlertManagerService} from "../../../../../service/alertManagerService";
import {ICalendarDetails} from "../../../../../store/reducers/calendarSlice";

interface IConnectedMonthCalendarProps {
    readonly availableConsultationSlots: { [key: string]: any } | null;
    readonly consultationIsLoading: boolean;
    readonly slotsToBlock: typeof IBlockerEvent[];
    readonly calendar: { [key: string]: any } | null;
    readonly calendarId: string;
    readonly clinic: any;
    readonly clinicId: string;
    readonly authToken: string;
    readonly calendarDetails: ICalendarDetails | null;
    readonly getAvailableConsultationSlots: typeof getAvailableConsultationSlots;
    readonly setSlotsToBlock: typeof setSlotsToBlock;
}

interface IMonthCalendarProps extends IConnectedMonthCalendarProps {
    calendar: {[key: string]: any};
}

interface IMonthCalendarState {
    clinicId: string;
    calendarId: string;
    formConfig: typeof IFormConfig;
    value: any;
    isLoading: boolean;
    selectedDate: Date | null;
    availableDates: Date[] | null;
    currentMonth: number;
    timeSlots: { [key: string]: any }[] | null;
    timezone: string | null;
}

class MonthCalendar extends React.Component<IMonthCalendarProps, IMonthCalendarState> {
    @lazyInject('CalendarCreationService') private calendarCreationService: CalendarCreationService;
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    readonly subscriptions: Subscription[] = [];

    constructor(props: IMonthCalendarProps) {
        super(props);

        this.state = {
            clinicId: '',
            calendarId: '',
            formConfig: calendarFormConfig,
            value: null,
            isLoading: false,
            selectedDate: null,
            availableDates: null,
            timeSlots: null,
            currentMonth: new Date().getMonth() + 1,
            timezone: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        if (this.props.clinicId !== null && this.props.calendarId !== null) {
            this.setState({clinicId: this.props.clinicId}, () => {
                this.getCurrentMonthAvailableSlots(this.props.calendarId);
            });
        }

        if (this.props.calendarId !== null) {
            this.setState({calendarId: this.props.calendarId});
        }

        if (this.props.calendarDetails) {
            this.setState({timezone: this.props.calendarDetails.timezone});
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IMonthCalendarProps>,
        prevState: Readonly<IMonthCalendarState>,
        snapshot?: any
    ): void {
        if (this.props.availableConsultationSlots !== prevProps.availableConsultationSlots) {
            const availableDates = this.calendarCreationService.getMonthAvailableDates(
                this.props.availableConsultationSlots,
                this.state.currentMonth
            );
            let config = calendarFormConfig;
            config.controls[0].controls.date.availableDates = availableDates;
            this.setState({
                availableDates: availableDates,
                formConfig: config
            });
        }
        if (this.props.calendarDetails && this.props.calendarDetails !== prevProps.calendarDetails) {
            this.setState({timezone: this.props.calendarDetails.timezone})
        }
        if (this.props.consultationIsLoading !== prevProps.consultationIsLoading) {
            this.setState({isLoading: this.props.consultationIsLoading});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.calendarContainer}>
                <div className={styles.calendarWrapper}>
                    <h4><Translation text={'calendar.calendarTimeSlots.tabs.calendar.editCalendar'} /></h4>
                    <div className={styles.formWrapper}>
                        <Form config={this.state.formConfig}
                              controlName={'calendarForm'}
                              onValueStateChange={this.onValueStateChange}
                              value={this.state.value}/>
                    </div>
                </div>
                <Toast/>
                <Loader showLocalLoader={this.state.isLoading}/>
            </div>
        )
    }

    private onValueStateChange = (controlName: string, value: any) => {
        Object.keys(value).forEach(key => {
                if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'month') {
                    this.onMonthChange(value[key].value);
                }

                if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'date') {
                    this.onDayChange(value[key].value);
                }
                if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'checkboxSelectedDate' &&
                    this.state.calendarId && this.state.timeSlots) {
                    this.onTimeSlotChange(value[key]);
                }

                if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'selectedDate') {
                    this.setState({selectedDate: value.date.value});
                }
            }
        )
    };

    private onMonthChange = (monthValue: any) => {
        let currentMonth = monthValue.getMonth() + 1,
            firstDay = moment(new Date(monthValue.getFullYear(), monthValue.getMonth(), 1)).toISOString(true),
            lastDay = moment(new Date(monthValue.getFullYear(), monthValue.getMonth() + 1, 0)).toISOString(true);

        let config = calendarFormConfig;
        config.controls[0].controls.date.availableTimeSlots = null;
        this.setState({
            currentMonth: currentMonth,
            timeSlots: null,
            selectedDate: null,
            formConfig: config
        });

        this.props.getAvailableConsultationSlots(firstDay, lastDay, this.state.calendarId, new Date().getMonth() + 1)
    };

    private onDayChange = (dayValue: any) => {
        let year = dayValue.getFullYear(),
            month = dayValue.getMonth() + 1,
            day = dayValue.getDate(),
            timeSlots: { [key: string]: any }[] = [];
        if (this.props.availableConsultationSlots) {
            let time = this.props.availableConsultationSlots[year][month][day];

            Object.keys(time).forEach((key: any) => {
                timeSlots.push({
                    value: this.props.calendarDetails?.timezone ?
                        moment(new Date(time[key].starts_at)).utcOffset(getTimezoneOffset(this.props.calendarDetails.timezone)).format('YYYY-MM-DD HH:mm') :
                        time[key].starts_at,
                    endsAt: this.props.calendarDetails?.timezone ?
                        moment(new Date(time[key].ends_at)).utcOffset(getTimezoneOffset(this.props.calendarDetails.timezone)).format('YYYY-MM-DD HH:mm') :
                        time[key].ends_at,
                    displayValue: this.props.calendarDetails?.timezone ?
                        getTime(moment(new Date(time[key].starts_at)).utcOffset(getTimezoneOffset(this.props.calendarDetails.timezone)).format('YYYY-MM-DD HH:mm')) :
                        getUTCTime(time[key].starts_at),
                    isFree: time[key].is_free
                })
            });
            let config = calendarFormConfig;
            config.controls[0].controls.date.availableTimeSlots = timeSlots;
            return this.setState({
                timeSlots: timeSlots,
                formConfig: config
            });
        }
    };

    private onTimeSlotChange = (timeSlotValue: any) => {
        if (this.props.availableConsultationSlots) {
            let year = new Date(timeSlotValue.value).getFullYear(),
                month = new Date(timeSlotValue.value).getMonth() + 1,
                day = new Date(timeSlotValue.value).getDate(),
                dayTimeslots = this.props.availableConsultationSlots[year][month][day],
                timeSlotsToChange = Object.assign({}, dayTimeslots);

            this.setState({timeSlots: timeSlotsToChange});
            const slotsToBlock: typeof IBlockerEvent[] = [...this.props.slotsToBlock];

            if (this.state.timeSlots !== null) {
                const timeSlotsUpdated = [...this.state.timeSlots];
                let updatedSlotsToBlock: typeof IBlockerEvent[];

                timeSlotsUpdated.forEach(timeSlot => {
                    if (timeSlot.value === timeSlotValue.value) {
                        timeSlot.isFree = !timeSlot.isFree;
                        const payloadSlot: typeof IBlockerEvent = {
                            startsAt: timeSlot.value,
                            endsAt: timeSlot.endsAt,
                            isFree: timeSlot.isFree
                        };
                        slotsToBlock.push(payloadSlot);
                        if (this.props.slotsToBlock.filter(slot => slot.startsAt === timeSlotValue.value).length > 0) {
                            updatedSlotsToBlock = slotsToBlock.filter(slot => slot.startsAt !== timeSlotValue.value);
                        } else {
                            updatedSlotsToBlock = [...slotsToBlock];
                        }
                        this.props.setSlotsToBlock(updatedSlotsToBlock);

                    }
                    return timeSlot;
                });
                this.setState({timeSlots: timeSlotsUpdated});
            }
        }
    };

    private getCurrentMonthAvailableSlots(calendarId: string) {
        const date = new Date(),
            month = new Date().getMonth() + 1,
            firstDay = moment(new Date(date.getFullYear(), date.getMonth(), 1)).toISOString(true),
            lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).toISOString(true);
        this.props.getAvailableConsultationSlots(firstDay, lastDay, calendarId, month);
    }
}

export default connect(
    (state: RootState) => ({
        availableConsultationSlots: availableConsultationSlotsSelector(state),
        consultationIsLoading: consultationDataLoadingSelector(state),
        calendarDetails: calendarDetailsSelector(state),
        slotsToBlock: consultationSlotsToBlockSelector(state),
        calendarId: calendarIdSelector(state),
        clinic: clinicSelector(state),
        clinicId: clinicIdSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        setSlotsToBlock,
        getAvailableConsultationSlots,
    }
)(MonthCalendar);
