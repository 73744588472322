import { Observable } from "rxjs";
import {RestQueryParams} from "common-web";
import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";


export function stripeDashboardRedirectAPI(
    authToken: string,
    paymentAccountId: string,
    redirectUrl: string
): Observable<any> {
    return clinicHunterInquiryAPI.put(
        `payment_accounts/${paymentAccountId}/generate_stripe_login_link`,
        {
            redirectUrl: redirectUrl
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        },
    );
}
