import React from 'react';
import {authTokenSelector, BasicModal, CustomCard, CustomCardType, Translation} from "common-web";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {of, Subscription} from "rxjs";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {catchError, tap} from "rxjs/operators";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {deleteCalendarAPI} from "../../../../api/deleteCalendarAPI";
import {list} from "../../../../actions/calendar/list";

interface IConnectedDeleteCalendarModalProps {
    readonly authToken: string;
    readonly list: any;
}

export interface IDeleteCalendarModalProps extends IConnectedDeleteCalendarModalProps,
    RouteComponentProps {
    readonly deleteModalShown: boolean;
    readonly calendarId: string | null;
    readonly toggleDeleteModal: (calendarId: string | null) => void;
    readonly redirectToList?: boolean;
}

interface IDeleteCalendarModalState {
    isLoading: boolean;
}

class DeleteCalendarModal extends React.Component<IDeleteCalendarModalProps, IDeleteCalendarModalState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {}

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {
        if (null !== this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.deleteModalShown}
                        closeModal={() => this.props.toggleDeleteModal(null)}>
                <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <h4><Translation text={'calendar.modals.confirmDeleteModal.title'}/></h4>
                            <button className="btn-modal-close" onClick={() => this.props.toggleDeleteModal(null)}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>

                        <div className="modal-body consultation-cancellation-modal">
                            <Translation text={'calendar.modals.confirmDeleteModal.description'}/>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-theme"
                                    type="button"
                                    onClick={() => this.deleteCalendar()}>
                                <Translation text={'calendar.modals.confirmDeleteModal.deleteBtn'}/>
                            </button>
                        </div>
                    </CustomCard.Body>
                </CustomCard>
            </BasicModal>
        );
    }

    private deleteCalendar = (): void => {
        if (this.props.calendarId !== null && this.props.authToken !== null) {
            this.setState({isLoading: true});
            this.subscriptions.push(
                deleteCalendarAPI(this.props.calendarId, this.props.authToken).pipe(
                    tap(() => {
                        this.alertManagerService.addAlert('calendar.modals.confirmDeleteModal.success');
                        if (this.props.redirectToList === true) {
                            const path = [''];
                            path.push(`dashboard/consultations/widget`);
                            this.props.history.push(path.join('/'));
                        } else {
                            this.props.list('calendars', this.props.authToken);
                        }
                        this.props.toggleDeleteModal(null);
                    }),
                    catchError((error: any) => {
                        this.props.toggleDeleteModal(null);
                        return of(this.alertManagerService.handleApiError(error))
                    })
                ).subscribe()
            );
        }
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        list
    }
)(withRouter(DeleteCalendarModal));
