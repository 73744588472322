import React from "react";
import {connect} from "react-redux";
import {BasicModal, CustomCard, CustomCardType, Translation} from "common-web";
import styles from "./styles.module.scss";
import ReactHtmlParser from 'react-html-parser';
import ChangeSubscriptionPlan from "../ChangeSubscriptionPlanModal";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {AlertType, IAlertManagerService} from "../../../../service/alertManagerService";

export enum PlanButtonType {
    CURRENT = 'currentPlan',
    DOWNGRADE = 'downgrade',
    UPGRADE = 'upgrade'
}

interface ISubscriptionPlanCardConnectedProps {}

interface ISubscriptionPlanCardExternalProps {
    subscriptionPlan: {[key: string]: any};
    currentPlan: {[key: string]: any} | null;
    calendarModuleConfig: {[key: string]: any} | null;
    updateSubscriptionPlanStatus: (shouldUpdateRequest?: boolean) => void;
}

interface ISubscriptionPlanCardProps extends
    RouteComponentProps,
    ISubscriptionPlanCardConnectedProps,
    ISubscriptionPlanCardExternalProps {}

interface ISubscriptionPlanCardState {
    isLoading: boolean;
    isModalShown: boolean;
    isCalendarLimitModalShown: boolean;
    nextPlan: {[key: string]: any} | null;
    currentCalendarLimit: number;
    upcomingCalendarLimit: number;
}

class SubscriptionPlanCard extends React.Component<ISubscriptionPlanCardProps, ISubscriptionPlanCardState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: ISubscriptionPlanCardProps) {
        super(props);

        this.state = {
            isLoading: false,
            isModalShown: false,
            isCalendarLimitModalShown: false,
            nextPlan: null,
            currentCalendarLimit: 0,
            upcomingCalendarLimit: 0
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {}

    componentDidUpdate(
        prevProps: Readonly<ISubscriptionPlanCardProps>,
        prevState: Readonly<ISubscriptionPlanCardState>,
        snapshot?: any
    ): void {}

    render() {
        if (!this.props.subscriptionPlan) {
            return null;
        }

        const plan = this.props.subscriptionPlan,
            planDetails = plan.subscriptionDetails,
            price = planDetails && planDetails.price && planDetails.price.amount > 0 ? planDetails.price.amount / 100 : "Free",
            currency = planDetails.price.amount > 0 ?
                `${planDetails.price.currency.code.toLowerCase()}/month` : null,
            isCurrentPlan = this.props.currentPlan && this.props.currentPlan.subscriptionDefinition ?
                plan.id === this.props.currentPlan.subscriptionDefinition.id :
                this.props.currentPlan && plan.id === this.props.currentPlan.id;

        return (
            <React.Fragment>
            <div className={`${styles.planContainer} ${isCurrentPlan ? styles.current : ''}`}>
                <div className={styles.header}>
                    <h4 className={styles.title}>{planDetails.name}</h4>
                    <div className={styles.price}>
                        <span className={styles.amount}>
                            {price}
                        </span>
                        <span className={styles.currency}>
                            {currency}
                        </span>
                    </div>
                </div>

                <div className={styles.actions}>
                    {this.renderPlanBtn()}
                </div>

                <div className={styles.description}>
                    { ReactHtmlParser(planDetails.description) }
                </div>
            </div>

                {this.state.isModalShown &&
                    (<ChangeSubscriptionPlan toggleModal={this.toggleModal}
                                             isModalShown={this.state.isModalShown}
                                             currentPlan={this.props.currentPlan}
                                             updateSubscriptionPlanStatus={this.props.updateSubscriptionPlanStatus}
                                             nextPlan={this.props.subscriptionPlan}
                    />)
                }

                {this.renderCalendarLimitModal()}

            </React.Fragment>
        );
    }

    private renderPlanBtn = () => {
        if (this.props.subscriptionPlan === null ||
            this.props.subscriptionPlan === undefined
            //this.props.currentPlan === null ||
            // this.props.currentPlan === undefined
        ) {
            return;
        }

        let planBtnType = PlanButtonType.UPGRADE;


        const planDetails = this.props.subscriptionPlan && this.props.subscriptionPlan.subscriptionDetails,
            planPrice = Number(planDetails.price.amount),
            planId = this.props.subscriptionPlan.id;
        let calendarsUsed = this.props.calendarModuleConfig ? this.props.calendarModuleConfig.moduleParameters.calendarsUsed : 0,
            upcomingCalendars = planDetails.subscriptionRestrictions.calendarCountLimit,
            calendarExceeded =
            this.props.calendarModuleConfig && planDetails &&
            this.props.calendarModuleConfig.moduleParameters.calendarsUsed > planDetails.subscriptionRestrictions.calendarCountLimit;
        if (this.props.currentPlan !== null && this.props.currentPlan !== undefined) {
            const currentPlanDetails = (this.props.currentPlan && this.props.currentPlan.subscriptionDefinition) ?
                this.props.currentPlan.subscriptionDefinition.subscriptionDetails :
                (this.props.currentPlan && this.props.currentPlan.subscriptionDetails),
                currentPlanPrice = Number(currentPlanDetails.price.amount),
                currentPlanId = (this.props.currentPlan && this.props.currentPlan.subscriptionDefinition) ?
                    this.props.currentPlan.subscriptionDefinition.id : (this.props.currentPlan && this.props.currentPlan.id);

            planBtnType = (planPrice === currentPlanPrice && currentPlanId === planId) ?
                PlanButtonType.CURRENT : (planPrice >= currentPlanPrice ? PlanButtonType.UPGRADE : PlanButtonType.DOWNGRADE);
        }

        return(
            <button className={`${styles.btnAction} ${styles[planBtnType]}`}
                    onClick={() => {
                        calendarExceeded ?
                            this.toggleCalendarLimitModal(calendarsUsed, upcomingCalendars) :
                            this.toggleModal(planBtnType)
                    }}>
                <Translation text={`billings.subscriptions.${planBtnType}`}/>
            </button>
        )
    };

    private renderCalendarLimitModal = () => {
        let validUntil: string = '';
        if (this.props.currentPlan && this.props.currentPlan.validUntil) {
            validUntil = this.props.currentPlan.validUntil.split(/[T ]/i, 1)[0];
        }
        return (
            <BasicModal isModalShown={this.state.isCalendarLimitModalShown} closeModal={this.toggleCalendarLimitModal}>
                <CustomCard showLocalLoader={false} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <Translation text={'billings.subscriptions.changeSubscriptionModal.title'}/>
                            <button className="btn-modal-close" onClick={() => this.toggleCalendarLimitModal(0, 0)}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        <div className={styles.modalBody}>
                            <p className="mb-4">
                                <Translation text={'billings.subscriptions.calendarLimitsModal.description'}
                                             config={{
                                                 currentCalendarsNo: this.state.currentCalendarLimit,
                                                 upcomingCalendarsNo: this.state.upcomingCalendarLimit
                                             }}
                                />
                            </p>
                            <p>
                                <Translation text={'billings.subscriptions.calendarLimitsModal.warning'}
                                             config={{date: validUntil}} />
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-theme"
                                    onClick={() => this.props.history.push('/dashboard/consultations/widget')}>
                                <Translation text={'billings.subscriptions.calendarLimitsModal.reduceCalendars'}/>
                            </button>
                        </div>
                    </CustomCard.Body>
                </CustomCard>
            </BasicModal>
        )
    };

    private toggleCalendarLimitModal = (currentLimit: number, upcomingLimit: number) => {
        return this.setState({
            isCalendarLimitModalShown: !this.state.isCalendarLimitModalShown,
            currentCalendarLimit: currentLimit,
            upcomingCalendarLimit: upcomingLimit
        });
    };

    private toggleModal = (planType: PlanButtonType) => {
        if (planType === PlanButtonType.CURRENT) {
            return;
        }

        if (!this.props.currentPlan) {
            return this.alertManager.addAlert(
                'An error occurred while selecting a plan. Please contact the admin to resolve an issue.',
                AlertType.WARNING
            );
        }
        return this.setState({isModalShown: !this.state.isModalShown});
    };
}

export default connect(
    () => ({}),
    {}
)(withRouter(SubscriptionPlanCard));
