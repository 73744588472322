import { Observable } from "rxjs";
import {clinicHunterClinicAPI} from "./provider/clinicHunterClinic";
import {RestQueryParams} from "./base/queryParams";

export function updateClinicAPI(
    clinicId: string,
    accessToken: string,
    login: string,
    account: any,
    companyName: string,
    reference?: string,
    feePercent?: number,
    timezone?: string,
): Observable<any> {
    let payload = {
        login: login,
        account: account,
        companyName: companyName,
        reference: reference,
        feePercent: feePercent,
        timezone: timezone
    };

    return clinicHunterClinicAPI.put(
        `clinic/${clinicId}/update`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        },
    );
}
