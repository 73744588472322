import {injectable} from 'inversify';
import {Address} from '../store/reducers/accountSlice';

export enum UpdateCardType {
    PERSONAL = 'personal_data',
    ADDRESS = 'address_data',
    INVOICE = 'invoice_data',
    ERROR = 'error',
}

export interface IInvoiceAddress {
    taxId: string,
    addressLine1?: string,
    addressLine2?: string,
    addressLine3?: string,
    postcode?: string,
    city?: string,
    country?: string
}

export interface IUpdateAccountPayload {
    companyName: string,
    firstName: string | null,
    lastName: string | null,
    birthdate: string | null,
    phone: string | null,
    address: Address | null,
    invoiceAddress: IInvoiceAddress | null,
    residency: string | null,
    timezone: string | null
}

export interface IAccountPayloadMapperService {
    updateAccountFromCard: (accountData: any, formConfig: any, type: UpdateCardType) => { uploadAccountPayload: IUpdateAccountPayload };
}

@injectable()
class AccountPayloadMapperService implements IAccountPayloadMapperService {

    public updateAccountFromCard = (accountData: any, formData: any, type: UpdateCardType) => {
        let uploadAccountPayload: IUpdateAccountPayload = {
            companyName: accountData.companyName,
            firstName: accountData.firstName,
            lastName: accountData.lastName,
            birthdate: accountData.birthDate,
            phone: accountData.phone,
            address: accountData.addressData,
            invoiceAddress: accountData.invoiceData,
            residency: accountData.personalData.residency,
            timezone: accountData.timezone
        };

        switch (type) {
            case UpdateCardType.PERSONAL:
                uploadAccountPayload.companyName = formData.companyName;
                uploadAccountPayload.firstName = formData.firstName;
                uploadAccountPayload.lastName = formData.lastName;
                uploadAccountPayload.phone = formData.phone;
                uploadAccountPayload.timezone = formData.timezone
                break;

            case UpdateCardType.ADDRESS:
                uploadAccountPayload.companyName = formData.companyName;
                uploadAccountPayload.firstName = formData.firstName;
                uploadAccountPayload.lastName = formData.lastName;
                uploadAccountPayload.phone = formData.phone;
                uploadAccountPayload.address = {
                    addressLine1: formData.addressLine1,
                    addressLine2: formData.addressLine2,
                    addressLine3: formData.addressLine3,
                    city: formData.city,
                    country: formData.country,
                    postcode: formData.postcode
                };
                break;

            case UpdateCardType.INVOICE:
                uploadAccountPayload.companyName = formData.companyName;
                uploadAccountPayload.firstName = formData.firstName;
                uploadAccountPayload.lastName = formData.lastName;
                uploadAccountPayload.phone = formData.phone;
                uploadAccountPayload.invoiceAddress = {
                    taxId: formData.taxId,
                    addressLine1: formData.addressLine1,
                    addressLine2: formData.addressLine2,
                    addressLine3: formData.addressLine3,
                    city: formData.city,
                    country: formData.country,
                    postcode: formData.postcode
                };
                break;
        }
        return {uploadAccountPayload};
    };
}

export default AccountPayloadMapperService;
