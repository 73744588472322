import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../store/reducers";
import {BasicModal, CustomCard, CustomCardType, Translation} from "common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import styles from "./styles.module.scss";
import {accountSelector, paymentAccountsSelector} from "../../../store/selectors/accountSelectors";
import {PaymentAccount} from "../../../store/reducers/accountSlice";
import {PaymentServiceName} from "../../../api/buySubscriptionDefiniton";

interface IConnectedOnboardingModalProps {
    readonly paymentAccounts: PaymentAccount[];
    readonly account: {[key: string]: any};
}

interface IOnboardingModalProps extends IConnectedOnboardingModalProps,
    RouteComponentProps,
    WithTranslation {}

interface IOnboardingModalState {
    isOnboardingCompleted: boolean;
    isCardAttached: boolean;
    clinicOrigin: string | null;
}

class OnboardingModal extends React.Component<IOnboardingModalProps, IOnboardingModalState> {
    constructor(props: IOnboardingModalProps) {
        super(props);

        this.state = {
            isOnboardingCompleted: false,
            isCardAttached: false,
            clinicOrigin: null
        };
    }

    componentDidMount() {
        if (this.props.account && this.props.account.addressData) {
            this.setState({clinicOrigin: this.props.account.addressData.country})
        }

        const stripePaymentAccount = this.props.paymentAccounts
            .find(account => (account.paymentAccountVendorType === PaymentServiceName.STRIPE));
        if (stripePaymentAccount !== null &&
            stripePaymentAccount !== undefined
        ) {
            this.setState({
                isOnboardingCompleted: stripePaymentAccount.paymentAccountVendorData.chargesEnabled,
                isCardAttached: stripePaymentAccount.paymentAccountVendorData.customerPaymentMethodId !== null
            });
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IOnboardingModalProps>,
        prevState: Readonly<IOnboardingModalState>,
        snapshot?: any
    ): void {
        if (this.props.account !== prevProps.account) {
            this.setState({clinicOrigin: this.props.account.addressData.country})
        }
    }

    render() {
        const isModalShown = (!this.state.isOnboardingCompleted && this.state.clinicOrigin !== 'TR') ||
            (this.state.clinicOrigin === 'TR' && !this.state.isCardAttached);

        return (
            <React.Fragment>
                <BasicModal isModalShown={isModalShown}
                            isModalLocal={true}
                            closeModal={null}>
                    <CustomCard showLocalLoader={false} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            {this.renderModalContent()}
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private renderModalContent = () => {
        if (this.state.clinicOrigin === 'TR' && !this.state.isCardAttached) {
            return (
                <React.Fragment>
                    <div className="modal-header">
                        <p className={styles.title}>
                            <Translation text={'billings.paymentModal.title'}/>
                        </p>
                    </div>
                    <div className="modal-body">
                        <div className={styles.modalDescription}>
                            <p>
                                <Translation text={'billings.paymentModal.description'}/>
                            </p>
                        </div>
                        <div className={styles.btnContainer}>
                            <button className="btn btn-theme"
                                    onClick={() => this.props.history.push('/dashboard/billings')}>
                                <Translation text={'billings.paymentModal.proceed'}/>
                            </button>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        if (this.state.clinicOrigin !== 'TR' && !this.state.isOnboardingCompleted) {
            return (
                <React.Fragment>
                    <div className="modal-header">
                        <p className={styles.title}>
                            <Translation text={'billings.onboardingModal.title'}/>
                        </p>
                    </div>
                    <div className="modal-body">
                        <div className={styles.modalDescription}>
                            <p>
                                <Translation text={'billings.onboardingModal.description'}/>
                            </p>
                        </div>

                        <div className={styles.btnContainer}>
                            <button className="btn btn-theme"
                                    onClick={() => this.redirectToOnboarding()}>
                                <Translation text={'billings.onboardingModal.proceed'}/>
                            </button>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    };

    private redirectToOnboarding = () => {
        this.props.history.push(
            '/dashboard/billings',
            {selectedTab: 3}
            );
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        paymentAccounts: paymentAccountsSelector(state),
        account: accountSelector(state)
    }),
    {}
)(withRouter(OnboardingModal)));
