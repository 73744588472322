import {
    FormButtonType,
    FormControlType,
    IFormConfig,
    InputType,
    MultiSelectType,
    stripeCountryList,
    ValidationRules,
} from 'common-web';

export const registrationFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: 'auth-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'registration_email',
            controlType: 'group',
            class: 'form-row',
            controls: {
                companyName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Clinic Name',
                    isLabelHidden: true,
                    label: "Clinic Name",
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                },
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}}
                    ],
                    placeholder: 'example@email.com',
                    isLabelHidden: true,
                    label: 'Email address',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12'
                }
            }
        },
        {
            controlType: 'group',
            key: 'registration_password',
            class: 'form-row',
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {
                        accessors: [
                            (value: any) => value.password,
                            (value: any) => value.newPassword
                        ]
                    }
                }
            ],
            controls: {
                password: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: 'Password',
                    isLabelHidden: true,
                    label: 'Password',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12'
                },
                newPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: 'Repeat Password',
                    isLabelHidden: true,
                    label: 'Confirm your password',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12'
                }
            }
        },
        {
            controlType: 'group',
            key: 'country',
            class: 'form-row',
            controls: {
                country: {
                    hostClass: 'col-xl-12',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'Choose country',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: "Choose country",
                    isLabelHidden: true,
                    isComponentCustom: true,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: () => {
                        return stripeCountryList.map((country: any) => ({
                            value: country.value,
                            label: country.label,
                        }))
                    },
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return stripeCountryList
                            .filter((country: any) => country.value === data)
                            .map((country: any) => {
                                return {
                                    value: country.value,
                                    label: country.label + 'aaa',
                                }
                            });
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                },
            },
        },
        {
            controlType: 'group',
            key: 'registration_agreements',
            class: 'form-row',
            controls: {
                registrationTermsAgreement: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [],
                    placeholder: '',
                    label: '',
                    isLabelHidden: true,
                    isCheckboxLabelRaw: true,
                    checkboxLabel: `By registering, you agree to <a href="https://clinichunter.com/clinics-terms/" target="_blank"> the terms and conditions</a> and the <a href="https://clinichunter.com/privacy-policy/" target="_blank">privacy policy</a>.`,
                    hostClass: 'col-xl-12 checkbox-container'
                }
            }
        },
        {
            controlType: 'group',
            key: 'registration_submit',
            class: 'form-row register-form',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-100 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'Register',
                },
            },
        },
    ]
};
