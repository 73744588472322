import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterCalendarAPI} from "./provider/clinicHunterCalendar";
import {ICalendarRule} from "../store/reducers/calendarSlice";

export function addCalendarRulesAPI(
    authToken: string,
    calendarRules: ICalendarRule[]
): Observable<any> {
    const payload: any = {
        calendarRules: calendarRules
    };

    return clinicHunterCalendarAPI.post(
        "calendar_rules",
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
