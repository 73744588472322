import {FormControlType, IFormConfig, InputType, languagesList, ValidationRules} from 'common-web';

export const clinicDetailsFormConfig: typeof IFormConfig = {
  class: '',
  dataAccessor: (data: any) => data, // data is not grouped
  outputDataMapper: (data: any, previousStateSnapshot: any) => {
    Object.assign(previousStateSnapshot, data);

    return previousStateSnapshot;
  },
  controls: [
    {
      controlType: 'group',
      key: 'languages',
      class: 'row',
      controls: {
        languages: {
          controlType: 'control',
          defaultValue: '',
          formControlType: FormControlType.AUTOCOMPLETE,
          multiselectOtions: [],
          placeholder: 'No language selected',
          validationRules: [
            {name: ValidationRules.IS_REQUIRED},
          ],
          label: 'Languages',
          type: InputType.TEXT,
          hostClass: 'col-xl-12',
          disabled: true,
          inputDataMapper: (data: any) => {
            if (!data) {
              return [];
            }

            let optionsList = data.map((el: any) => el.id),
                selectedOptions: { [key: string]: any }[] = [];

            if (optionsList) {
              optionsList.forEach((item: any) => {
                let option: { [key: string]: any } = {};

                languagesList.map((el: any) => {
                  if (el.value === item) {
                    option.value = el.value;
                    option.label = el.label;
                    return option;
                  }
                });

                selectedOptions.push(option);
                return selectedOptions;
              })
            }
            return selectedOptions;
          }
        }
      }
    },
    // {
    //   controlType: 'group',
    //   key: 'opening_hours',
    //   class: 'row',
    //   controls: {
    //     openingHours: {
    //       controlType: 'control',
    //       defaultValue: '',
    //       formControlType: FormControlType.TEXTAREA,
    //       validationRules: [
    //         { name: ValidationRules.IS_REQUIRED },
    //       ],
    //       placeholder: 'Opening Hours',
    //       label: 'Opening Hours',
    //       type: InputType.TEXT,
    //       hostClass: 'col-xl-12',
    //       disabled: true,
    //     }
    //   }
    // },
    {
      controlType: 'group',
      key: 'payment_options',
      class: 'row',
      controls: {
        paymentOptions: {
          controlType: 'control',
          defaultValue: '',
          formControlType: FormControlType.AUTOCOMPLETE,
          disabled: true,
          multiselectOptions: [],
          validationRules: [
            {name: ValidationRules.IS_REQUIRED},
          ],
          placeholder: 'No payment option selected',
          label: "Payment Options",
          hostClass: 'col-xl-12',
          inputDataMapper: (data: any) => {
            if (!data) {
              return [];
            }

            return data.map((option: any) => ({
              value: option.id,
              label: option.name,
            }));
          }
        }
      }
    },
    {
      controlType: 'group',
      key: 'accreditation',
      class: 'row',
      controls: {
        accreditation: {
          controlType: 'control',
          defaultValue: '',
          formControlType: FormControlType.TEXTAREA,
          disabled: true,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
          placeholder: 'Accreditation',
          label: "Accreditation",
          type: InputType.TEXT,
          hostClass: 'col-xl-12',
        }
      }
    },
  ]
};
