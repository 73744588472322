import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface IInquiryBaseState {
    loading: boolean;
    error: string;
}

export interface ICalendarListState extends IInquiryBaseState {
    retrieved: any;
    eventSource: EventSource;
}

export const selectCalendarList = (state: RootState): ICalendarListState => {
    return state.calendar.list;
};

export const retrievedCalendarListSelector = createSelector(
    [selectCalendarList],
    (state: ICalendarListState) => state.retrieved
);

export const calendarListLoadingSelector = createSelector(
    [selectCalendarList],
    (state: ICalendarListState) => state.loading
);

export const calendarListErrorSelector = createSelector(
    [selectCalendarList],
    (state: ICalendarListState) => state.error
);

export const calendarListEventSourceSelector = createSelector(
    [selectCalendarList],
    (state: ICalendarListState) => state.eventSource
);
