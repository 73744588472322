import React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {list, reset} from '../../../actions/inquiry/list';
import {RootState} from "../../../store/reducers";
import {
    authTokenSelector,
    CustomCard,
    DateConverter,
    Provision,
    Translation,
    initInquirySummary,
    totalClinicFeeSelector,
    totalPremiumSelector,
    CustomPagination,
    RestQueryParams,
    CurrencyConverter,
    formatDateToString,
} from "common-web";
import {
    inquiryListErrorSelector,
    inquiryListEventSourceSelector,
    inquiryListLoadingSelector,
    retrievedListSelector
} from '../../../store/selectors/inquiryListSelectors';
import {DateRangePicker} from 'rsuite';
import {isNullOrUndefined} from '../../../utils/runtimeUtils';
import moment from 'moment';
import styles from '../styles.module.scss';
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import { saveAs } from 'file-saver';

const datepickerRanges: any[] = [
    {
        label: 'Previous Month',
        value: [
            new Date(moment().subtract(1, 'months').startOf('month').format()),
            new Date(moment().subtract(1, 'months').endOf('month').format())
        ]
    },
    {
        label: 'last7Days',
        value: [
            new Date(moment().subtract(7, 'days').format()),
            new Date(moment().endOf('day').format())]
    },
    {
        label: 'today',
        value: [
            new Date(moment().startOf('day').format()),
            new Date(moment().endOf('day').format())
        ]
    },
    {
        label: 'Current Month',
        value: [
            new Date(moment().startOf('month').format()),
            new Date(moment().endOf('month').format())
        ]
    },
];

enum IOfferDetailType {
    PRICE = 'price',
    COMMISION_VALUE = 'commision_value',
    COMMISION_PERCENT = 'commision_percent',
}

enum InsuranceStatus {
    PAID = 'status_paid',
    ACTIVE = 'status_active',
    EXPIRED = 'status_expired',
    DRAFT = 'status_draft',
    MODIFIED = 'status_modified',
}

interface IConnectedListProps {
    readonly retrieved: any;
    readonly loading: boolean;
    readonly error: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly authToken: string;
    readonly initInquirySummary: typeof initInquirySummary;
    readonly totalCommission: {[key: string]: any};
    readonly totalClinicFee: {[key: string]: any};
}

interface IInsurancesListProps extends IConnectedListProps,
    RouteComponentProps,
    WithTranslation {
}

interface IInsurancesListState {
    startDate: Date | null;
    defaultDateValue: any;
    reportInsuranceNumber: string | null;
    reportStartDate: string;
    reportEndDate: string;
}

class InsurancesList extends React.Component<IInsurancesListProps, IInsurancesListState> {
    private linkRef: any;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IInsurancesListProps) {
        super(props);

        let date = new Date();
        const defaultStartValue = new Date(date.getFullYear(), date.getMonth(), 1),
            defaultEndValue = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.state = {
            startDate: null,
            defaultDateValue: [defaultStartValue, defaultEndValue],
            reportInsuranceNumber: null,
            reportStartDate: this.getTimeString(defaultStartValue),
            reportEndDate: this.getTimeString(defaultEndValue),
        };
        this.linkRef = React.createRef();
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getInquiriesSummary(this.props.authToken);
    }

    componentDidUpdate(
        prevProps: Readonly<IInsurancesListProps>,
        prevState: Readonly<IInsurancesListState>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }

        if (this.state.reportStartDate !== prevState.reportStartDate ||
            this.state.reportEndDate !== prevState.reportEndDate) {
            this.getInquiriesSummary(this.props.authToken);
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'insurances.title'}/>
                            </div>
                            <Provision dataRange={[this.state.reportStartDate, this.state.reportEndDate]}
                                       data={[
                                           {label: 'Insurance Total', value: this.props.totalCommission, class: "insurance"},
                                           {label: 'Commission Total', value: this.props.totalClinicFee}
                                       ]} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 d-flex justify-content-between align-middle">
                        <div className="filter-container">
                            <div className="form-control input-icon icon-search mr-4">
                                <input type="text"
                                       placeholder="Search for insurance"
                                       className="input"
                                       onChange={(e: any) => this.handleChange(e)}/>
                            </div>
                            <div className="form-control mr-4">
                                <DateRangePicker placeholder="Select Date Range"
                                                 onChange={this.handleDateChange}
                                                 placement="autoVerticalStart"
                                                 ranges={datepickerRanges}
                                                 defaultValue={this.state.defaultDateValue}
                                                 renderValue={(value) => {
                                                     return `${formatDateToString(value[0])} - ${formatDateToString(value[1])}`;
                                                 }}
                                />
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-no-outline mr-2"
                                    type="button"
                                    onClick={() => this.downloadReport()}>
                                <span className="feather icon-download"/>
                                Download report
                            </button>
                            <a ref={this.linkRef}/>

                            <Link to="/dashboard/inquiries/create" className="btn btn-theme">
                                <Translation text={'inquiries.createInquiry.newButton'}/>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>
                                { this.renderInsuranceList()}

                                <CustomPagination retrieved={this.props.retrieved}
                                                  basePath="dashboard"
                                                  path="inquiries"
                                                  provider={this.getInsurancesList}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderInsuranceList = () => {
        if (!this.props.retrieved ||
            !this.props.retrieved['hydra:member'] ||
            !Array.isArray(this.props.retrieved['hydra:member'])) {
            return <p>There is no data available</p>;
        }

        return (
            <React.Fragment>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th><Translation text={'insurances.list.paidAt'}/></th>
                        <th><Translation text={'insurances.list.insuranceNo'}/></th>
                        <th><Translation text={'insurances.list.patient'}/></th>
                        <th className="text-center"><Translation text={'insurances.list.companion'}/></th>
                        <th><Translation text={'insurances.list.startDate'}/></th>
                        <th><Translation text={'insurances.list.endDate'}/></th>
                        <th><Translation text={'insurances.list.price'}/></th>
                        <th><Translation text={'insurances.list.commission'}/></th>
                        <th><Translation text={'insurances.list.commissionP'}/></th>
                        <th className="text-center"><Translation text={'insurances.settledWithClinic.title'}/></th>
                        <th><Translation text={'insurances.list.insuranceStatus'}/></th>
                        <th className="text-center" />
                    </tr>
                    </thead>
                    <tbody>

                    {this.renderTableRows()}

                    </tbody>
                </table>
            </React.Fragment>
        )
    };

    private handleChange = (e: any) => {
        this.props.list(
            `inquiries?page=1&inquiry_filter=${(e.target.value).toUpperCase()}`,
            this.props.authToken,
        );

        this.setState({reportInsuranceNumber: e.target.value});
    };

    private handleDateChange = (e: any) => {
        if (!e.length || isNullOrUndefined(e)) {
            return;
        }

        const startDate = this.getTimeString(e[0]);
        const endDate = this.getTimeString(e[1]);
        this.props.list(
            `inquiries?page=1&paid_at[after]=${startDate}&paid_at[before]=${endDate}`,
            this.props.authToken,
        );

        this.setState({
            reportStartDate: startDate,
            reportEndDate: endDate
        })
    };

    private renderTableRows() {
        const {t} = this.props;

        const rows: any[] = [];

        const list = this.props.retrieved['hydra:member'].sort(this.sortMethod);

        list.map((item: any) => {

            const isMultipleCompanions = item.inquirySubjects.length > 1,
                offerDetails = item.offers[0];

                return rows.push((
                <tr key={item['@id']}>
                    <td><DateConverter date={item.createdAt}/></td>
                    <td>{ item.acceptedOffer ? item.acceptedOffer.reference : '-' }</td>
                    <td>{ this.renderPatient(item) }</td>
                    <td className="align-middle text-center">
                      <span className={styles.linkItem}>
                          <abbr className={`${styles.linkItemTooltip} ${styles.statusIcon}`}
                                title={t(`insurances.list.companionsPresent.description`, {isMultipleCompanions: `${isMultipleCompanions ? 'are' : 'arent any'}`})}>
                              <span
                                  className={`feather icon-${isMultipleCompanions ? 'users' : 'minus'} ${styles[isMultipleCompanions ? 'multiple' : 'single']}`}
                                  aria-hidden="true"/>

                          </abbr>
                          <span className="sr-only">
                              {t(`insurances.list.companionsPresent.description`, {isMultipleCompanions: `${isMultipleCompanions ? 'are' : 'arent any'}`})}
                          </span>

                      </span>
                    </td>
                    <td><DateConverter date={item.from}/></td>
                    <td><DateConverter date={item.to}/></td>

                    <td>{this.getInsuranceOfferDetails(item, IOfferDetailType.PRICE)}</td>
                    <td>{this.getInsuranceOfferDetails(item, IOfferDetailType.COMMISION_VALUE)}</td>
                    <td>{this.getInsuranceOfferDetails(item, IOfferDetailType.COMMISION_PERCENT)}</td>
                    <td className="align-middle text-center">
                      <span className={styles.linkItem}>
                          <abbr className={`${styles.linkItemTooltip} ${styles.statusIcon}`}
                                title={t(`insurances.list.settledWithClinic.description`, {isSettled: `${item.settledWithClinic ? 'settled' : 'not settled'}`})}>
                              <span
                                  className={`feather icon-${item.settledWithClinic ? 'check color-success' : 'x color-danger'}`}
                                  aria-hidden="true"/>
                          </abbr>
                          <span className="sr-only"><Translation
                              text={`insurances.list.settledWithClinic.${item.settledWithClinic ? 'settled' : 'notSettled'}`}/></span>
                      </span>
                    </td>
                    <td>
                        {offerDetails ? this.renderInsuranceStatus(offerDetails.status) : '---'}
                    </td>
                    <td className="align-middle text-right">
                        <Link className="btn btn-action" to={`/dashboard/inquiries/${item['id']}`}>
                            <span className="feather icon-search"/>
                        </Link>
                    </td>
                </tr>
            ))
        });

        return rows;
    }

    private renderPatient(item: any) {
        const mainPatient = item.inquirySubjects.filter((subject: any) => subject.main === true)[0];
        if (!mainPatient) {
            return '-'
        }

        return (
            <span>{ mainPatient.firstName } {mainPatient.lastName }</span>
        )
    }

    private getInsuranceOfferDetails(item: any, type: IOfferDetailType) {
        if (!item || !item.offers || !Array.isArray(item.offers) || !item.offers.length) {
            return '';
        }
        const offer = item.offers[0],
            currency = '€';//offer.premium.currency.code;

        switch (type) {
            case IOfferDetailType.PRICE:
                return (<CurrencyConverter price={offer.premium} />);

            case IOfferDetailType.COMMISION_VALUE:
                return (<CurrencyConverter price={offer.clinicFee} />);

            case IOfferDetailType.COMMISION_PERCENT:
                const commissionPercent = Math.round(offer.clinicFee.amount / offer.premium.amount * 100);
                return `${commissionPercent.toFixed(2)} %`;

            default:
                return '';
        }
    }

  private getInsurancesList = (searchParams: typeof RestQueryParams) => {
    searchParams = searchParams.add('order[paid_at]', 'DESC');
    searchParams = searchParams.add('paid_at[before]', this.state.reportStartDate);
    searchParams = searchParams.add('paid_at[before]', this.state.reportEndDate);
    this.props.list(`inquiries${searchParams.prepareQuery()}`, this.props.authToken);
  };

  private downloadReport = () => {
    const requestUrl = this.state.reportStartDate && this.state.reportEndDate ?
      `inquiries/export?paid_at[after]=${this.state.reportStartDate}&paid_at[before]=${this.state.reportEndDate}` : 'inquiries/export';
    let url = `${process.env.REACT_APP_AUTH_API_URL}/${requestUrl}`;
    return fetch(url, {
      headers: {
        "Accept": "text/csv",
        "Content-type": "application/json",
        "Authorization": `Bearer ${this.props.authToken}`
      },
    }).then(response => response.blob())
        .then(blob => saveAs(blob, 'report.csv'));
  };

  private getInquiriesSummary(authToken: string) {
    const dateRange = `paid_at[after]=${this.state.reportStartDate}&paid_at[before]=${this.state.reportEndDate}`;

    return this.props.initInquirySummary(authToken, true, dateRange);
  }

  private getTimeString(value: Date): string {
    return new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
  }

    private sortMethod(a: any, b: any): number {
        const aDate = new Date(a.createdAt),
            bDate = new Date(b.createdAt),
            aTime = aDate.getTime(),
            bTime = bDate.getTime();

        return aTime > bTime ? -1 : aTime !== bTime ? 1 : 0;
    }

    private renderInsuranceStatus = (status: InsuranceStatus) => {
        return (
            <div className="badge-wrapper">
                <span className={`${"badge-text"} ${status}`}>
                    <Translation text={`insurances.status.${status}`}/>
                </span>
            </div>
        );
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedListSelector(state),
        loading: inquiryListLoadingSelector(state),
        error: inquiryListErrorSelector(state),
        eventSource: inquiryListEventSourceSelector(state),
        authToken: authTokenSelector(state),
        totalCommission: totalPremiumSelector(state),
        totalClinicFee: totalClinicFeeSelector(state)
    }),
    {
        list,
        reset,
        initInquirySummary
    }
)(withRouter(InsurancesList)));
