import React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {authTokenSelector, clinicIdSelector, CustomCard, CustomPagination, RestQueryParams, Translation} from "common-web";
import {RootState} from "../../store/reducers";
import {connect} from "react-redux";
import {
    specialistListErrorSelector,
    specialistListEventSourceSelector,
    specialistListLoadingSelector,
    retrievedSpecialistListSelector
} from "../../store/selectors/specialistListSelectors";
import {list, reset} from "../../actions/specialist/list";

interface IConnectedSpecialistsProps {
    readonly retrieved: any;
    readonly loading: boolean;
    readonly error: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly authToken: string;
    readonly clinicId: string;
}

interface IExternalSpecialistsProps {
}

interface ISpecialistsProps extends IConnectedSpecialistsProps,
    IExternalSpecialistsProps,
    RouteComponentProps {
}

interface ISpecialistsState {
    isLoading: boolean;
}

class Specialists extends React.Component<ISpecialistsProps, ISpecialistsState> {

    constructor(props: ISpecialistsProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(
        prevProps: Readonly<ISpecialistsProps>,
        prevState: Readonly<{}>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'specialists.title'}/>
                            </div>
                            {/*<div className="action-container">*/}
                            {/*<button className="btn btn-small btn-theme"*/}
                            {/*        type="button">+ Add Clinic Specialist</button>*/}
                            {/*<button className="btn btn-small btn-theme ml-2"*/}
                            {/*        type="button">?*/}
                            {/*</button>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">

                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>
                                <table className="data-table">
                                    <thead>
                                    <tr>
                                        <th><Translation text={'specialists.list.name'}/></th>
                                        <th><Translation text={'specialists.list.academicDegree'}/></th>
                                        <th><Translation text={'specialists.list.medicalDegrees'}/></th>
                                        <th><Translation text={'specialists.list.specializations'}/></th>
                                        <th colSpan={2} />
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderTableRows()}
                                    </tbody>
                                </table>

                                <CustomPagination retrieved={this.props.retrieved}
                                                  basePath="dashboard"
                                                  path="specialists"
                                                  provider={this.getSpecialistList}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderTableRows() {
        if (!this.props.retrieved || !this.props.retrieved['hydra:member']) {
            return;
        }
        const rows: any[] = [];
        this.props.retrieved['hydra:member'].map((item: any) => {
            return rows.push(
                <tr key={item.id}>
                    <td>{item.account.firstName} {item.account.lastName}</td>
                    <td>{item.academicDegree}</td>
                    <td>{item.medicalDegrees}</td>
                    <td>{item.specializations.map((specialization: any) => <span
                        key={specialization.name}>{specialization.name} </span>)}</td>
                    <td className="text-right">
                        <Link className="btn btn-action" to={`/dashboard/specialists/${item['id']}`}>
                            <span className="feather icon-search"/>
                        </Link>

                        {/*<Link to={`edit/${encodeURIComponent(item['@id'])}`}>*/}
                        {/*<span className="feather icon-search"/>*/}
                        {/*<span className="sr-only">*/}
                        {/*<Translation text={'buttons.edit'}/>*/}
                        {/*</span>*/}
                        {/*</Link>*/}
                    </td>
                </tr>
            )
        });

        return rows;
    }

    private getSpecialistList = (searchParams: typeof RestQueryParams) => {
        this.props.list(`clinics/${this.props.clinicId}/doctors${searchParams.prepareQuery()}`, this.props.authToken);
    }
}

export default connect(
    (state: RootState) => ({
        retrieved: retrievedSpecialistListSelector(state),
        loading: specialistListLoadingSelector(state),
        error: specialistListErrorSelector(state),
        eventSource: specialistListEventSourceSelector(state),
        authToken: authTokenSelector(state),
        clinicId: clinicIdSelector(state),
    }),
    {
        list,
        reset
    }
)(withRouter(Specialists));
