import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";
import {IAfterCareBaseState} from "./afterCareListSelectors";

export interface IAfterCareItemState extends IAfterCareBaseState {
  retrieved: any; // object
  eventSource: EventSource;
}

export interface IDeleteAfterCareItemState extends IAfterCareBaseState {
  deleted: any; // model for aftercare
}

export interface ICreateAfterCareItemState extends IAfterCareBaseState {
  created: any; // model for aftercare
}

export interface IUpdateAfterCareItemState {
  retrieved: any; // model for aftercare
  retrieveError: string;
  retrieveLoading: boolean;
  updateError: string;
  updateLoading: boolean;
  eventSource: EventSource;
  updated: any; // model for aftercare
}

export const selectAfterCareItem = (state: RootState): IAfterCareItemState => {
  return state.afterCare.show;
};

export const selectDeleteAfterCareItem = (state: RootState): IDeleteAfterCareItemState => {
  return state.afterCare.del;
};

export const selectCreateAfterCareItem = (state: RootState): ICreateAfterCareItemState => {
  return state.afterCare.create;
};

export const selectUpdateAfterCareItem = (state: RootState): IUpdateAfterCareItemState => {
  return state.afterCare.update;
};

export const retrievedAfterCareItemSelector = createSelector(
  [selectAfterCareItem],
  (state: IAfterCareItemState) => state.retrieved
);

export const afterCareItemLoadingSelector = createSelector(
  [selectAfterCareItem],
  (state: IAfterCareItemState) => state.loading
);

export const afterCareItemErrorSelector = createSelector(
  [selectAfterCareItem],
  (state: IAfterCareItemState) => state.error
);

export const afterCareItemEventSourceSelector = createSelector(
 [selectAfterCareItem],
 (state: IAfterCareItemState) => state.eventSource
);

export const afterCareItemDeleteErrorSelector = createSelector(
  [selectDeleteAfterCareItem],
  (state: IDeleteAfterCareItemState) => state.error
);

export const afterCareItemDeleteLoadingSelector = createSelector(
  [selectDeleteAfterCareItem],
  (state: IDeleteAfterCareItemState) => state.loading
);

export const afterCareItemDeletedSelector = createSelector(
  [selectDeleteAfterCareItem],
  (state: IDeleteAfterCareItemState) => state.deleted
);

export const afterCareItemCreateErrorSelector = createSelector(
  [selectCreateAfterCareItem],
  (state: ICreateAfterCareItemState) => state.error
);

export const afterCareItemCreateLoadingSelector = createSelector(
  [selectCreateAfterCareItem],
  (state: ICreateAfterCareItemState) => state.loading
);

export const afterCareItemCreatedSelector = createSelector(
  [selectCreateAfterCareItem],
  (state: ICreateAfterCareItemState) => state.created
);

export const afterCareItemUpdateRetrievedSelector = createSelector(
  [selectUpdateAfterCareItem],
  (state: IUpdateAfterCareItemState) => state.retrieved
);

export const afterCareItemUpdateRetrieveErrorSelector = createSelector(
  [selectUpdateAfterCareItem],
  (state: IUpdateAfterCareItemState) => state.retrieveError
);

export const afterCareItemUpdateRetrieveLoadingSelector = createSelector(
  [selectUpdateAfterCareItem],
  (state: IUpdateAfterCareItemState) => state.retrieveLoading
);

export const afterCareItemUpdateErrorSelector = createSelector(
  [selectUpdateAfterCareItem],
  (state: IUpdateAfterCareItemState) => state.updateError
);

export const afterCareItemUpdateLoadingSelector = createSelector(
  [selectUpdateAfterCareItem],
  (state: IUpdateAfterCareItemState) => state.updateLoading
);

export const afterCareItemUpdatedSelector = createSelector(
  [selectUpdateAfterCareItem],
  (state: IUpdateAfterCareItemState) => state.updated
);

export const afterCareItemUpdateEventSourceSelector = createSelector(
  [selectUpdateAfterCareItem],
  (state: IUpdateAfterCareItemState) => state.eventSource
);
